import Vue from 'vue'
import App from './App.vue'
import { store } from './store'
import router from './router'
// import Vuetify from 'vuetify'
import Vuex from 'vuex'
import VueWait from 'vue-wait'
import VueI18n from 'vue-i18n'
import messages from '@/common/assets/json/messages1.json'
import VTooltip from 'v-tooltip'
// import 'vuetify/dist/vuetify.css'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
// import 'vuetify/dist/vuetify.min.css'
import '@/common/assets/styles/vz-am.css' //reg //login
import profilerest from '@/vbgauth/util/profilerest-axios.js'
import BlockUI from 'vue-blockui'

Vue.config.productionTip = false
Vue.config.devtools = true;
// Vue.use(Vuetify, {
// })

Vue.use(Vuex)
Vue.use(VueWait)
Vue.use(VueI18n)
Vue.use(VTooltip)
Vue.use(VuejsDialog)
Vue.use(BlockUI)
VTooltip.options.defaultTemplate = '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
VTooltip.options.defaultArrowSelector = '.tooltip-vue-arrow, .tooltip-vue__arrow';
VTooltip.options.defaultInnerSelector = '.tooltip-vue-inner, .tooltip-vue__inner';
// const vuetifyOptions = {}
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages,
})
//interceptor needed for password blacklist
profilerest.interceptors.request.use(
  config => {
    config.headers.Authorization =  'Basic aWRtOmlkbQ=='
    //store.commit('profile/setLoader', true)
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
new Vue({
  // vuetify: new Vuetify(vuetifyOptions),
  router: router,
  store:store,
  wait: new VueWait({
    useVuex: true
  }),
  render: h => h(App),
  i18n,
  created() {
    if(performance.navigation.type === 1 || performance.navigation.type === 2 ) {
      if(this.$route.path === '/stepup/otp' || this.$route.path === '/stepup/sqa' || this.$route.path === '/verify/otp' || this.$route.path === '/verify/sqa') {
        this.$router.push({path: '/login'})
      }
    }
  }
}).$mount('#app')