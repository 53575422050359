import Vue from 'vue'
import VueRouter from 'vue-router'
import ContainerComponent from '@/vbgauth/components/amlogin/ContainerComponent'
// import AMLoginComponent from '@/vbgauth/components/amlogin/AMLoginComponent'
import AMLoginRootComponent from '@/vbgauth/components/amlogin/AMLoginRootComponent'
import AMForgotInfoRootComponent from '@/vbgauth/components/amForgotFlow/AMForgotInfoRootComponent'
import AMForgotPwdComponent from '@/vbgauth/components/amForgotFlow/AMForgotPwdComponent'
// import AMForgotUsrnmResultComponent from '@/vbgauth/components/amForgotFlow/AMForgotUsrnmResultComponent'
import AMForgotUsrnmComponent from '@/vbgauth/components/amForgotFlow/AMForgotUsrnmComponent'
import AMSqaRegRootComponent from '@/vbgauth/components/csqa/AMSqaRegRootComponent'
import AMSqaRegComponent from '@/vbgauth/components/csqa/AMSqaRegComponent'
import AuthStepupRootComponent from '@/vbgauth/components/authstepup/AuthStepupRootComponent'
import AuthOtpComponent from '@/vbgauth/components/authstepup/AuthOtpComponent'
import AuthSqaComponent from '@/vbgauth/components/authstepup/AuthSqaComponent'
import FFStepupRootComponent from '@/vbgauth/components/ffstepup/FFStepupRootComponent'
// import AMResetPwdFormComponent from '@/vbgauth/components/amForgotFlow/AMResetPwdFormComponent'
import FFOtpComponent from '@/vbgauth/components/ffstepup/FFOtpComponent'
import FFSqaComponent from '@/vbgauth/components/ffstepup/FFSqaComponent'
import UserRegRootComponent from '@/vbgauth/components/userRegistrationFlow/UserRegRootComponent'
import NotFoundComponent from '@/vbgauth/components/NotFoundComponent'
import ErrorPage from '@/vbgauth/components/ErrorPage'
import BuildInfo from '@/vbgauth/components/BuildInfo'
import { store } from '../store'
import LoginRootComponent from '@/vbgauth/components/simpleLogin/LoginRootComponent'
import MobileLoginComponent from '@/vbgauth/components/simpleLogin/MobileLoginComponent'
import iLoginComponent from '@/vbgauth/components/simpleLogin/iLoginComponent'
import UnifiedLogin from '@/vbgauth/components/hybridExp/UnifiedLogin'
import PwdLessLoginRootComponent from '@/vbgauth/components/hybridExp/PwdLessLoginRootComponent'
import PwdLessOption from '@/vbgauth/components/hybridExp/PwdLessOption'
import PwdLessPassword from '@/vbgauth/components/hybridExp/PwdLessPassword'
import PwdLessOtpDelivery from '@/vbgauth/components/hybridExp/PwdLessOtpDelivery'
import PwdLessOtpValidate from '@/vbgauth/components/hybridExp/PwdLessOtpValidate'
import VPSPwdLessLoginRootComponent from '@/vbgauth/components/vpsFlow/vpsPwdless/VPSPwdLessLoginRootComponent'
import VPSPwdLessOption from '@/vbgauth/components/vpsFlow/vpsPwdless/VPSPwdLessOption'
import VPSPwdLessPassword from '@/vbgauth/components/vpsFlow/vpsPwdless/VPSPwdLessPassword'
import VPSPwdLessOtpDelivery from '@/vbgauth/components/vpsFlow/vpsPwdless/VPSPwdLessOtpDelivery'
import VPSPwdLessOtpValidate from '@/vbgauth/components/vpsFlow/vpsPwdless/VPSPwdLessOtpValidate'
import PwdLessPushRegistered from '@/vbgauth/components/hybridExp/PwdLessPushRegistered'
import PwdLessPushNotRegistered from '@/vbgauth/components/hybridExp/PwdLessPushNotRegistered'
import CombinenowRootComponent from '@/vbgauth/components/hybridExp/combinenow/CombinenowRootComponent'
import CreatePassword from '@/vbgauth/components/vsbdrPasswordFlow/createPassword'
import PasswordUpdated from '@/vbgauth/components/vsbdrPasswordFlow/passwordUpdated'
import ForgotPasswordLanding from '@/vbgauth/components/vsbdrPasswordFlow/forgotPassword'
import VerifyProfile from '@/vbgauth/components/vsbdrPasswordFlow/resendEmail'
import MaxAttempt from '@/vbgauth/components/vsbdrPasswordFlow/MaxAttempt'
import ResetPassword from '@/common/components/ResetPassword.vue'
import VPSResetPassword from '@/common/components/VPSResetPassword.vue'
import ResetSecurityProfileRootComponent from '@/vbgauth/components/unlockMyAccount/ResetSecurityProfileRootComponent'
import ProcessLoaderComponent from '@/vbgauth/components/unlockMyAccount/ProcessLoaderComponent'
import Csqa from '@/vbgauth/components/VbmCmbUsers/Csqa'
import SecurityProfileRequest from '@/vbgauth/components/VbmCmbUsers/SecurityProfileRequest'
import SecurityProfileRequestPin from '@/vbgauth/components/VbmCmbUsers/SecurityProfileRequestPin'
import DeliveryMethodLocked from '@/vbgauth/components/hybridExp/DeliveryMethodLocked.vue'
import VcgProfileUpdateRootComponent from '@/vbgauth/components/VbmCmbUsers/VcgProfileUpdateRootComponent'
import VPSRegistrationPage from '@/vbgauth/components/vpsFlow/vpsRegistration/VPSRegistrationPage.vue'
import VPSRegistrationSuccessful from '@/vbgauth/components/vpsFlow/vpsRegistration/VPSRegistrationSuccessful.vue'
import VPSSecurityProfilePage from '@/vbgauth/components/vpsFlow/vpsEditProfile/VPSSecurityProfilePage.vue'
import VPSSecurityProfilePwdEdit from '@/vbgauth/components/vpsFlow/vpsEditProfile/VPSSecurityProfilePwdEdit.vue'
import VPSSecurityProfileSQAEdit from '@/vbgauth/components/vpsFlow/vpsEditProfile/VPSSecurityProfileSQAEdit.vue'
import VPSSecurityProfileUsernameEdit from '@/vbgauth/components/vpsFlow/vpsEditProfile/VPSSecurityProfileUsernameEdit.vue'
import VPSSecurityProfileEmailEdit from '@/vbgauth/components/vpsFlow/vpsEditProfile/VPSSecurityProfileEmailEdit.vue'
import VPSUserSuspendedPage from '@/vbgauth/components/vpsFlow/VPSUserSuspendedPage.vue'
import VPSForgotUserIdUserDetails from '@/vbgauth/components/vpsFlow/vpsForgotFlows/VPSForgotUserIdUserDetails.vue'
import VPSForgotUserIdSelect from '@/vbgauth/components/vpsFlow/vpsForgotFlows/VPSForgotUserIdSelect.vue'
import VPSForgotUserIdEmailSent from '@/vbgauth/components/vpsFlow/vpsForgotFlows/VPSForgotUserIdEmailSent.vue'
import VPSForgotPwdFormComponent from '@/vbgauth/components/vpsFlow/vpsForgotFlows/VPSForgotPwdFormComponent.vue'
import VPSFFStepupRootComponent from '@/vbgauth/components/vpsFlow/vpsForgotFlowStepup/VPSFFStepupRootComponent.vue'
import VPSFFOtpComponent from '@/vbgauth/components/vpsFlow/vpsForgotFlowStepup/VPSFFOtpComponent.vue'
import VPSFFSqaComponent from '@/vbgauth/components/vpsFlow/vpsForgotFlowStepup/VPSFFSqaComponent.vue'
import vsbdrlogin from '@/vbgauth/components/vsbdrPasswordFlow/vsbdrlogin'
import ForgotPasswordErrorMessage from '@/vbgauth/components/simplifiedDigitalAuthentication/ForgotPasswordErrorMessage.vue'
import SessionExpiredError from '@/vbgauth/components/SessionExpiredError'
Vue.use(VueRouter)
const isVPSFlowEnabled = iamvar_vpsFlowToggle;
var baseUrl = '/account/business/'
const router = new VueRouter({
    base: baseUrl,
    mode: 'history',
    linkActiveClass: 'open active',
    routes: [
      {
        path: '/account',
        redirect: '/account/business/login/'
      },
      {
        path: '/login/user',
        name: 'AMLoginRootComponent',
        component: AMLoginRootComponent
      },
      {
        path: '/login/calnet',
        name: 'CalnetLoginComponent',
        redirect: '/login/'
      },
      {
        path: '/login/odi',
        name: 'ODILoginComponent',
        redirect: '/login/'
      },
      {
        path: '/login/',
        name: 'SimpleLoginRoot',
        component: LoginRootComponent
      },
      {
        path: '/login/unifiedlogin',
        name: 'PwdLessLoginRootComponent',
        component: PwdLessLoginRootComponent
      },
      {
        path: '/login/mobile',
        name: 'MobileLoginComponent',
        component: MobileLoginComponent
      },
      {
        path: '/login/options',
        name: 'PwdLessOption',
        component: PwdLessOption
      },
      {
        path: '/login/password',
        name: 'PwdLessPassword',
        component: PwdLessPassword
      },
      {
        path: '/login/otpdelivery',
        name: 'PwdLessOtpDelivery',
        component: PwdLessOtpDelivery
      },
      {
        path: '/login/otpvalidate',
        name: 'PwdLessOtpValidate',
        component: PwdLessOtpValidate
      },
      {
        path: '/login/push',
        name: 'PwdLessPushRegistered',
        component: PwdLessPushRegistered
      },
      {
        path: '/login/nopush',
        name: 'PwdLessPushNotRegistered',
        component: PwdLessPushNotRegistered
      },
      {
        path: '/login/combinenow',
        name: 'CombinenowRootComponent',
        component: CombinenowRootComponent,
      },
      {
        path: '/login/recovery',
        name: 'AMForgotInfoRootComponent',
        component: AMForgotInfoRootComponent,
        children: [
          {
            path: 'forgotpassword',
            name: 'AMForgotPwdComponent',
            component: AMForgotPwdComponent
          },
          {
            path: 'forgotusername',
            name: 'AMForgotUsrnmComponent',
            component: AMForgotUsrnmComponent
          }
        ]
      },
      {
        path: '/login/stepup',
        name: 'FFStepupRootComponent',
        component: FFStepupRootComponent,
        beforeEnter: (to, from, next) => {
          if(from.name === 'FFOtpComponent' || from.name === 'FFSqaComponent') {
            //router.push({ name: 'SimpleLoginRoot', query: { goto: store.state.login.gotoUrl }})
            window.location.href = store.state.login.urlOnMount
          } else if(from.path === '/verify/otp') {
            next({ name: 'SimpleLoginComponent' , query: { goto: store.state.login.gotoUrl }})
          } else next()
        },
        children: [
          { 
            path: 'otp',
            name: 'FFOtpComponent',
            component: FFOtpComponent
          },
          {
            path: 'sqa',
            name: 'FFSqaComponent',
            component: FFSqaComponent
          }
        ]
      },
      {
        path: '/login/verify',
        name: 'VerifyStepupRootComponent',
        component: FFStepupRootComponent,
        children: [
          { 
            path: 'otp',
            name: 'VerifyFFOtpComponent',
            component: FFOtpComponent
          },
          {
            path: 'sqa',
            name: 'VerifyFFSqaComponent',
            component: FFSqaComponent
          }
        ]
      },
      {
        path: '/login/buildinfo',
        name: 'BuildInfo',
        component: BuildInfo
      },
      {
        path: '/login/accountlocked/:lockType',
        name: 'ResetPassword',
        component: ResetPassword
      },
      {
        path: '/odasignin',
        name: 'SimpleLoginRoot',
        component: LoginRootComponent,
      },
      {
        path:'/login/recovery/resetsecurityprofile',
        name:'ResetSecurityProfileRootComponent',
        component: ResetSecurityProfileRootComponent
      },
      {
        path:'/login/recovery/forgotpassworderrorpage',
        name:'ForgotPasswordErrorMessage',
        component: ForgotPasswordErrorMessage
      },
      {
        path:'/login/recovery/redirecting',
        name: 'ProcessLoaderCompoent',
        component: ProcessLoaderComponent,
      },
      {
        path: '/ilogin/',
        //name: 'SimpleLoginRoot',
        //component: LoginRootComponent
        name: 'iLoginComponent',
        component: iLoginComponent
      },
      {
        path: '/login/modifyPassword',
        name: 'ModifyPassword',
        component: CreatePassword
      },
      {
        path: '/login/passwordUpdated',
        name: 'PasswordUpdated',
        component: PasswordUpdated
      },
      {
        path: '/login/displayResetPassword',
        name: 'displayResetPassword',
        component: CreatePassword
      },
      {
        path: '/login/resetPasswordUpdated',
        name: 'resetPasswordUpdated',
        component: PasswordUpdated
      },
      {
        path: '/login/forgotPasswordLanding',
        name: 'ForgotPasswordLanding',
        component: ForgotPasswordLanding
      },
      {
        path: '/login/verifyProfile',
        name: 'VerifyProfile',
        component: VerifyProfile
      },
      {
        path: '/login/maxAttempt',
        name: 'MaxAttempt',
        component: MaxAttempt
      },
      {
        path: '/login/csqa',
        name: 'Csqa',
        component: Csqa
      },
      {
        path: '/login/vcgupdateprofile',
        name: 'VcgProfileUpdateRootComponent',
        component: VcgProfileUpdateRootComponent,
      },
      
      {
        path: '/login/securityprofilerequest',
        name: 'SecurityProfileRequest',
        component: SecurityProfileRequest
      },
      {
        path: '/login/securityprofilerequestpin',
        name: 'SecurityProfileRequestPin',
        component: SecurityProfileRequestPin
      },
      
      // {
      //   path: '/login/ilogin',
      //   name: 'iLoginComponent',
      //   component: iLoginComponent
      // },
      { path: '*', component: NotFoundComponent },
      { path: '*', component: ErrorPage },
      {
        path: '/login/deliveryMethodLocked',
        name: 'DeliveryMethodLocked',
        component: DeliveryMethodLocked
      },
      {
        path: '/login/vpsunifiedlogin',
        name: 'VPSPwdLessLoginRootComponent',
        component: isVPSFlowEnabled && VPSPwdLessLoginRootComponent
      },
      {
        path: '/login/vpspassword',
        name: 'VPSPwdLessPassword',
        component: isVPSFlowEnabled && VPSPwdLessPassword
      },
      {
        path: '/login/vpsotpdelivery',
        name: 'VPSPwdLessOtpDelivery',
        component: isVPSFlowEnabled && VPSPwdLessOtpDelivery
      },
      {
        path: '/login/vpsotpvalidate',
        name: 'VPSPwdLessOtpValidate',
        component: isVPSFlowEnabled && VPSPwdLessOtpValidate
      },
      {
        path: '/login/vpsoptions',
        name: 'VPSPwdLessOption',
        component: isVPSFlowEnabled && VPSPwdLessOption
      },
      {
        path: '/login/vpsaccountlocked',
        name: 'VPSResetPassword',
        component: isVPSFlowEnabled && VPSResetPassword
      },
      {
        path: '/login/vpsprofile',
        name: 'VPSSecurityProfilePage',
        component: isVPSFlowEnabled && VPSSecurityProfilePage
      },
      {
        path: '/login/recovery/vpsforgotuseridselect',
        name: 'VPSForgotUserIdSelect',
        component: isVPSFlowEnabled && VPSForgotUserIdSelect
      },
      {
        path: '/login/recovery/vpsforgotusername',
        name: 'VPSForgotUserIdUserDetails',
        component: isVPSFlowEnabled && VPSForgotUserIdUserDetails
      },
      {
        path: '/login/recovery/vpsforgotflowmailsent',
        name: 'VPSForgotUserIdEmailSent',
        component: isVPSFlowEnabled && VPSForgotUserIdEmailSent
      },
      {
        path: '/login/recovery/vpsforgotpassword',
        name: 'VPSForgotPwdFormComponent',
        component: isVPSFlowEnabled && VPSForgotPwdFormComponent
      },
      {
        path: '/login/vpsupdatepassword',
        name: 'VPSSecurityProfilePwdEdit',
        component: isVPSFlowEnabled && VPSSecurityProfilePwdEdit
      },
      {
        path: '/login/vpsupdatesqa',
        name: 'VPSSecurityProfileSQAEdit',
        component: isVPSFlowEnabled && VPSSecurityProfileSQAEdit
      },
      {
        path: '/login/vpsupdateusername',
        name: 'VPSSecurityProfileUsernameEdit',
        component: isVPSFlowEnabled && VPSSecurityProfileUsernameEdit
      },
      {
        path: '/login/vpsupdateemail',
        name: 'VPSSecurityProfileEmailEdit',
        component: isVPSFlowEnabled && VPSSecurityProfileEmailEdit
      },
      {
        path: '/login/vpsregistration',
        name: 'VPSRegistrationPage',
        component: isVPSFlowEnabled && VPSRegistrationPage
      },
      {
        path: '/login/vpsusersuspended',
        name: 'VPSUserSuspendedPage',
        component: isVPSFlowEnabled && VPSUserSuspendedPage
      },
      {
        path: '/login/vpsregistrationsuccessful',
        name: 'VPSRegistrationSuccessful',
        component: isVPSFlowEnabled && VPSRegistrationSuccessful
      },
      {
        path: '/login/vpsffstepup',
        name: 'VPSFFStepupRootComponent',
        component: isVPSFlowEnabled && VPSFFStepupRootComponent,
        children: [
          { 
            path: 'vpsotp',
            name: 'VPSFFOtpComponent',
            component: isVPSFlowEnabled && VPSFFOtpComponent
          },
          {
            path: 'vpssqa',
            name: 'VPSFFSqaComponent',
            component: isVPSFlowEnabled && VPSFFSqaComponent
          }
        ]
      },
      {
        path: '/login/vsbdrlogin',
        name: 'VSBDRLogin',
        component: iamvar_vsbdrMigration && vsbdrlogin
      },
      {
        path: '/login/sessionexpiredpage',
        name: 'SessionExpiredErrorPage',
        component: SessionExpiredError,
      },
    ]
  })
  export default router
