<template>
  <div style="overflow: hidden">
    <Header></Header>
    <div class="pwdless_pass_container">
      <BlockUI v-if="submitLoader" :url="url"></BlockUI>
      <form>
        <div class="opt-container-1">
          <div v-if="otpAlerts.length > 0">
            <div
              v-for="j in otpAlerts"
              :key="j"
              class="pwdPushRError"
              v-bind:class="getAlertType(j)"
            >
              <div class="pwdless_error">
                <div class="warningImage" :class="messageImage(j)"></div>
                <p class="pushTextError" style="margin-top:3px" v-html="j.msg">{{ j.msg }}</p>
              </div>
              <div>
                <img
                  class="closeImage"
                  src="@/common/assets/styles/corp/images/close_black.png"
                  @click="deleteAlert(j)"
                />
              </div>
            </div>
          </div>
          <div class="opt-container-1-1">
            <h3 class="opt-header-1">Log in</h3>
            <div class="opt-header-1-1">
              <p class="opt-p-1">User ID</p>
              <div style="display: flex">
                <p class="opt-username">{{ pwdUsername }}</p>
                <button
                  class="opt-button-1"
                  id="changeUserID"
                  name="changeUserID"
                  @click="gotoPwdLessLogin()"
                >
                  Change
                </button>
              </div>
              <div>
                <label class="labeltiny">Secret Question</label>
              </div>
              <div class="method_para">
                <p class="account_text_para">
                  {{ getFormatVcgSecretQuestion }}
                </p>
              </div>
            </div>
            <div>
              <label class="labeltiny">Answer</label>
              <div style="position: relative">
                <input
                  class="input-f"
                  id="maskedI"
                  :type="show1 ? 'text' : 'password'"
                  v-model="scqaAns"
                />
                <span
                  class="showBtn showbtnwithicon bottom_underline"
                  v-if="!show1 && scqaAns"
                  @click="show1 = !show1"
                  >Show</span
                >
                <span
                  class="showBtn showbtnwithicon bottom_underline"
                  v-if="show1 && scqaAns"
                  @click="show1 = !show1"
                  >Hide</span
                >
              </div>
              <span class="forgot_ans" v-on:click="gotoResetAnswer()">Forgot your answer</span>
            </div>
            <div>
              <p
                style="margin-top: 24px; display: flex; align-items: center"
                class="check_bg"
              >
                <input
                  class="tc check_box_border"
                  type="checkbox"
                  id="ch"
                  name="remember_device"
                  v-model="rememberThisDevice"
                />
                <label class="remember_label" for="ch"
                  ><b>Remember this device</b></label
                >
              </p>
              <p class="remember-subtag">
                Remember this device to log in quicker next time.
              </p>
            </div>
            <div class="btn_div">
              <button class="btn primary_black continue_btn" :disabled="!scqaAns" style="
                  width: 100%;
                  margin: 64px 0px;
                  font-size: 16px !important;
                  font-family: 'VzNHGeDS-bold11';
                "
                type="button"
                :class="isDisabled()"
                @click="sendOTP()"
                id="send_passcode"
                name="send_passcode"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="container-2">
        <div class="pwd-default-promo1">
          <p class="pwd-default-promo">
            Manage your business account online anytime, anywhere.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/common/mixin/commonMixins";
import Header from "@/vbgauth/components/LegalHeader";
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import ForgotFlowFooter from "../ForgotFlowFooter.vue";
import { mapActions, mapGetters } from "vuex";
import { mapWaitingActions } from "vue-wait";
export default {
  name: "Csqa",
  components: {
    Header,
    Footer,
  },
  mixins: [commonMixin],
  data() {
    return {
      show1: false,
      scqaAns: "",
      rememberThisDevice: true,
      url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif'
    };
  },
  mounted() {
    this.getVcgCsqaQuestion();
    if (this.pwdUsername == null) {
      this.$router.push({ name: "PwdLessLoginRootComponent" });
    }
  },
  computed: {
    ...mapGetters("login", [
      "gotoUrl",
      "pwdUsername",
      "makeDefaultMethodTrue",
      "optionMethod",
      "pwdPassWord",
    ]),
    ...mapGetters("cvsstepup", [
      "getFormatVcgSecretQuestion",
      "flowType",
      "fingerprintSaved",
      "otpAlerts",
      "submitLoader"
    ]),
    vzigInstanceCreated: {
      get: function () {
        return this.$store.getters["cvsstepup/vzigInstance"];
      },
    },
  },
  methods: {
    ...mapActions("cvsstepup", ["sendVcgVerifyPin"]),
    ...mapActions("login", ["redirectToOpenam"]),
    ...mapWaitingActions("cvsstepup", {
      getVcgCsqaQuestion: "request_to_complete",
    }),
    gotoPwdLessLogin() {
      if (this.gotoUrl != null) {
        this.$router.push({
          name: "PwdLessLoginRootComponent",
          query: { goto: this.gotoUrl },
        }); //.catch(err => {throw err});
      } else {
        this.$router.push({ name: "PwdLessLoginRootComponent" });
      }
    },
    isDisabled() {
      return "active";
    },
    sendOTP() {
      // let pinValidateParams = {
      //   answer: this.scqaAns,
      // };
      this.$store.dispatch(
        "cvsstepup/updateSetUserDeviceRegistration",
        this.rememberThisDevice
      );
      this.sendVcgVerifyPin(this.scqaAns);
    },
    gotoResetAnswer(){
      window.location.href = this.sanitizeUrl(iamvar_vbm_fp_redirect + '&fa=true')
    },
    getMsgIcon(index) {
        // return iamvar_cdnUrl+'account/business/login/includes/img/info.png';
        return "@/common/assets/styles/corp/images/"+index.type+".png";
      },
      messageImage(index) {
        if(index.type == 'error' || index.type == 'warning' || index.type == 'list') {
          return "newErrorMessageImage"
        } else {
          return index.type+"MessageImage";
        }
      },
      getCloseIcon() {
        return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
      },
      getAlertType (index) {
        //when error occurs take the user to the top screen where the orange banner is shown
        window.scrollTo(0,0);
        return index.type
      }, 
      deleteAlert(alert) {
        this.otpAlerts.splice(this.otpAlerts.indexOf(alert),1)
      }
  },
  watch: {
    show1: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.show1 = false;
        }, 10000);
      }
    },
    vzigInstanceCreated: function (newVal) {
      if (newVal != null) {
        // call 2FA_CQA_OPTION polling call
        this.getVcgCsqaQuestion();
      }
    },
    fingerprintSaved: function (newVal) {
      if (newVal) {
        if (this.flowType === "auth_sqa_flow" && iamvar_vcgvbgMigration) {
          this.redirectToOpenam();
        }
      }
    },
  },
};
</script>
<style scoped>
.footer-margin-top {
  margin-top: 64px;
}
.pwdless_pass_container {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.opt-container-1 {
  width: 464px;
  height: auto;
  border: solid;
  border-color: #d8dada;
  border-width: 1px;
  margin-bottom: 198px;
  border-radius: 4px;
  margin-left: 0 !important;
}
.opt-container-1-1 {
  /* height: 485px; */
  margin-top: 48px;
  margin-bottom: 48px;
  margin-left: 40px;
  margin-right: 40px;
}
.opt-header-1 {
  /* width: 382px; */
  height: auto;
  font-family: "VzNHGeDS-bold11";
  font-size: 32px;
  line-height: 38px !important;
  text-align: left;
  color: #000000;
  letter-spacing: 0.5px;
  margin-bottom: 32px;
  /*padding-top: 14px !important; */
}
.opt-p-1 {
  width: 298px;
  height: 20px;
  font-family: "VzNHGeDS-reg11";
  font-size: 16px;
  line-height: 20px !important;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: left;
}
.opt-username {
  max-width: 80%;
  height: 24px;
  font-family: "VzNHGeDS-bold11";
  margin-top: 4px;
  word-break: break-all;
  font-size: 20px;
  line-height: 24px !important;
  color: #000000;
  letter-spacing: normal;
}
.opt-button-1 {
  width: 57px;
  height: 20px;
  font-family: "VzNHGeDS-reg11";
  font-size: 16px;
  margin-top: 4px;
  margin-left: 12px;
  text-decoration: none !important;
  border-bottom: 1px solid #000 !important;
  line-height: 1 !important;
}
.labeltiny {
  font-size: 12px;
  margin-top: 25px;
  color: #000000 !important;
  line-height: 16px !important;
  letter-spacing: normal;
  text-align: left;
  font-family: "VzNHGeTX-reg11" !important;
}
.account_text_para {
  font-size: 24px !important;
  line-height: 26px !important;
  letter-spacing: 0.75px !important;
}
.input-f {
  border: 1px solid #000;
  border-radius: 5px !important;
  height: 44px;
  width: 100%;
  margin-bottom: 12px !important;
}
.showBtn {
  position: absolute;
  right: 12px;
  margin-top: 12px;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.showbtnwithicon {
  position: absolute;
  right: 40px;
  top: 0;
}
.bottom_underline {
  text-decoration: none !important;
  border-bottom: 1px solid #000 !important;
  line-height: 1 !important;
}
.check_bg input:checked {
  accent-color: #000 !important;
}
.check_box_border,
.check_box_border:hover {
  outline: none !important;
  border: 1px solid #d8dada !important;
  margin-top: 0 !important;
}
.check_box_border[type="checkbox"]:checked {
  accent-color: #000 !important;
}
.remember_label {
  margin-bottom: 0px !important;
  font-family: "VzNHGeDS-bold11", sans-serif;
}
.remember-subtag {
  margin-left: 32px;
  /* width: 296px; */
  /* height: 40px; */
  font-family: "VzNHGeDS-reg11";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #000000 !important;
}
.pushTextError {
    /* margin-top: 16px; */
    margin-right: 8px;
    /* margin-bottom: 16px; */
    margin-left: 8px;
    height: auto;
    font-size: 12px;
    line-height: 16px;
    width: 380px;
    font-family: 'VzNHGeTX-reg11';
    color: #000000 !important;
    flex: 1 !important;
}
.closeImage {
  /* margin-top: 16px; */
  /* margin-right: 16px; */
  /* margin-bottom: 36px; */
  height: 11.7px;
  width: 11.7px;
  float: right !important;
}
.warningImage {
  /* margin-top: 16px; */
  /* margin-left: 16px; */
  /* margin-bottom: 36px; */
  width: 24px;
  height: 24px;
}
.pwdPushRError {
  display: flex !important;
  height: auto;
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
  justify-content: space-between;
  border-radius: 4px;
  justify-content: space-between;
  background-color: orange
}
.pwdless_error{
display: flex;
/* align-items: center !important; */
}
.pwdPushRError.error {
    
    background-color: #FFECE0 !important;
    
}
.pwdPushRError.list {
    
    background-color: #FFECE0 !important;
    
}
.pwdPushRError.success {
    
    background-color: #DCF5E6 !important;
    
}
.pwdPushRError.warning {
    
    background-color: #FFF9DE !important;
    
}
.pwdPushRError.information {
    
    background-color: #E2F2FD !important;
    
}
@media only screen and (max-width: 1023px) {
  .footer-margin-top {
    margin-top: 48px;
  }
  .pwdless_pass_container {
    margin-top: 0px !important;
    display: block !important;
  }
  .opt-container-1 {
    width: 100% !important;
    margin-top: 0px;
    margin-bottom: 48px !important;
    border: none;
  }
  .opt-container-1-1 {
    padding: 0 16px !important;
    margin: 0 !important;
    margin-top: 32px !important;
  }
  .opt-header-1 {
    font-size: 24px !important;
    line-height: 28px !important;
    color: #000000;
    letter-spacing: normal;
  }
  .opt-header-1-1 {
    margin-top: 24px;
  }
  .opt-p-1 {
    font-size: 12px;
    line-height: 20px !important;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 4px !important;
    font-family: "VzNHGeTX-reg11";
    width: 298px;
    height: 20px;
  }
  .opt-username {
    font-size: 16px;
    line-height: 20px !important;
    letter-spacing: 0.5px;
    color: #000000;
    text-align: left;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-family: "VzNHGeDS-bold11";
    max-width: 80%;
    height: 24px;
    word-break: break-all;
  }
  .opt-button-1 {
    width: 57px;
    height: 20px;
    font-family: "VzNHGeDS-reg11";
    font-size: 16px;
    margin-top: 0px !important;
    margin-left: 12px;
    text-decoration: none !important;
    border-bottom: 1px solid #000 !important;
    line-height: 1 !important;
  }
  .account_text_para {
    font-size: 24px !important;
    line-height: 26px !important;
    letter-spacing: 0.75px !important;
  }
  .showBtn {
    margin-top: 13px !important;
  }
  .remember_label {
    margin-top: 0px !important;
  }
  .continue_btn {
    margin-top: 48px !important;
    letter-spacing: 0.5px;
  }
  .closeImage {
  /* margin-top: 16px; */
  /* margin-right: 16px; */
  /* margin-bottom: 36px; */
  float: right !important;
}
}
.pwd-default-promo1 {
  background: #f6f6f6;
  height: 650px;
  width: 464px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.pwd-default-promo {
  height: 128px;
  width: 253.78px;
  color: #a7a7a7;
  font-family: "VzNHGeDS-bold11";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

@media only screen and (min-width: 1201px) {
  .pwdless_pass_container {
    display: flex;
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 280px) and (max-width: 1200px) {
  .container-2,
  .pwd-default-promo1,
  .pwd-default-promo {
    display: none !important;
  }
}
.forgot_ans{
float: right;
text-decoration: underline;
cursor: pointer;
color: #000000;
}
</style>