import {getDeviceFingerprintInfo} from '../../../../public/vbgIAMfb.js';
import axios from 'axios';
import router from '@/vbgauth/router';
import translations from '@/common/assets/json/messages1.json';

const state = {
  submitLoader: false,
  callbacks: '',
  auth_id: '',
  otpCallbacks: '',
  otpData: '',
  AuthError: '',
  AuthStatus: '',
  errorCallbacks: '',
  accountDisabled:'',
  //tokenId: false,
  accountLock: false,
  amHeaders: {
    'Accept': 'application/json, text/plain, */*',
    'Accept-API-Version': 'resource=2.0',
    'Content-Type': 'application/json',
  },
  session: false,
  gotourl: "",
  sessionInfo: true,
  gotoSessionUrl: "",
  Timer: "",
};

// const env_cdnUrl = "http://localhost:4001/"
const actions = {
  async getSessionInfo({commit}) {
    commit('setAuthError', false);
    commit('setAccountDisabled',false);
    

    var config = {
      method: 'post',
      url: '/citizen/json/sessions?_action=getSessionInfo',
      headers: state.amHeaders,
      withCredentials: true
    };
    axios(config)
      .then(function (response) {
        if (state.gotourl === '') {
          window.location.href = state.gotoSessionUrl;
        } else {
          window.location.href = state.gotourl;
        }
      })
      .catch(function (error) {
        commit('setSessionInfo', false)
      })
  },
  async getcallbacks({commit,dispatch}, formdata) {
    commit('setLoader', true)
    commit('setAuthError', false)
    var config = {
      method: 'post',
      url: const_amEndpoint,
      headers: state.amHeaders,
      withCredentials: true
    };
    const response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return null;
      });
    commit('setCallbacks', response);
    dispatch('onLogin', formdata)
  },
  updateGotoUrl({commit}, goto) {
    //console.log(goto)
    commit('setgotourl', goto);
  },
  updateGotoSessionUrl({commit}, goto) {
    let gotoSession = goto
    //console.log(gotoSession)
    commit('setGotoSessionUrl', gotoSession)
  },
  async onOtpCallbacks({commit,getters,dispatch}, resendOtp) {
    var data = JSON.parse(JSON.stringify(getters.getOTPData));
    var config = {
      method: 'post',
      url: const_amEndpoint,
      headers: state.amHeaders,
      data: data,
      withCredentials: true
    };
    if (resendOtp) {
      axios(config)
        .then(function (response) {
          var callbacks = JSON.parse(JSON.stringify(response.data))
          if ('tokenId' in callbacks) {
            if (state.gotourl === '') {
              window.location.href = state.gotoSessionUrl;
            } else {
              window.location.href = state.gotourl;
            }
          } else if (translations.en.message[callbacks.callbacks[0].output[0].value]) {
            commit('setLoader', false)
            commit('setAuthError', {
              errorMessage: translations.en.message[callbacks.callbacks[0].output[0].value]
            })
          } else {
            var otpCallbacks = response.data;
            commit('setAuthError', {
              errorMessage: translations.en.message["RESEND_OTP"]
            })
            commit('setLoader', false)
            commit('setOtpCallbacks', otpCallbacks);
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      axios(config)
        .then(function (response) {
          var callbacks = JSON.parse(JSON.stringify(response.data))
          if ('tokenId' in callbacks) {
            if (state.gotourl === '') {
              window.location.href = state.gotoSessionUrl;
            } else {
              window.location.href = state.gotourl;
            }
          } else if (translations.en.message[callbacks.callbacks[0].output[0].value]) {
            var data = response.data;
            var config = {
              method: 'post',
              url: const_amEndpoint,
              headers: state.amHeaders,
              data: data,
              withCredentials: true
            };
            axios(config).then(function (response) {})
              .catch(function (error) {
                commit('setLoader', false)
                commit('setAuthError', {
                  errorMessage: translations.en.message[callbacks.callbacks[0].output[0].value]
                })
              });
          } else {
            var otpCallbacks = response.data;
            commit('setLoader', false)
            commit('setOtpCallbacks', otpCallbacks);
            router.push({
              path: '/otp'
            });
          }
        })
        .catch(function (error) {});
    }
  },
  async onLogin({ commit, getters, dispatch}, formdata) {
    commit('setAuthError', false);
    commit('setAccountDisabled',false);
    var data = JSON.parse(JSON.stringify(getters.getterCallbacks));
    data.callbacks[0].input[0].value = formdata.username;
    data.callbacks[1].input[0].value = formdata.password;
    let stateHeaders = state.amHeaders;
    // if (isRedesignFlow) {
    //   stateHeaders['X-VZ-REDESIGN'] = true;
    // }
    var config = {
      method: 'post',
      url: const_amEndpoint,
      headers: stateHeaders,
      data: data,
      withCredentials: true
    };
    var locale = localStorage.getItem('lng') || 'en';
    const response = await axios(config)
      .then(function (response) {
        var data = response.data
        var callbacks = JSON.parse(JSON.stringify(response.data));
        if ('tokenId' in callbacks) {
          //GTM dataLayer
          var dataLayerSignin = window.dataLayer = window.dataLayer || [];
          dataLayerSignin.push({
            'event': 'login',
            'detailed_event': 'User Signed In',
            'user_data': {
              'user_login_state': 'loggedin'
            }
          });       
          if (state.gotourl === '') {
            window.location.href = state.gotoSessionUrl;
          } else {
            window.location.href = state.gotourl;
          }
        }
        var authMessage = callbacks.callbacks[0].output[0].value;
        if (translations.en.message[authMessage]) {
          //GTM dataLayer
          var dataLayer = window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'error',
            'error_type': translations.en.message[authMessage]
          });
          var config = {
            method: 'post',
            url: const_amEndpoint,
            headers: stateHeaders,
            data: data,
            withCredentials: true
          };
          axios(config).then(function (response) {
              commit('setLoader', false)
              if (authMessage == "INVALID_CREDENTIALS") {
                commit('setAuthError', {
                  errorMessage: locale == 'en' ? translations.en.message[authMessage] : translations.en.message["commonerr"]
                })
                commit('setCallbacks', response.data)
              } else if (authMessage == "INVALID_CREDENTIALS_PWD_RESET") {
                commit('setAuthError', {
                  errorMessage: translations.en.message[authMessage]
                })
                commit('setCallbacks', response.data)
              } 
            })
            .catch(function (error) {
              commit('setLoader', false)
              var dataLayer = window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                'event': 'error',
                'error_type': translations.en.message[authMessage]
              });
              if (authMessage == "ACCOUNT_OTP_METHOD_LOCKED") {
                commit('setAccountLock', true)
                router.push({
                  path: '/otp'
                })
              } else if (authMessage == "ACCOUNT_LOGIN_LOCKED") {
                commit('setAuthError', {
                  errorMessage: locale == 'en' ? translations.en.message["ACCOUNT_REDESIGN_LOGIN_LOCKED"] : translations.en.message["tempdisable"]
                })
                commit('setAccountDisabled',true);
                commit('setCallbacks', response.data);              
              } else if (authMessage == "INVALID_CREDENTIALS_PWD_EXPIRED") {
                commit('setAuthError', {
                  errorMessage: translations.en.message[authMessage]
                })
              } else if (authMessage == "NON_VA_ACCOUNT") {
                commit('setAuthError', {
                  errorMessage: translations.en.message[authMessage]
                })
              }
            });
        } else {
          let deviceFingerPrint = getDeviceFingerprintInfo();
          data.callbacks[1].input[0].value = deviceFingerPrint.value;
          commit('setAuthError', false)
          commit('setOtpData', data);
          dispatch('onOtpCallbacks', false)
        }
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          var dataLayer = window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'error',
            'error_type': locale == 'en' ? translations.en.message["INVALID_CREDENTIALS"] : translations.en.message["commonerr"]
          });
          commit('setLoader', false)
          commit('setAuthError', {
            errorMessage: locale == 'en' ? translations.en.message["INVALID_CREDENTIALS"] : translations.en.message["commonerr"]
          })
        } else {
          //GTM dataLayer
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'error',
            'error_type': 'Sign In Link Expired'
          });
          // End of GTM dataLayer
          commit('setLoader', false)
          commit('setAuthError', {
            errorMessage: "An error has occurred"
          })
        }
      });
  },
  async onSendOtp({commit,getters,dispatch}, otp) {
    commit('setLoader', true)
    commit('setAuthError', false)
    var data = JSON.parse(JSON.stringify(getters.getOtpCallbacks));
    data.callbacks[0].input[0].value = otp;
    var config = {
      method: 'post',
      url: const_amEndpoint,
      headers: state.amHeaders,
      data: data,
      withCredentials: true
    };
    const response = await axios(config)
      .then(function (response) {
        var data = response.data
        var callbacks = JSON.parse(JSON.stringify(response.data))
        if ('tokenId' in callbacks) {

          if (state.gotourl === '') {
            window.location.href = state.gotoSessionUrl;

          } else {
            window.location.href = state.gotourl;
          }
        } else {
          if (translations.en.message[callbacks.callbacks[0].output[0].value]) {
            commit('setLoader', false)
            //GTM DataLayer
            var dataLayer = window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'error',
              'error_type': translations.en.message[callbacks.callbacks[0].output[0].value]
            });
            var config = {
              method: 'post',
              url: const_amEndpoint,
              headers: state.amHeaders,
              data: data,
              withCredentials: true
            };
            axios(config)
              .then(function (response) {
                commit('setAuthError', {
                  errorMessage: translations.en.message[callbacks.callbacks[0].output[0].value]
                })
              })
              .catch(function (error) {
                if (error.response) {
                  var errorData = error.response.status
                  if (translations.en.message[callbacks.callbacks[0].output[0].value]) {
                    commit('setAccountLock', true)
                  }
                }
              });
          }
        }
      })
      .catch(function (error) {});
  },
  updateAuthError({commit}, value) {
    commit('setAuthError', value);
  },
};

const mutations = {
  setLoader: (state, payload) => state.submitLoader = payload,
  setCallbacks: (state, callbacks) => (state.callbacks = callbacks),
  setOtpCallbacks: (state, callbacks) => (state.otpCallbacks = callbacks),
  setOtpData: (state, data) => (state.otpData = data),
  setAuthError: (state, data) => (state.AuthError = data),
  setAccountLock: (state, data) => (state.accountLock = data),
  setTokenId: (state, data) => (state.tokenId = data),
  setSession: (state, data) => (state.session = data),
  setgotourl: (state, data) => (state.gotourl = data),
  setSessionInfo: (state, data) => (state.sessionInfo = data),
  setGotoSessionUrl: (state, data) => (state.gotoSessionUrl = data),
  setTimer: (state, data) => (state.Timer = data),
  setAccountDisabled: (state, data) => (state.accountDisabled = data)
};

const getters = {
  submitLoader: state => state.submitLoader,
  getterCallbacks: state => state.callbacks,
  getOtpCallbacks: state => state.otpCallbacks,
  getOTPData: state => state.otpData,
  getAuthError: state => state.AuthError,
  getAccountLock: state => state.accountLock,
  getTokenId: state => state.tokenId,
  getSession: state => state.session,
  getGoToUrl: state => state.gotourl,
  getInfo: state => state.sessionInfo,
  getGotoSessionUrl: state => state.gotoSessionUrl,
  getTimer: state => state.Timer,
  getAccountDisabled: state => state.accountDisabled
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
