<template>
    <div>
        <Header></Header>
            <div class="otp_validate_container">
                <BlockUI v-if="submitLoader" :url="url"></BlockUI>
                <v-form>
                <div class="opt-container-1">
                    <div v-if="this.showTokLink">
                      <div v-if="otpAlerts.length > 0" class="pwdPushRError" v-for="j in otpAlerts" :key="j" v-bind:class="getAlertType(j)">
                        <div class="warningImage" :class="messageImage(j)"></div>
                        <p class="pushTextError" v-html="j.msg">{{j.msg}}</p>
                        <img class="closeImage" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                      </div>
                    </div>
                    <div v-if="!this.showTokLink" class="opt-container-1-1">
                        <h3 v-if="selectedOtpDevice.deviceType == 'IVR'" class="opt-header-1">We're calling you with a one-time passcode</h3>
                        <h3 v-else class="opt-header-1">Your passcode was sent</h3>
                        <div class="user_id">
                            <p class="opt-p-1">User ID</p>
                            <div style="display: flex;">
                                <p class="opt-username">{{this.pwdUsername}}</p>
                                <button class="opt-button-1" id="changeUserID" name="changeUserID" @click="gotoPwdLessLogin()">Change</button>
                            </div>
                        </div> 
                        <div style="margin-top: 48px; margin-bottom: 36px; color:#000000;" class="p-2 dynamic_para_div">
                            <p v-if="selectedOtpDevice.deviceType == 'IVR'" class="opt-msg">Answer the automated phone call and use the passcode to continue logging in to your account.</p>
                            <p v-else-if="selectedOtpDevice.deviceType == 'BASIC'" class="opt-msg">Check your text messages and use the passcode to continue logging in to your account.</p>
                            <p v-else-if="selectedOtpDevice.deviceType == 'EMAIL'" class="opt-msg">Check your email and use the passcode to continue logging in to your account.</p>
                            <div style="display: flex; margin-top: 12px;">
                                <p class="opt-deli-label">{{this.deviceTypeLabel}}</p>
                                <button class="opt-button-1-1" id="changeDeliveryMethod" name="changeDeliveryMethod" @click="gotoPwdLessOtpDelivery()">Change</button>
                            </div>
                            <div style="display: flex;" class="passcode_div">
                                <p class="timer_content">This passcode will expire in </p>
                                <p style="margin-left: 2px; " class="timer_num" id="expiretime"></p>
                                <p style="margin-left: 2px;" class="timer_content">minutes.</p>
                            </div>
                        </div>
                        <div class="otpfield">
                            <p class="otptitle input_label_otp">One-time passcode</p>
                            <div>
                            <input id="otpCode" name="otpCode" ref="otpRef" v-model="otpCode" type="password" required style="background-color: none !important;" class="input-f input_mr" autocomplete="off" :tabindex="devices.length + 4" maxlength="6">
                            
                            <!--<div class="pwdLessOtpValidateMessageContent" v-if="otpAlerts.length > 0">
                              <div class="row" v-for="j in otpAlerts" :key="j">
                                <div v-bind:class="getAlertType(j)">
                                    <p v-if="j.type == 'error' || j.type == 'warning'" style="padding:0px;margin:0px" v-html="j.msg">{{j.msg}}</p>
                                  </div>
                                </div>
                              </div>-->
                            </div>
                        </div>
                        <div class="pwdLoginTextError" v-if="this.pwdInlineError != ''" id="inlineError"><p class="inline_err">{{ this.pwdInlineError }}</p></div>
                        <div class="resend-field">
                            <a v-if="selectedOtpDevice.deviceType == 'IVR'" @click="$event.preventDefault();resendPasscode();" href="#" class="resend-field-call" :tabindex="devices.length + 12"
                            id="callAgain">Call me again</a>
                            <a v-else-if="selectedOtpDevice.deviceType == 'EMAIL'" @click="$event.preventDefault();resendPasscode();" href="#" class="resend-field-otr" :tabindex="devices.length + 12"
                            id="resendText">Resend text message</a>
                            <!-- <a v-else @click="$event.preventDefault();resendPasscode();" href="#" class="resend-field-otr" :tabindex="devices.length + 12">Resend passcode</a> -->
                            <a v-else @click="$event.preventDefault();resendPasscode();" href="#" class="resend-field-otr" :tabindex="devices.length + 12"
                            id="resendText2">Resend text message</a>
                        </div>
                        <div>
                            <button
                                class="btn primary_black continue-btn"
                                style="width: 100%; margin: 48px 0px 64px 0px;font-size: 16px !important; height: 44px !important; font-family: 'VzNHGeDS-bold11';"
                                type="button"
                                :class="isDisabled()"
                                @click="validateOtpCode(false)"
                                id="submit_passcode"
                                name="submit_passcode"
                            >
                                Continue
                            </button>
                        </div>
                        <p v-if="this.formData[1].input[0].value == ''" style="margin-bottom: 98px; font-size: 16px;" @click="supportUrl()" id="loginAnotherWay" name="loginAnotherWay">
                          <b class="anotherCls">
                              <span class="left_caret_spacing">
                              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                               <path d="M6.5 1L1.5 6L6.5 11" stroke="black" stroke-width="2"/>
                              </svg>
                            </span> 
                            <span>Log in another way</span></b>
                        </p>
                    </div>
                    <div v-if="this.showTokLink" class="opt-container-1-1">
                        <h3 class="opt-header-1">Your verification link was sent</h3>
                        <div class="user_id">
                            <p class="opt-p-1">User ID</p>
                            <div style="display: flex;">
                                <p class="opt-username">{{this.pwdUsername}}</p>
                                <button class="opt-button-1" id="changeUserID" name="changeUserID" @click="gotoPwdLessLogin()">Change</button>
                            </div>
                        </div> 
                        <div style="margin-top: 48px; margin-bottom: 36px; color:#000000;" class="p-2 dynamic_para_div">
                            <p class="selected_otddevice_Content" v-if="selectedOtpDevice.deviceType == 'SMARTPHONE'">Check your text messages and use the verification link to continue logging in to your account.</p>
                            <p class="selected_otddevice_Content" v-else-if="selectedOtpDevice.deviceType == 'EMAIL'">Check your email and use the verification link to continue logging in to your account.</p>
                            <div style="display: flex; margin-top: 8px;">
                                <p class="opt-button-1-1_label">{{this.deviceTypeLabel}}</p>
                                <button class="opt-button-1-1" id="changeDeliveryMethod" name="changeDeliveryMethod" @click="gotoPwdLessOtpDeliveryFromTok()">Change</button>
                            </div>
                            <div style="display: flex;" class="passcode_div">
                                <p class="timer_content">This link will expire in </p>
                                <p style="margin-left: 2px; " class="timer_num" id="expiretime"></p>
                                <p class="timer_content" style="margin-left: 2px;">minutes.</p>
                            </div>
                        </div>
                        <div>
                            <button
                                class="btn secondary_white resent_verify_link"
                                style="width: 100%; margin-bottom: 16px; font-size: 16px !important; height: 48px !important; font-family: 'VzNHGeDS-bold11';"
                                type="button"
                                @click="resendPasscode()"
                                id="resend_verification"
                                name="resend_verification"
                            >
                                Resend verification link
                            </button>
                        </div>
                        <div style="margin-bottom: 64px;" class="resend-field">
                            <a @click="$event.preventDefault();dontHaveOTPAccess();" href="#" class="resend-field-otr" :tabindex="devices.length + 12" id="linkNotReceived" name="linkNotReceived">I didn’t receive a verification link</a>
                        </div>
                        
                        <p v-if="this.formData[1].input[0].value == ''" style="margin-bottom: 98px; font-size: 16px;" @click="supportUrl()" id="loginAnotherWay" name="loginAnotherWay">
                          <b class="anotherCls">
                              <span class="left_caret_spacing">
                              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
                               <path d="M6.5 1L1.5 6L6.5 11" stroke="black" stroke-width="2"/>
                              </svg>
                            </span> 
                            <span>Log in another way</span></b>
                        </p>
                    </div>
                </div>
                <div>
              <div id="noAccessToOtpModal" class="modal fade">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <!-- <div v-if="this.cantrcvLink"> -->          
                  <!-- </div> -->
                  <!-- <div v-if="this.checkVerifyPopUp"> -->
                  <div class="modal-content modal-content-container sdar-modal-content">
                    <div class="modal-container">
                      <div class="close-icon-container" tabindex="1">
                        <a data-dismiss="modal" @click="closeModal()">
                          <img src="@/common/assets/styles/corp/images/close_black.png" style="height:20px; width: 20px;" />
                        </a>
                      </div>
                      <div class="window-content mar-bottom50">
                        <div v-if="this.checkVerifyPopUp">
                          <h2 v-if="verificationSent" class="modal-content-title">Your verification link was sent.</h2>
                          <h2 v-else class="modal-content-title">Your verification link was resent.</h2>
                          <p v-if="selectedOtpDevice.deviceType == 'EMAIL'" class="modal-content-description">Check your email and use your verification link to continue logging in to your account. <br> 
                          </p>
                          <p v-else class="modal-content-description">Check your text message and use the verification link to continue logging into your account. <br> 
                          </p>
                          <p v-if="selectedOtpDevice.deviceType == 'EMAIL'" class="modal-content-description">This verification link will expire in {{ this.aaEmailTimeout }} minutes.</p>
                          <p v-else class="modal-content-description">This verification link will expire in {{ this.aaSmsTimeout }} minutes.</p>
                        </div>
                        <div v-if="this.cantrcvLink">
                          <h2 v-if="((flowType == 'forgot_username' || flowType == 'forgot_password' || flowType == 'auth') && !this.vbgFlow)" style="height: auto;" class="martop0 marbottom20 modal-content-title">Did not receive your verification link?</h2>
                          <h2 v-else class="martop0 marbottom20 modal-content-title">Can't receive a one time passcode?</h2>
                          <p class="modal-content-description">
                            Please try another delivery method, contact your company administrator, or 
                            <a href="https://www.verizon.com/business/support/" style="text-decoration: underline; cursor: pointer;" class="vbg-ff-focus" target="_blank" tabindex="2">visit our support page.</a>
                          </p>
                        </div>
                      </div>

                      <div class="modal-loader" v-if="this.checkVerifyPopUp">
                        <CustomLoaderComponent></CustomLoaderComponent>
                      </div>
                      
                      <div class="btn-container" v-if="this.checkVerifyPopUp">
                        <button type="submit" class="button-primary-large-copy btn secondary_white vbg-rel-bottom-40" tabindex="2" style="cursor: pointer; font-size: 16px; letter-spacing: 0.5px;" @click="resendPasscode()">
                        Resend verification link
                        </button>
                        <div class="margin-bottom-medium padright0 padleft0 col-xs-12 text-link vbg-rel-bottom-40">
                          <a class="vz-a link-text" @click="dontHaveOTPAccess()" tabindex="3">
                            I didn’t receive a verification link
                          </a>
                        </div>
                      </div>

                      <div class="modal-btn-container martop100" v-if="this.cantrcvLink">
                        <button type="submit" data-dismiss="modal" @click="closeModal()" class="btn primary_black marright20 martop50 oauth-close-button bttn-container vbg-rel-bottom-40" tabindex="3">
                          <span>Close</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
                </v-form>
                <div class="opt-container-2">
                    <div class="opt-default-promo1">
                        <p class="opt-default-promo">Manage your business account online anytime, anywhere.</p>
                    </div>
                </div>
            </div>
        <Footer class="footer-margin-top"></Footer>
        <div>
          <div id="showSdarPopup" class="modal fade">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content sdar_popup">
                <ConfirmSecurityDetailsOrDoLaterPopUp @completeSecurityProfile="completeSecurityProfile"
                @doitLater="doitLater" />
              </div>
            </div>
          </div>
        </div> 
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import commonMixin from '@/common/mixin/commonMixins'
import Header from '@/vbgauth/components/LegalHeader'
import Footer from '@/vbgauth/components/ForgotFlowFooter'
import translations from '@/common/assets/json/messages1.json'
import '@/common/assets/styles/tooltip.css' 
import loadingImage from '@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif'
import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
import ConfirmSecurityDetailsOrDoLaterPopUp from "@/vbgauth/components/hybridExp/popups/ConfirmSecurityDetailsOrDoLaterPopUp";
export default {
  name: 'PwdLessOtpValidate',
  components: {
    Header,
    Footer,
    ConfirmSecurityDetailsOrDoLaterPopUp
  },
  mixins: [commonMixin],
  data () {
    return {
        content: translations.en.message['login_verify_and_dont_register_tooltip'],
        showResendOtpLink: false,
        isInFrame: true,
        otpCode: '',
        otpValidationInputClass: "",
        // resendPasscodeToSelectedDevice: false,
        deviceTypeLabel: '',
        rememberThisDevice: false,
        url : iamvar_cdnUrl + iamvar_imgSourceContext +'/business/login/includes/circle-loaderWHITE_48x48.gif',
        showTokLink : false
    }
  },
  mounted () {
    setGotoUrlInClientLib(this.getParameterByName('goto'))
    if(!this.vbgFlow) {
      if(this.selectedOtpDevice.deviceType == 'SMARTPHONE' || this.selectedOtpDevice.deviceType == 'EMAIL') {
        this.showTokLink = true
      }
    }
    if(this.showTokLink) {
      this.changeContextBiocatch('pwdless_login_adeptive_auth_page')
    } else {
      this.changeContextBiocatch('pwdless_login_otp_validate_page')
    }
    if(!this.vbgFlow) {
      if(this.selectedOtpDevice.deviceType == 'EMAIL') {
        this.pwdTimer(15)
      } else {
        this.pwdTimer(10)
      }
    } else {
      this.pwdTimer(10)
    }
    
    if (this.pwdUsername == null) {
      this.$router.push({ name: 'PwdLessLoginRootComponent'})
    }
    this.$store.dispatch('cvsstepup/updateOTPAlert', [] , {root:true})
    this.gotoUrl = this.$route.query.goto;
    // this.rememberThisDevice = this.$route.params.rememberThisDevice;
    if (this.gotoUrl != null) {
        // determine if there is a goto with a hashtag
        let hashValue = window.location.hash.substr(1);
        if (hashValue != "") {
          let hashInUrl = '#' + window.location.hash.substr(1);
          if (this.gotoUrl != null) {
            this.gotoUrl += hashInUrl;
            this.$store.dispatch('login/updateGotoUrl', this.gotoUrl);
          }
        }
    }
    if(this.vbgFlow) {
     var deviceTypeLabelValue =  this.selectedOtpDevice.label.split(' ')
     this.deviceTypeLabel  = deviceTypeLabelValue[deviceTypeLabelValue.length - 1]
    } else if (!this.vbgFlow) {
      this.deviceTypeLabel = this.selectedOtpDevice.deviceValue
    }
    this.observePasswordField()
    //browser back button disabled
    window.onpopstate = () => {
        window.history.forward();
      };
  },
  beforeDestroy() {
    if(this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    getMsgIcon(index) {
        // return iamvar_cdnUrl+'account/business/login/includes/img/info.png';
        return "@/common/assets/styles/corp/images/"+index.type+".png";
      },
      messageImage(index) {
        if(index.type == 'error' || index.type == 'warning' || index.type == 'list') {
          return "newErrorMessageImage"
        } else {
          return index.type+"MessageImage";
        }
      },
      getCloseIcon() {
        return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
      },
      getAlertType (index) {
        //when error occurs take the user to the top screen where the orange banner is shown
        window.scrollTo(0,0);
        return index.type
      }, 
      deleteAlert(alert) {
        this.otpAlerts.splice(this.otpAlerts.indexOf(alert),1)
      },  
    async resendPasscode() {
      eventTracking('One Time Passcode Resend Request Initiated','');
        let self = this
        self.otpSent = false
        self.showResendOtpLink = false
        this.resendPasscodeToSelectedDevice = true
        if(!this.vbgFlow) {
          if(this.selectedOtpDevice.deviceType == 'EMAIL' || this.resendDevice.deviceType == 'EMAIL') {
            this.pwdTimer(15)
          } else {
            this.pwdTimer(10)
          }
        } else {
          this.pwdTimer(10)
        }
        this.resendOtpToDevice(this.selectedOtpDevice)
        if(!this.vbgFlow) {
          if(this.selectedOtpDevice.deviceType == 'SMARTPHONE' || this.selectedOtpDevice.deviceType == 'EMAIL') {
            this.updateVerificationSent(false);
          }
        }
      },
      openUnableReceiveOverlay() {
        this.unableToReceiveOtp()
      },
      isDisabled() {
        if (!this.otpSent || !this.otpCode) {
          return 'inactive'; 
        }
      },
      validateOtpCode (saveFingerprint) {
        if(!this.otpSent || !this.otpCode) {
          // the continue button is disabled and should not be clickable
        } else {
          eventTracking('One Time Passcode Submitted','');
          this.showResendOtpLink = false

          // trim spaces at the beginning and at the end of otp
          this.otpCode = this.otpCode.trim()
          if (this.$store.getters['cvsstepup/getregisterDevice']) {
            saveFingerprint = true
          }
          var otpValidateParams = {
              "otpCode" : this.otpCode,
              "saveFingerprint": saveFingerprint
          }
          this.validateOtp(otpValidateParams)
        }
      },
      pwdTimer(maxMinutes) {
        clearInterval(interval);
        let secondsRemaining = maxMinutes * 60;
        // let minutes; let seconds;
        var current_time = Date.parse(new Date());
        var endTime = new Date(current_time + maxMinutes*60*1000);
        interval = setInterval(function(triggerInlineError){
          var diff = Date.parse(endTime) - Date.parse(new Date());
          var seconds = 0;
          var minutes = 0;
          if(diff >=0){
            seconds = Math.floor( (diff/1000) % 60 );
            minutes = Math.floor( (diff/1000/60) % 60 );
          }
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;  
          if(document.getElementById('expiretime')) {
            document.getElementById('expiretime').innerHTML = minutes + ":" + seconds;
          }
          // time is up
          if (seconds === "00" && minutes === "00") {         
            clearInterval(interval);
            setTimeout(function () {
              let msg = "The verification code you’ve entered is invalid or expired.Please try again or click Resend to get a different code.";
              this.pwdInlineError = msg;
              triggerInlineError(msg);
              //if(document.getElementById("inlineError")) {
                    document.getElementById('otpCode').classList.add('pwdLessAlert')
                //document.getElementById("inlineError").setAttribute('style', 'display:flex')
                //document.getElementById("inlineError").innerHTML = "The verification code you’ve entered is invalid or expired.</b><br>Please try again or click Resend to get a different code.";
              //}
              }, 3000)         
          }
            secondsRemaining--;
        }, 1000, this.updateInlineError);
      },
      cancelVerification() {
        this.$store.dispatch('cvsstepup/cancelVerification')
        this.$store.dispatch('cvsstepup/resetState')
        this.$store.dispatch('forgotflow/resetState')
        // this.$router.go(-3)
        if(this.flowType === 'forgot_username')
          this.$router.push({ name: 'AMForgotUsrnmComponent'}).catch(err => {throw err})
        else if(this.flowType === 'forgot_password')
          this.$router.push({ name: 'AMForgotPwdComponent'}).catch(err => {throw err})
      },
      dontHaveOTPAccess() {
        $("#noAccessToOtpModal").modal({
          backdrop: "static"
        });
        this.updatecantrcvLink(true)
      },
      getOAuthClass(className = 'oauth-flow', subClass = '') {
        return className + ' ' + subClass;
      },
      supportUrl(){
        if(this.gotoUrl != null) {
            this.$router.push({ name: 'PwdLessOption', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
        } else {
            this.$router.push({ name: 'PwdLessOption' });
        }
        this.$store.dispatch('cvsstepup/cancelVerification')
    },
      getHostName(url) {
          var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
          if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
            return match[2];
          } else {
            return null;
          }
      },
      continueButtonChange() {
      Vue.nextTick(() => {

      })
    },
      ...mapActions('cvsstepup', [
                'validateOtp',
                'unableToReceiveOtp',
                'sendOtpToDevice',
                'resendOtpToDevice',
                'updatecantrcvLink',
                'updateOTPAlwaysOn',
                'updateVerificationSent',
                'initializeStepup',
                'updateSelectedDevice',
                'updateOTPAlert',
                'updateShowChangeError',
                'updateInlineError'
            ]
        ),
      ...mapActions('forgotflow', [
                'updateUserVerified',
                'setFFTransactionId',
                'setFFUsername',
                'getUsername'
            ]
        ),
      ...mapActions('login', ['redirectToOpenam']),
    gotoPwdLessLogin() {
        if(this.gotoUrl != null) {
            this.$router.push({ name: 'PwdLessLoginRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
        } else {
            this.$router.push({ name: 'PwdLessLoginRootComponent' });
        }
        this.$store.dispatch('cvsstepup/cancelVerification')
    },
    closeModal() {
      $("#noAccessToOtpModal").modal('hide')
    },
    gotoPwdLessOtpDelivery() {
      this.cancelVerification();
      this.initializeStepup();
      this.updateShowChangeError(true);
      this.gotoUrl ? this.$router.push({ name: 'PwdLessOtpDelivery', query: { goto: this.gotoUrl }}) : this.$router.push({ name: 'PwdLessOtpDelivery' });
    }, 
    gotoPwdLessOtpDeliveryFromTok() {
      if(!this.vbgFlow) {
        this.updatecantrcvLink(false);
        this.updateShowChangeError(true);
        this.updateVerificationSent(true);
        this.updateSelectedDevice(this.defaultSelectedDevice)
      }
      this.updateOTPAlert([])
      this.cancelVerification();
      this.initializeStepup();
      this.gotoUrl ? this.$router.push({ name: 'PwdLessOtpDelivery', query: { goto: this.gotoUrl } }) : this.$router.push({ name: 'PwdLessOtpDelivery' });
    },
    showSdarPopup() {
      this.$store.dispatch('login/updateDisplayPopupClass', true)
      $("#showSdarPopup").modal({
        backdrop: "static",
      });
    },
    completeSecurityProfile() {
      $("#showSdarPopup").modal('hide')
      // sending user selection option to AM
      setSelectedValueInClientLibForCreatingSession("1");
      // redirecting to AM for logging in the user
      this.redirectToOpenam(this.sdarUserType);
    },
    doitLater() {
      // sending user selection option to AM
      setSelectedValueInClientLibForCreatingSession("2");
      // redirecting to AM for logging in the user
      this.redirectToOpenam(this.sdarUserType);
    },
    observePasswordField() {
      const passwordField = this.$refs.otpRef;
      //added MutationObserver to check if 'type' attrbute of input feild has changed
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if(mutation.attributeName === 'type' && passwordField.type !== 'password') {
            passwordField.type = 'password'
          }
        })
      })
      const config = { attributes: true };
      this.observer.observe(passwordField, config);

      passwordField.addEventListener('input', () => {
        if (passwordField.type !== 'password') {
          passwordField.type = 'password';
        }
      })
    },
  },
  computed: {
    ...mapGetters('cvsstepup', [
      'dynamicErrorClasses',
      'fingerprintSaved',
      'sqaDisabled',
      'isPwReset',
      'alerts',
      'dialogMsg',
      'dialogTitle',
      'submitLoader',
      'currentStep',
      'transactionId',
      'username',
      'flowType',
      'email',
      'vbgFlow',
      'otpValidationError',
      'selectedOtpDevice',
      'otpDeliveryMsg',
      'otpAlwaysOn',
      'urlOnMount',
      'cantrcvLink',
      'devices',
      'flowInitialized',
      'stepupReason',
      'urlOnMount',
      'otpAlwaysOn',
      'dnrStatus',
      'defaultSelectedDevice',
      'resendDevice'
    ]),
    ...mapGetters('profile', [ 'sdarUserType' ]),
    otpSent: {
      get: function () {
        return this.$store.getters['cvsstepup/otpSent']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateOtpSent', newVal)
      }
    },
    pwdInlineError: {
      get: function() {
        return this.$store.getters['cvsstepup/pwdInlineError']
      },
      set: function(newVal) {
        this.$store.commit('cvsstepup/setPwdInlineError', newVal)
      }
    },
    resendVerificationToSelectedDevice: {
      get:function() {
        return this.$store.getters['cvsstepup/resendVerificationToSelectedDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateResendVerificationToSelectedDevice', newVal)
      }
    },
    otpAlerts: {
      get: function() {
        return this.$store.getters['cvsstepup/otpAlerts']
      },
      set: function(newVal) {
        this.$store.commit('cvsstepup/setOTPAlert', newVal)
      }
    },
    dialog: {
      get: function () {
        return this.$store.getters['cvsstepup/dialog']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateShowDialog', newVal)
      }
    },
    resendPasscodeToSelectedDevice: {
      get:function() {
        return this.$store.getters['cvsstepup/resendPasscodeToSelectedDevice']
      },
      set: function (newVal) {
        this.$store.dispatch('cvsstepup/updateResendPasscodeToSelectedDevice', newVal)
      }
    },
    ...mapGetters('login', [ 'gotoUrl', 'pwdUsername' ]),
    formData: {
        get: function () {
            return this.$store.getters['login/formData'];
        },
        set: function (newVal) {
            this.$store.dispatch('login/updateFormData', newVal);
        }
    },
    pwdAuthOptions : {
        get: function () {
            return this.$store.getters['login/pwdAuthOptions'];
        }
    },
    gotoUrl: {
            get: function () {
                return this.$store.getters['login/gotoUrl'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateGotoUrl', newVal);
                this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
            }
    },
  },
  watch: {
    otpSent: function(newVal) {
      if(newVal) {
        // this.$router.push({ path: 'send' })
      }
    },
    dialogMsg: function (newVal) {
    },
    otpValidationError: function(newVal) {
      if(newVal) {
        this.otpValidationInputClass = "vz-error"
        this.otpCode = ''
      }
    },
    resendVerificationToSelectedDevice: function(newVal) {
      if(newVal) {
        if (!this.vbgFlow && (this.flowType == 'forgot_password' || this.flowType==='forgot_username' || this.flowType == 'auth')) {
          this.updatecantrcvLink(false);
          this.$store.dispatch('forgotflow/updateVbgFlow', false)
          this.resendVerificationToSelectedDevice = false
        }
      }
    },
    otpAlerts: function(newVal){
      console.log("newVal for otpAlerts", newVal);
      if(newVal){
        if(newVal.length > 0){
          const hasError = newVal.some(res => res.type == "error");
          if(hasError){
            this.pwdTimer(0);
          }
        }
      }
    },
    currentStep: function(newVal) {
      if(newVal === 'OTP_VALIDATED' && this.flowType === 'forgot_password') {
        this.updateUserVerified(true)
        this.setFFTransactionId(this.transactionId)
        this.setFFUsername(this.username)
        this.$router.push({ name: 'AMForgotPwdComponent'})
      } else if(newVal==='OTP_VALIDATED' && this.flowType==='forgot_username') {
        this.updateUserVerified(true)
        this.setFFTransactionId(this.transactionId)
        this.getUsername(this.email)
        // redirect user back to login page
        this.redirectBackToLogin(this.urlOnMount, this.gotoUrl);
      }
    },
    fingerprintSaved: function(newVal) {
      if(newVal) {
        if(this.flowType === 'auth') {
          if(iamvar_polarisFlowToggle && this.sdarUserType) {
            this.showSdarPopup();
          } else {
            this.redirectToOpenam()
          }
        } else if(this.currentStep === 'OTP_VALIDATED' && this.continueUrl !== null) {
          // window.location.href = this.continueUrl
          
          // append needed query parameter if continuing to profile flow
          if(this.continueUrl.includes('/profile/postauth')) {
            // split the whole url after the question mark; if something is present after the question mark, parameters have been found
            // append query parameter as & if parameters are found, or append it with a '?' otherwise
            let qParameterCheck = this.continueUrl.split('?')

            if(qParameterCheck[1] !== '') { // check split after '?'
              // append new parameter using '&'
              window.location.href = this.continueUrl + '&stepupVerification=true'
            } else {
              // append new parameter using '?'
              window.location.href = this.continueUrl + '?stepupVerification=true'
            }
          }
          else 
            window.location.href = this.continueUrl

        }
      }
    },
  }
}
</script>

<style scoped>
.pwdPushRError {
  display: flex !important;
  height: auto;
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
}
.pwdPushRError.error {
    
    background-color: #FFECE0 !important;
    
}
.pwdPushRError.list {
    
    background-color: #FFECE0 !important;
    
}
.pwdPushRError.success {
    
    background-color: #DCF5E6 !important;
    
}
.pwdPushRError.warning {
    
    background-color: #FFF9DE !important;
    
}
.pwdPushRError.information {
    
    background-color: #E2F2FD !important;
    
}
.remember_label {
margin-top: 4px !important;
margin-bottom: 0px !important;
font-family: "VzNHGeDS-bold11",sans-serif;
}
.pushTextError {
    margin-top: 16px;
    margin-right: 8px;
    margin-bottom: 16px;
    margin-left: 8px;
    height: auto;
    font-size: 12px;
    line-height: 16px;
    width: 380px;
    font-family: 'VzNHGeTX-reg11';
    color: #000000 !important;
    flex: 1 !important;
}
.pwdLoginTextError {
    height: auto;
    font-size: 12px;
    line-height: 16px;
    width: 380px;
    margin-top: 8px;
}
.closeImage {
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 36px;
  float: right !important;
}
.warningImage {
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 36px;
  width: 24px;
  height: 24px;
}
.inlineError {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}
.pwdLessAlert {
  border-color: #C44904 !important;
  border: 1px solid;
  background-color: #FFECE0;
  border-radius: 5px;
  background-image: url(../../../common/assets/styles/corp/images/error_icon.svg);
  background-position: right;
  background-origin: content-box;
}
.pwdLessOtpValidateMessageContent {
  height: 16px;
  font-size: 12px;
  margin-top: 8px !important; 
  margin-bottom: 16px !important;
}
.pwdLessOtpValidateAlert {
  border-color: #C44904 !important;
  border: 1px solid;
  background-color: #ddaf84;
  border-radius: 10px;
}
.opt-container-1 {
  width: 464px;
  /* height: 800px; */
  border: solid;
  border-color: #D8DADA;
  border-width: 1px;
  /* margin-bottom: 198px; */
  /* margin-left: 290px; */
  border-radius: 4px;
}
.input-f {
  border: 1px solid #000;
  border-radius: 5px !important;
  height: 44px;
  width: 100%;
}
.opt-container-1-1 {
  min-height: 485px;
  margin-top: 48px;
  margin-bottom: 48px;
  margin-left: 40px;
  margin-right: 40px;
}
.opt-header-1 {
  /* width: 382px; */
  height: auto !important;
  font-family: 'VzNHGeDS-bold11';
  font-size: 32px;
  line-height: 36px !important;
  margin-bottom: 32px !important;
}
.opt-p-1 {
  /* width: 298px; */
  /* height: 20px; */
  font-family: 'VzNHGeDS-reg11';
  font-size: 16px;
  line-height: 20px !important;
  letter-spacing: 0.5px;
  color: #000;
}

.modal-content {
    position: relative;
    width: 560px;
    height: 275px !important;
    margin: 0 auto;
    border-radius: 5px;
}
.p-2 {
    /* width: 384px; */
    height: 120px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
    line-height: 20px;
}
.opt-username {
  max-width: 80%;
  height: 24px;
  font-family: 'VzNHGeDS-bold11';
  font-size: 20px;
  line-height: 24px !important;
  letter-spacing: normal;
  margin-top: 4px;
  word-break: break-all;
}
.opt-button-1 {
  width: 57px;
  height: 20px;
  font-family: 'VzNHGeDS-reg11';
  font-size: 16px;
  line-height: 20px !important;
  letter-spacing: 0.5px !important;
  margin-top: 4px;
  margin-left: 12px;
  /*text-decoration: underline;*/
  text-decoration: none !important;
  border-bottom: 1px solid #000;
}
.opt-button-1-1 {
    width: 57px;
    height: 20px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
    margin-bottom: 4px;
    margin-left: 12px;
    /*text-decoration: underline;*/
    text-decoration: none !important;
    border-bottom: 1px solid #000;
}
.opt-optionsTable {
    width: 384px;
    height: 321px auto;
    margin-right: 40px;
    margin-top: 48px;
}
.opt-title {
    width: 298px;
    /* height: 28px; */
font-family: 'VzNHGeTX-reg11' !important;
    font-size: 24px;
    margin-bottom: 8px;
    display: block;
}
.opt-sub-title {
    width: 298px;
    height: 36px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    display: block;
}
.opt-horizontal-1 {
    width: 384px;
    height: 1px;
    fill: solid;
    color: #D8DADA;
    margin-bottom: 23px;
    margin-right: 40px;
}
.otpfield {
    width: 384px;
    height: 64px;
    margin-top: 32px;
}
.otptitle {
    height: 16px;
    font-family: 'VzNHGeDS-reg11';
    font-size: 12px;
    margin-bottom: 4px;
}
.resend-field {
    width: auto;
    height: 20px;
    /*text-decoration: underline;*/
    font-family: 'VzNHGeDS-reg11';
    font-size: 16px;
    margin-top: 16px;
}
.resend-field-call {
  width: 95px;
  text-decoration: none !important;
  border-bottom: 1px solid #000;
}
.resend-field-otr {
  width: 123px;
  text-decoration: none !important;
  border-bottom: 1px solid #000;
}
.anotherCls {
  cursor: pointer;
  font-family: VzNHGeDS-bold11;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  color: #000;
}
.left_caret_spacing {
  padding-right: 4px !important;
}
input[type="checkbox"].tc {
  outline: none !important;
  margin-right: 12px !important;    
}
.check_box_border, .check_box_border:hover {
  outline: none !important;
  border: 1px solid #D8DADA !important;
}
.check_bg input:checked{
  accent-color: #000 !important;
}
.opt-container-2 {
    margin-left: 172px;
    margin-bottom: 20px;
}
.opt-default-promo1{
  background: #F6F6F6;
  height: 650px;
  width: 464px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.opt-default-promo{
  height: 128px;
  width: 253.78px;
  color: #A7A7A7;
  font-family: 'VzNHGeDS-bold11';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.footer-margin-top {
  margin-top: 64px;
}
@media only screen and (min-width: 280px) and (max-width: 1200px) {
  .opt-default-promo {
    display: none;
  }
  .opt-default-promo1 {
    display: none;
  }
}
/* responsive styles  */
.timer_num{
  font-weight: 700 !important;
}
.dynamic_para_div{
margin-top: 0px !important;
height: auto !important;
margin-bottom: 32px !important;
}
.user_id{
  margin-top: 32px !important;
  margin-bottom: 48px !important;
}
.otp_validate_container{
display: flex;
justify-content: center;
margin-top: 48px;
/* margin-left: 18px; */
}
@media only screen and (min-width: 280px) and (max-width: 1200px) {
.opt-container-1,
.opt-container-2 {
  margin-left: 0 !important;
}
}
.opt-container-1{
  height: auto !important;
  /* min-height: 650px !important; */
}

@media only screen and (min-width: 280px) and (max-width: 1200px) {
  .otp_validate_container {
    display: flex;
    justify-content: center;
    margin-top: 48px;
  }
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .otpfield{
    width: 340px !important;
  }
  .otp_validate_container {
    margin-top: 0px !important;
  }
  .opt-header-1{
font-size: 30px;
  }

  .opt-container-1 {
    margin-bottom: 0 !important;
    width: 420px !important;
    border: none !important;
  }
  

}

@media only screen and (min-width: 320px) and (max-width: 399px) {
  .passcode_div{
    font-size: 14px;
  }
  .otpfield{
    width: 234px !important;
  }
  .opt-container-1 {
    margin-bottom: 0 !important;
    width: 320px !important;
    border: none !important;
  }
  .otp_validate_container {
    margin-top: 0px !important;
  }
.opt-header-1{
font-size: 22px;
  }
 
  .account_text_para{
    font-size: 16px;
    margin-bottom: 0 !important;
  }

  
  .opt-container-1-1{
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 40px !important; 
    
  }

 
}
@media only screen and (min-width: 280px) and (max-width: 319px) {
  .opt-container-1 {
    margin-bottom: 0 !important;
    width: 280px !important;
    border: none !important;
  }
  
  .otp_validate_container {
    margin-top: 0px !important;
  }

  .opt-header-1{
font-size: 18px;
  }

  .label_text {
    font-size: 12px;
  }
}
.footer-margin-top {
margin-top: 132px;
}
.input_label_otp{
  font-family: 'VzNHGeTX-reg11' !important;
  letter-spacing: normal !important;
}
@media only screen and (max-width: 1023px) {
  .inline_err{
    font-family: 12px !important;
    line-height: 16px !important;
    letter-spacing: normal !important;
    font-family: "VzNHGeTX-reg11" !important;
    
  }
  .opt-deli-label{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-family: 'VzNHGeDS-reg11';
    color: #000000;
  }
  .primary_black{
    letter-spacing: 0.5px !important;
  }
  .otptitle { 
    font-family: 'VzNHGeTX-reg11' !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: normal !important;
    color: #000000 !important;
   
}
  .timer_num{
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
    color: #000000;
}
.timer_content{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
    color: #000000;
    font-family: 'VzNHGeDS-reg11' !important;

}
  .opt-msg{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #000000;
    font-family: 'VzNHGeDS-reg11';
  }
.footer-margin-top {
margin-top: 96px;
}
.otp_validate_container { 
  display: block;
  margin-top: 32px !important;
}
.opt-container-1-1 {
  margin-top: 32px;
  padding: 0 16px !important; 
  width: 100% !important;
  margin: 0 !important;
}
.opt-container-1 {
 width: 100% !important;
 border: none !important;
}
.opt-header-1 {
  margin-bottom: 24px !important;
  font-size: 24px;
  line-height: 28px !important;
  color: #000000;

}
.otptitle {
  color: #000000 !important
}
.opt-header-1-1{
  margin-top: 24px;
}
.opt-p-1{
  font-size: 12px !important;
  line-height: 16px !important;
  color: #000000;
  letter-spacing: normal;
font-family: 'VzNHGeTX-reg11' !important;
}
.passcode_div {
  margin-top: 12px !important;
}
.otpfield {
  width: 100% !important;
}
.method-para {
  margin-top: 49px !important;
}
.labeltiny{
  color: #000000;
  font-size: 12px;
}
.user_id {
  margin-top: 0 !important;
}
.opt-username{
  font-size: 16px;
  line-height: 20px !important;
  color: #000000;
}
.input_mr{
  margin-bottom: 8px !important;
  border-color: #6F7171 !important;
}
.input_mr:focus{
  border-color: #000000 !important;
}
.font_size {
  font-size: 20px;
}
.selected_otddevice_Content {
  font-size: 16px !important;
}
.resent_verify_link, .continue-btn {
  letter-spacing: 0.5px;
  margin-bottom: 0px !important;
}

.opt-button-1-1, opt-button-1-1_label {
  font-size: 16px !important;
  letter-spacing: 0.5px;
  font-family: 'VzNHGeTX-reg11' !important;
}
.opt-button-1-1 {
  /* width:67px !important; */
  font-family: 'VzNHGeDS-reg11' !important;
}
.passcode_div {
margin-top: 20px;
}
}
.sdar_popup{
  padding:30px 15px !important;
  height: 400px !important;
}
/*@media only screen and (min-width: 767px) and (max-width: 1023px){
  .sdar_popup > div >.sdar_main_title {
    margin-top: 24px !important;
  }
}*/
@media (max-width: 540px) {
  .sdar_popup{
    height: 100% !important;
    margin: auto !important;
    width: 100% !important;
    border-radius: 0px !important;
  }
}
</style>