<template>
  <div class="vbg-ff-root-container">
    <Header v-if="this.whichRealm"></Header>
    <div class="vbg-ff-content-container">
      <router-view></router-view>
    </div>
    <Footer v-if="this.whichRealm" class="footer-margin-top"></Footer>
  </div>
</template>
<script>
import LoaderComponent from '@/vbgauth/components/LoaderComponent'
import { mapActions,mapGetters } from 'vuex'
import commonMixin from '@/common/mixin/commonMixins'
import Header from '@/vbgauth/components/LegalHeader'
import Footer from '@/vbgauth/components/ForgotFlowFooter'

export default {
  name: 'FFStepupRootComponent',
  components: {
    LoaderComponent,
    Header,
    Footer,
  },
  mixins: [commonMixin],
  data () {
    return {
      whichRealm: true,
    }
  },
  mounted () {
    this.init()
    if((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount
      if(hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('iamvar_realm') !== -1) {
      let iamvar_realmCookie = getCookie('iamvar_realm')
      if(iamvar_realmCookie == 'MOBILE') {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      let iamvar_appCookie = getCookie('X-VZ-C_APP')
      if(iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }
    window.onpopstate = () => {
      //browser back button disabled
      window.history.forward();
    };
  },
  methods: {
    init() {
      // check if stepup root is called by login script
      // URLSearchParams not supported in IE
      // let params = new URLSearchParams(window.location.search)
      if(this.getParameterByName('loginCode')) {
        console.log('stepup triggered by external login')

        // update gotoUrl with query param
        if(this.getParameterByName('goto')) {
          this.$store.dispatch('login/updateGotoUrl', this.getParameterByName('goto'))
          setGotoUrlInClientLib(this.getParameterByName('goto'))
          // TODO: redirect to error page if gotoUrl is not present?
        }

        if(this.getParameterByName('appUrl')) {
          this.updateUrlOnMount(this.getParameterByName('appUrl'))
        }

        if(this.getParameterByName('otpAlwaysOn')) {
          this.updateOTPAlwaysOn(true)
        }
        // set up ott in state
        this.$store.dispatch('cvsstepup/updateVBGCCookieValue', this.getParameterByName('loginCode'))
        
        // assign ott to client library
        setOTTInClientLibForCreatingSession(this.getParameterByName('loginCode'))
        
        // update flow type to Auth
        this.$store.dispatch('cvsstepup/updateFlowType', 'auth')

        // call initialize
        this.$store.dispatch('cvsstepup/initializeStepup')
      } else {
        this.redirectToVerification()
      }
    },
    redirectToVerification() { 

      let validGoto = (this.gotoUrl != null && this.gotoUrl != "null" 
                       && this.gotoUrl != '' && this.gotoUrl != undefined)

      if (this.flowInitialized == 'OTP_INITIALIZED') {

        if(validGoto) {
          this.$router.push({ name: 'FFOtpComponent', query: { goto: this.gotoUrl } }).catch(err => {throw err})
        } else {
          this.$router.push({ name: 'FFOtpComponent' }).catch(err => {throw err})
        }

      } else if (this.flowInitialized == 'SQA_INITIALIZED') {

        // this.$router.push({ name: 'FFSqaComponent'}).catch(err => {throw err})
        this.$router.push({ name: 'FFOtpComponent' }).catch(err => {throw err})

      } else if (this.flowInitialized == 'ALLOW_INITIALIZED') {

        if(this.stepupReason === 'FORGOT_USERNAME') {
          
          if(validGoto) {
            this.$router.push({ name: 'FFOtpComponent', query: { goto: this.gotoUrl } }).catch(err => {throw err})
          } else {
            this.$router.push({ name: 'FFOtpComponent'}).catch(err => {throw err})
          }
        
        } else if(this.stepupReason === 'RESET_PASSWORD') {

          if(validGoto) {
            this.$router.push({ name: 'FFOtpComponent', query: { goto: this.gotoUrl } }).catch(err => {throw err})
          } else {
            this.$router.push({ name: 'FFOtpComponent' }).catch(err => {throw err})
          }

        // } else if(this.stepupReason === 'FORGOT_PASSWORD' && this.dnrStatus) {
        //   this.$router.push({ name: 'FFOtpComponent'}).catch(err => {throw err})
        } else if(this.stepupReason === 'FORGOT_PASSWORD') {
          if(validGoto) {
            this.$router.push({ name: 'FFOtpComponent', query: { goto: this.gotoUrl } }).catch(err => {throw err})
          } else {
            this.$router.push({ name: 'FFOtpComponent' }).catch(err => {throw err})
          }
        } else if(this.stepupReason === 'DEVICE_NOT_REGISTERED' || this.otpAlwaysOn) {
          
          if(validGoto) {
            this.$router.push({ name: 'FFOtpComponent', query: { goto: this.gotoUrl } }).catch(err => {throw err})
          } else {
            this.$router.push({ name: 'FFOtpComponent' }).catch(err => {throw err})
          }
          
        } else if(this.stepupReason === 'RISK' || this.otpAlwaysOn) {
          
          if(validGoto) {
            this.$router.push({ name: 'FFOtpComponent', query: { goto: this.gotoUrl } }).catch(err => {throw err})
          } else {
            this.$router.push({ name: 'FFOtpComponent' }).catch(err => {throw err})
          }
          
        } else if (this.stepupReason === 'SQA_NOT_REGISTERED') {
          this.$router.push({ name: 'FFOtpComponent'}).catch(err => {throw err})
        } else {
          // remove this block
          this.$store.dispatch('login/redirectToOpenam')
        }

      }
    },
    ...mapActions('cvsstepup', [
        // 'updateUrlOnMount',
        'updateOTPAlwaysOn'
      ]
    ),
    ...mapActions('login', [
      'updateUrlOnMount' // updates urlOnMount on both cvsstepup and login modules
    ])
  },
  computed: {
    ...mapGetters('cvsstepup', [
      'flowInitialized',
      'stepupReason',
      'urlOnMount',
      'otpAlwaysOn',
      'dnrStatus'
    ]),
    ...mapGetters('login', [ 'gotoUrl' ])
  },
  watch: {
    flowInitialized: function(newVal) {
      if(newVal) {
        console.log('Flow is initialized')

        this.redirectToVerification()
      }
    }
  }
}
</script>
<style scoped>
.footer-margin-top {
  margin-top: 64px;
}
</style>
