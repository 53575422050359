<template>
  <!-- <BlockUI v-if="submitLoader" :url="url"></BlockUI> -->
  <div v-if="watchSubmitLoader" style="display:flex;  justify-content: center; margin-top: 250px;">
    <CustomLoaderComponent></CustomLoaderComponent>
  </div>
  <div
    v-else
    style="overflow: hidden; font-family: 'VzNHGeDS-reg11'"
  >
    <div id="vz-gh20"></div>
    <div>
      <PwdLessLogin></PwdLessLogin>
    </div>
    <div id="vz-gf20"></div>
  </div>
</template>
    
<script>
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import { setPageDetails } from "../../../../public/adobeTagging";
import ClearWhite from "@/common/assets/styles/corp/images/clear_white.png";
import PwdLessLogin from "./PwdLessLogin";
import CustomLoaderComponent from "@/vbgauth/components/CustomLoaderComponent.vue";
import commonMixin from "@/common/mixin/commonMixins";
export default {
  name: "PwdLessLoginRootComponent",
  components: { PwdLessLogin, CustomLoaderComponent },
  mixins: [commonMixin],
  data() {
    return {
      meth: "pass",
      showClickHereMsg: false,
      idmRedirect: "",
      pwdLoginEnable: false,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      watchSubmitLoader: false
    };
  },
  computed: {
    ...mapGetters("login", [
      "getUnifiedLoginAlerts",
      "getUnifiedErrorCode",
      "getVcgSubmitLoader",
    ]),
  },
  watch: {
    getVcgSubmitLoader: function(newVal) {
      if(newVal) {
        this.watchSubmitLoader = true
      } else {
        this.watchSubmitLoader = false
        var globalNav = document.createElement("script");
        globalNav.setAttribute(
          "src",
          "https://www.verizon.com/business/ngnav/smb.js"
        );
        globalNav.setAttribute("async", "async");
        document.head.appendChild(globalNav);
      }
    }
  },
  async created() {
    setPageDetails("UnifiedLoginMain:landing");
  },
  mounted() {
    var globalNav = document.createElement("script");
    globalNav.setAttribute(
      "src",
      "https://www.verizon.com/business/ngnav/smb.js"
    );
    globalNav.setAttribute("async", "async");
    document.head.appendChild(globalNav);
    // f.setAttribute('id',"login-form");
    // f.setAttribute('name',"loginForm");

    var rky = window.location.search.match(/Reg_Message=([^\&]+)/) || [0, 0];
    if (rky && rky[1]) {
      // document.getElementById("regMsgP").innerHTML = regMsg[rky[1]] || "";
      // document.getElementById("regMsgS").checked = true;
      this.updateSetUnifiedLoginAlerts(rky[1].toLowerCase());
    }
    let gotoParam = this.getParameterByName("goto");
    let encrpytedTok = this.getParameterByName("t1");
    console.log("gotoParam..",gotoParam,"encrpytedTok..",encrpytedTok)
    if(gotoParam) {
      setGotoUrlInClientLib(gotoParam);
    }
    if (encrpytedTok) {
      console.log("coming inside..,",encrpytedTok)
      //for vbms account link send gotourl in vbgIamclient lib
      this.submitVbmsAccountLinkForm(encrpytedTok);
    }
    //for guest user or regration flow from vcg
    let encrpytedRegTok = this.getParameterByName("rt1");
    if (encrpytedRegTok) {
      console.log("coming inside..,",encrpytedRegTok)
      //for vbms account link send gotourl in vbgIamclient lib
      let payload = {
        encrpytedRegTok: encrpytedRegTok
      }
      this.submitPwdAMPasswordForm(payload)
    }
  },
  methods: {
    ...mapActions("login", [
      "setCookieForBlueBanner",
      "UpdatePopupValue",
      "updateSetUnifiedLoginAlerts",
      "updateCVSSubmitLoader",
      "submitVbmsAccountLinkForm",
      "submitPwdAMPasswordForm"
    ]),
    getCloseIcon() {
      // return  (
      //   iamvar_cdnUrl +
      //   iamvar_imgSourceContext +
      //   "/business/login/includes/img/close.7e170800.svg"
      // )
      //return this.getUnifiedLoginAlerts[0].type == "success"
      // ? CloseBlack : ClearWhite;
      return ClearWhite;
    },
    bannerContainer() {
      return this.getUnifiedLoginAlerts[0].type == "success" ||
        this.getUnifiedLoginAlerts[0].msg.includes("Too many failed attempts")
        ? "bannerMargin"
        : "";
    },
    deleteAlert(alert) {
      this.getUnifiedLoginAlerts.splice(
        this.getUnifiedLoginAlerts.indexOf(alert),
        1
      );
    },
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png'
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    messageImage(index) {
      return index.type + "MessageImage";
    },
    getTypeOfAlert(index) {
      return index.type;
    },
    gethpop(value) {
      this.UpdatePopupValue(value);
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
      });
    },
  },
  async created() {
    const searchParams = new URLSearchParams(window.location.search);
    gnavdl = {
      bu: "smb",
      appid: "unified",
    };
    setPageDetails("unifiedLogin:landing");
    this.setCookieForBlueBanner();
  },
};
</script>
<style>
</style>
