<template>
  <div class="text-clr">
    <BlockUI v-if="submitLoader" :url="loaderGIF"></BlockUI>
    <div class="martop10">
				<p>{{ $t("message.required_info_pd") }}</p>
		</div>
      <!-- <h3>
        <button type="button" v-tooltip="tooltipText"></button>
      </h3> -->
      <!-- <p class="martop20">
        You will also need to answer your secret question and enter a one-time
        passcode (OTP)
      </p> -->
    <div class="vbg-ff-form-container">
      <form @submit.prevent="onCaptchaVerified('')">
        <div v-if="formInputs">
          <div>
            <label class="vbg-ff-input-label" for="userid">User ID</label>
            <div>
              <input
                id="userid"
                name="userid"
                @keydown.space.prevent
                ref="userIDRef"
                v-model="userEnteredUsername"
                type="text"
                class="vbg-ff-input-field"
                :autocomplete="formInputs[0].autocomplete"
                v-bind:class="userValidationInputClass"
              />
            </div>
          </div>
        </div>
        <div v-if="userValidationError">
          <div class="padleft0 martop10">
            <p class="pmicro">{{ $t("message.forgot_pd_no_user_found") }}</p>
          </div>
        </div>
        <!-- <div v-if="userValidationContactAdminError">
          <div class="padleft0 martop10">
            <p class="pmicro">{{ $t("message.forgot_pd_contact_admin") }}</p>
          </div>
        </div> -->
        <!--<div v-if="cannotProceedRecovery">
         <div class="padleft0 martop10 col-xs-12">
           <p class="pmicro">{{ $t("message.forgot_pd_cannot_proceed") }}</p>
         </div>
		    </div>--->
        <div v-if="formInputs">
          <div v-if="this.jCaptchaFlag">
            <div>
              <div class="captcha-blk">
                <div class="captcha-txt-blk">
                  <img id="captchaImg" src="" :class="getOAuthClass('oauth-flow-jcaptch')"/><br/>
                </div>
              </div>
              <button type="button" class="refreshCaptcha" 
                v-on:click="refreshCaptchaImgUrl()" name="refreshCaptcha" id="refreshCaptcha">Refresh Image
              </button>
            </div>
            <div>
              <label class="vbg-ff-input-label" for="captchaInput">Type the characters from the above image</label>
              <input
                type="text"
                id="captchaInput"
                name="captchaInput"
                @keydown.space.prevent
                class="form-control vbg-ff-input-field"
                v-model="captchaText"
              />
            </div>
          </div>
        </div>
        <div v-if="formInputs">
          <invisible-recaptcha
            v-if="this.captchaOn"
            :sitekey="forgotPwdCaptchaKey"
            theme="light"
            badge="bottomright"
            :callback="onCaptchaVerified"
            :class="getBtnClass()"
            :type="formInputs[1].type"
            >{{ formInputs[1].value }}
          </invisible-recaptcha>
          <div class="vbg-mb-btn-container">
            <button
              v-if="!this.captchaOn" 
              :disabled="continueDisabled"
              @click="onCaptchaVerified('')"
              @keyup.enter="onCaptchaVerified('')"
              class="btn vbg-ff-btn-continue vbg-ff-btn-width140"
              :type="formInputs[1].type"
              :class="continueDisabled ? 'vbg-ff-disabled' : ''"
              id="userid-continue-btn"
              name="userid-continue-btn"
            >
              {{ formInputs[1].value }}
            </button>
            <button
              class="btn vbg-ff-btn-cancel vbg-ff-btn-width140"
              @click="cancelRecovery()"
              style="margin-left:0!important;"
              type="button"
              :style="formInputs[2].style"
              id="userid-cancel-btn"
              name="userid-cancel-btn"
            >
              {{ formInputs[2].value }}
            </button>
          </div> 
          <div v-if="mybizzFlag" style="margin-top:30px">
            <div>
              <h3>Need to contact admin?</h3>
              <p style="font-size:16px; line-height:22px; cursor: pointer;">Contact your admin for additional support.</p>
              <h3>
                <a 
                  :href="contact_admin"
                  style="font-size:16px; letter-spacing:0.5px;cursor: pointer;text-decoration: none;">
                    Contact admin 
                    <img style="width: 10px; height: 14px" :src="caret" />
                </a>
              </h3>
            </div>
          </div>
          <div style="margin-bottom: 80px"></div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import InvisibleRecaptcha from "vue-invisible-recaptcha";
import forgotFlowConfig from "@/common/assets/json/forgot_flow_config.json";
import "@/common/assets/styles/tooltip.css";
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import translations from "@/common/assets/json/messages1.json";
import commonMixin from "@/common/mixin/commonMixins";
import {
  setPageDetails,
  eventTracking,
  errorSet,
} from "../../../../public/adobeTagging";
export default {
  name: "UserIdComponent",
  components: {
    "invisible-recaptcha": InvisibleRecaptcha,
  },
  mixins: [commonMixin],
  data() {
    return {
      valid: false,
      contact_admin : iamvar_contact_admin,
      caret: caret_blk,
      fieldRules: [(v) => !!v || "Please fill out this field"],
      otpEnabled: true,
      userEnteredUsername: null,
      userValidationInputClass: "",
      captchaText: null,
      tooltipText: translations.en.message["enter_usrn_tooltip_msg"],
      captchaOn: true,
      jCaptchaFlag: true,
      submittedAlready: false,
      whichRealm: true,
      loaderGIF: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
    };
  },
  mounted() {
    this.changeContextBiocatch('forgot_password_user_id_page')
    window.scrollTo(0,0);
    // get captcha image
    this.$nextTick(() => this.refreshCaptchaImgUrl())
    if (window.location.href.includes("business")) {
      this.readConfig("vbg_config");
    } else if (window.location.href.includes("consumer")) {
      this.readConfig("vcg_config");
    }
    if((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount
      if(hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if(iamvar_appCookie == 'MVB') {
          this.whichRealm = false;
        }
    }
    if(iamvar_realm == 'MOBILE') {
      let returnUrl = this.urlOnMount;
      this.updateUrlOnMount(returnUrl);
    }

    this.readFormInputs("userid_input_component");

    this.captchaOn = iamvar_enableCaptcha;

    this.jCaptchaFlag = iamvar_enableJCaptcha;
    //focusing first input field when user lands on this page
    this.$nextTick(() => this.$refs.userIDRef.focus())

    this.updateFFSQAEnabled(false)
    this.updateSQAValidated(false)
    this.updateVZCarrier(false)
    this.updateUserQuestions([])
    this.updateSelectedQuestion()
    this.setOldSelectedDevice(null)
    if(this.username) {
      this.userEnteredUsername = this.username
    }

  },
  methods: {
    ...mapActions("forgotflow", [
      "getUsernameCallback",
      "readConfig",
      "readFormInputs",
    ]),
    ...mapActions("cvsstepup", [
      "updateUsernameForStepup",
      "updateFlowType",
      "initializeStepup",
      "igCaptchaEndpoint",
      "updateRecaptchaResponse",
      "updateCaptchaText",
      "updateClearInputFields",
      "updateFFSQAEnabled",
      "updateSQAValidated",
      "updateVZCarrier",
      "updateSelectedQuestion",
      "updateUserQuestions",
      "setOldSelectedDevice"
    ]),
    ...mapActions("login", [
      "updateUrlOnMount"
    ]),
    cancelRecovery() {
      this.$store.dispatch("cvsstepup/resetState");
      this.$store.dispatch("forgotflow/resetState");
      var returnUrl = this.getHostName(window.location.href);
      
      if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if(iamvar_appCookie == 'MVB') {
          window.location.href = iamvar_mvbLaunchUrl;
        }
      } else if (!this.urlOnMount) {
        window.location.href = this.sanitizeUrl("https://" + returnUrl + "/account/business/login/unifiedlogin");
      } else {
        window.location.href = this.urlOnMount;
      }

      // checking if user agent is vema then redirect user back to mobile login page
      if(this.userAgent && this.userAgent.toLowerCase() === 'vema') {
        window.location.href = iamvar_vemaLaunchUrl;
      }
    },
    onCaptchaVerified: function (recaptchaToken) {
      // submit only if required fields are filled
      if(!this.continueDisabled) {
        eventTracking("Password Reset Request Initiated", "");
        this.updateUsernameForStepup(this.userEnteredUsername.trim());
        this.updateCaptchaText(this.captchaText.trim());
        this.updateFlowType("forgot_password");
        // push to stepupRootComponent only if the username is valid (returned by the backend)

        this.updateRecaptchaResponse(recaptchaToken);
        this.igCaptchaEndpoint({ challengeMethod: "SQA" })
      }        
    },
    getBtnClass() {
      var btnClass = this.formInputs[1].class;
      if ((!this.userEnteredUsername) || (!this.captchaText && this.jCaptchaFlag)) {
        btnClass += " inactive";
      }
      return btnClass;
    },
    // getCaptchaImgUrl() {
    //   var captchaImgHref = this.getHostName(window.location.href);
    //   let srcURL = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha");
    //   return srcURL;
    // },
    refreshCaptchaImgUrl() {
      const captchaImgHref = this.getHostName(window.location.href);
      document.getElementById("captchaImg").src = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha?cb=" + Math.random());
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
      }
      else {
          return null;
      }
    },
    getOAuthClass(className = 'oauth-flow', subClass = '') {
      return className + ' ' + subClass
    },
  },
  computed: {
    ...mapGetters("forgotflow", [
      "forgotPwdCaptchaKey",
      "forgotFlConfig",
      "formInputs",
    ]),
    ...mapGetters("cvsstepup", [
      "userValidationError",
      "userValidationContactAdminError",
      "currentStep",
      "cannotProceedRecovery",
      "flowType",
      "urlOnMount",
      "fpAlerts",
      "submitLoader",
      "clearInputFields",
      "username"
    ]),
    ...mapGetters("login",["mybizzFlag", "userAgent"]),
    formData: {
      get: function () {
        return this.$store.getters["forgotflow/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("forgotflow/updateFormData", newVal);
      },
    },
    userVerified: {
      get: function () {
        return this.$store.getters["forgotflow/userVerified"];
      },
      set: function (newVal) {
        this.$store.dispatch("forgotflow/updateUserVerified", newVal);
      },
    },
    continueDisabled() {
      return (!this.userEnteredUsername ||
        (!this.captchaText && this.jCaptchaFlag) ||
        this.submitLoader)
    }
  },

  watch: {
    clearInputFields : function (newVal) {
      console.log("watch value", newVal)
      if(newVal) {
        this.userEnteredUsername = ''
        this.captchaText = null
        this.updateClearInputFields(!newVal)
      }
    },
    userValidationError: function (newVal) {
      if (newVal) {
        this.userValidationInputClass = "vz-error";
        this.refreshCaptchaImgUrl();
        this.captchaText = null;
        this.submittedAlready = false;
      } else {
        this.userValidationInputClass = '';
      }
    },
    userValidationContactAdminError: function (newVal) {
      if (newVal) {
        this.userValidationInputClass = "vz-error";
        this.refreshCaptchaImgUrl();
        this.captchaText = null;
        this.submittedAlready = false;
      } else {
        this.userValidationInputClass = '';
      }
    },
    currentStep: function (newVal) {
      if (newVal === "FLOW_INITIALIZED" && !this.cannotProceedRecovery) {
        this.$router.push({ name: "FFStepupRootComponent" });
      }
    },
    fpAlerts: function (newVal) {
      if(newVal) {
        this.refreshCaptchaImgUrl();
        this.captchaText = null;
        this.submittedAlready = false;
        window.scrollTo(0,0);
      }
    },
  },
};
</script>
<style scoped>
.v-text-field__details {
  padding-top: 4px;
}
.vz-input-field:focus {
  border: 1px solid black !important;
}
.contact-ad{
  position: absolute;
  margin-top: 405px;
  padding-bottom: 100px;
}
.a11y-tooltip-div {
  display: inline-block;
  position: relative;
}
.a11y-tooltip > a:before {
  color: #000;
  font-family: myvzw;
  content: "\24D8";
  font-weight: 800;
  font-size: 18px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.a11y-tooltip > a:after {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 6px;
  left: -6px;
  top: -6px;
  background: transparent;
  text-decoration: none !important;
  overflow: hidden;
}
input {
  padding: 11px 0 11px 10px;
  border-radius: 0px;
  font-size: 14px;
  color: #000;
  border: 1px solid #d8dada;
  border-bottom: 1px solid #000;
  box-shadow: none;
  transition: border 0.4s;
  height: 36px;
}
.v-application .primary {
  border: 1px solid #000 !important;
}
.vz-error {
  border: #ed7000 1px solid;
  border-bottom: #ed7000 4px solid;
}
.refreshCaptcha{
  border: none;
  background: white;
  text-decoration: none !important;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
  border-bottom: 1px solid #000 !important;
  font-family: 'VzNHGeTX-reg11' !important;
  margin-bottom: 32px;
}
.btn.secondary_white {
  margin-right: 1.5rem
}
.text-clr {
  color: #000000 !important;
}
.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
}
.vbg-ff-btn-width140 {
  height: 44px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
}
.captcha-blk{
  margin-top: 60px;
  margin-bottom: 10px;
}
.vbg-mb-btn-container {
  margin-top: 60px;
}
@media(max-width: 336px){
  .contact-ad{
    margin-top: 435px;
  }
}

@media (min-width: 280px) and (max-width: 1024px) {
  .oauth-flow-mt12 {
    margin-top: 12px !important;
  }
  .oauth-flow {
    padding-right: 0 !important;
    margin-top: 12px !important;
  }
  .oauth-flow form.padleft0.col-xs-12 {
    padding-right: 0 !important;
  }
  .oauth-flow-pr0 {
    padding-right: 0 !important;
  }
  .oauth-flow-w-100 {
    width: 100% !important;
  }
  .oauth-flow-jcaptch {
    /*width: 100%;*/
    margin-bottom: 0px;
    height: 48px;
  }
  .captcha-blk{
    height: 50px;
    margin-bottom: 12px;
    background: linear-gradient(270deg, #00FFF0 0%, #FFB404 100%);
    margin-top: 48px;
  }
  .captcha-txt-blk{
    position: absolute;
    margin-top: 24px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  .oauth-flow-continue-btn {
    width: 100%;
    margin-bottom: 12px;
  }
  .oauth-flow-cancel-btn {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 12px
  }
  .col-sm-6 {
    max-width: 100%;
  }
  .vbg-mb-btn-container {
    margin-top: 48px;
  }
}
@media  (min-width: 768px) and (max-width: 1024px) {
  .oauth-flow-continue-btn {
    width: 47% !important;
    margin-bottom: 0 !important;
    float: right;
  }
  .oauth-flow-cancel-btn {
    width: 47% !important;
    margin-left: 0 !important;
  }
}
@media (max-width: 768px) {
  /* .vbg-mb-btn-container {
    display: flex;
    flex-direction: column-reverse;
  } */
  .vbg-ff-btn-cancel {
    margin-top: 12px;
    margin-bottom: 30px;
  }
}
</style>
