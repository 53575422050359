<template>
  <div style="overflow: hidden">
    <div class=""></div>
    <div class="createpwd_layout">
      <BlockUI v-if="submitLoader" :url="url"></BlockUI>
      <form @submit.prevent="onSubmit()" class="createpwd_container" ref="createPForm">
        <h1 class="createpwd_container_heading">Welcome to My Verizon</h1>
        <div class="input_section">
          <label class="Createpwd_input_label">Create Password</label>
          <input
            :type="showPd ? 'text' : 'password'"
            v-model="pd"
            id="pd"
            @input="checkPassword"
            @blur="checkPassword"
            class="createpwd_input"
            tabindex="0"
            :class="
              pd
                ? pdInvalidClass() == 'orangeLoader'
                  ? 'green_input'
                  : 'orange_input sequence_err_mr'
                : ''
            "
          />
          <span
            class="show_hide"
            v-if="pd"
            type="button"
            @click="toggleShowPd"
            tabindex="0"
            @keydown.enter.prevent="toggleShowPd"
            @input="checkPdStrength"
            >{{ showPd ? "Hide" : "Show" }}</span
          >
            <span v-if="checkSequenceValidationError" class="sequence_err_msg">Avoid sequences like abcd, 1234, AAAA, 1111. </span>
          </div>
        <div class="validations_container" v-if="pd">
          <div class="strength_bar_container_label">
            <span>Strength</span>
            <span>{{
              pd
                ? pdInvalidClass() == "orangeLoader"
                  ? "Valid"
                  : "Not valid"
                : ""
            }}</span>
          </div>
          <div class="bar_bg">
            <div
              :class="progressBarLevel"
            ></div>
          </div>
          <div class="validation_para">
            please enter a password that's not one of your last 5 passwords.
            Your new password must be:
          </div>
          <!-- <div class="validation_criteria">
                        <div
                            :class="password && lentghMatch ? 'valid_conditions' : 'invalid_conditions'">
                            8-20 characters long</div>
                        <div :class="password && (atleastOneCharacter) ? 'invalid_conditions' : 'valid_conditions'">
                            At least 1 letter</div>
                        <div
                            :class="password && (atleastOneNumber) ? 'invalid_conditions' : 'valid_conditions'">
                            At least 1 number</div>
                        <div
                            :class="password && (password.length < 8 || password.length > 20) ? 'valid_conditions' : 'invalid_conditions'">
                            Hard to guess</div>

                    </div> -->
          <div
            v-for="(req, i) in this.pwdReq"
            :key="i"
            style="
              display: flex;
              height: 20px;
              margin-left: 20px;
              margin-bottom: 16px;
            "
          >
            <div
              v-if="req.required"
              :class="[
                pwdReqMatch[i].matched == 'matched'
                  ? 'vbg-tick-mark'
                  : 'unmatched-dot',
                req.type == 'cannot_contain_special_characters'
                  ? 'validationStyle'
                  : '',
              ]"
            >
              <img
                v-if="pwdReqMatch[i].matched == 'matched'"
                :src="checkIcon"
              />
            </div>
            <div
              v-if="req.required"
              class="pwd-progress-bar-text"
              :class="[
                pwdReqMatch[i].matched == 'matched' ? 'greycontentcolor' : '',
              ]"
            >
              {{ req.display_msg }}
            </div>
          </div>
        </div>
        <div class="input_section">
          <label
            class="Createpwd_input_label"
            :class="this.validPd ? '' : 'greycontentcolor'"
            >Confirm Password</label
          >
          <div :class="pdNotMatched ? 'error_spacing' : ''">
            <input
              :type="showConfirmPd ? 'text' : 'password'"
              v-model="confirmPd"
              id="confirmPd"
              @input="checkPassword"
              @blur="checkPassword"
              @copy.prevent
              @paste.prevent
              class="createpwd_input"
              :class="
                (pd && confirmPd)
                  ? pdInvalidClass(true) == 'orangeLoader'
                    ? 'green_input'
                    : 'orange_input'
                  : ''
              "
              :disabled="!validPd"
            />
          </div>
          <span
            class="show_hide"
            v-if="confirmPd"
            type="button"
            @click="toggleshowConfirmPd"
            tabindex="0"
            @keydown.enter.prevent="toggleshowConfirmPd"
            >{{ showConfirmPd ? "Hide" : "Show" }}</span
          >
          <span v-if="pdError">{{ pdError }}</span>
        </div>
        <div class="input_section">
        <label class="vbg-ff-input-label" for="userid" :class="(this.validPd && (pd === confirmPd) ) ? '' : 'greycontentcolor'">Secret question</label>
        <div
          ref="dropdownRef"
          class="row dropdown dropdown-toggle "
          id="drpdn"
          v-bind:class="{ open: showDropdown }"
          aria-haspopup="true"
          aria-expanded="false"
          @click="toggleDropdown()"
          @keyup.enter="toggleDropdown()"
          tabindex="2"
          >
          <div class="col-xs-11" style="padding: 0px">
            <a id="dropdownMenu1" aria-haspopup="true" role="button">
              <span>
                <span>{{ challengeQuestionText }}</span>
              </span>
            </a>
          </div>
          <a
            class="col-xs-1 pull-right dropdown-arrow"
            style="padding: 0px"
          ></a>
          <ul
            class="dropdown-menu col-xs-12 px-0"
            aria-labelledby="dropdownMenu1"
          >
            <li
              v-for="(question, index) in getVcgSecretQuestions"
              :key="question.id"
              role="presentation"
              :tabindex="index + 3"
              v-bind:class="{
                chosen: question.question === challengeQuestionText,
              }"
              @keyup.enter="questionSelected(question, 'enter')"
              @keyup="listkeyupHandler($event, index)"
              @click="questionSelected(question, 'click')"
              ref="userSelectQuestion"
            >
              <a role="menuitem" @click="selectQuestion(question)">
                <span>
                  <span class="sqaText">{{ question.question }}</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
        <!-- <div class="input_section">
          <label
            class="Createpwd_input_label"
            :class="confirmPassword && !pdNotMatched ? '' : 'greycontentcolor'"
            >Secret Question</label
          >
          <select
            v-model="secretQuestion"
            id="secretQuestion"
            @change="checksecretQuestion"
            class="createpwd_input"
            tabindex="0"
            :disabled="pdNotMatched"
          >
            <option value="" disabled>Please select one</option>
            <option v-for="question in questions" :value="question">
              {{ question }}
            </option>
          </select>
        </div> -->
        <div class="input_section">
          <label
            class="Createpwd_input_label"
            :class="!challengeQuestion ? 'greycontentcolor' : ''"
            >Secret Question Answer</label
          >
          <div class="">
            <input
              :type="showSqa ? 'text' : 'password'"
              v-model="confirmSqa"
              id="confirmSqa"
              @input="checkconfirmSqa"
              class="createpwd_input"
              :class="
                confirmSqa
                  ? confirmSqa.length < 3 || confirmSqa.length > 40
                    ? 'error_spacing orange_input'
                    : 'green_input'
                  : ''
              "
              :disabled="!challengeQuestion"
            />
          </div>
          <span
            class="show_hide"
            v-if="confirmSqa"
            type="button"
            @click="toggleshowSqa"
            tabindex="0"
            @keydown.enter.prevent="toggleshowSqa"
            >{{ showSqa ? "Hide" : "Show" }}</span
          >
          <span
            v-if="
              confirmSqa && (confirmSqa.length < 3 || confirmSqa.length > 40)
            "
            >Secret answer must be between 3 and 40 characters</span
          >
        </div>
        <!-- <div class="input_section">
          <label class="Createpwd_input_label"
            >Mobile Telephone Number(Optional)</label
          >
          <div>
            <input
              :type="number"
              v-model="mobileNumber"
              id="mobileNumber"
              @input="checkmobileNumber"
              class="createpwd_input"
            />
          </div>
          <span
            class="show_hide"
            v-if="confirmSqa"
            type="button"
            @click="toggleshowSqa"
            tabindex="0"
            @keydown.enter.prevent="toggleshowSqa"
            >{{ showSqa ? "Hide" : "Show" }}</span
          >
          <span
            v-if="
              confirmSqa && (confirmSqa.length < 3 || confirmSqa.length > 40)
            "
            >Enter valid number</span
          >
        </div> -->

        <div class="input_section btn_container">
          <input
            type="submit"
            :class="(confirmSqa.length < 3 || confirmSqa.length > 40) ? 'submit_btn disbaled_btn':'submit_btn enabled_btn'"            tabindex="0"
            :disabled="confirmSqa.length < 3 || confirmSqa.length > 40"
            value="Continue"
          />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/common/mixin/commonMixins";
import Header from "@/vbgauth/components/LegalHeader";
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import ForgotFlowFooter from "../ForgotFlowFooter.vue";
import { mapGetters, mapActions } from "vuex";
import warningIcon from "@/common/assets/styles/corp/images/warning.png";
import checkIcon from "@/common/assets/styles/corp/images/checkmark.png";

export default {
  name: "Createpwd",
  mounted() {
    // window.that = this;
    // window.scrollTo(0,0);
    this.getPassswordBlackList("create");
    this.readConfig("vbg_config");
    this.updateVcgSecretQuestions();
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      var iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }
    if (this.uuID === "") {
      this.showContent = false;
    }
    this.$refs.dropdownRef.focus();
    document.addEventListener("click", (e) => {
      if (e.target.parentElement.id != "drpdn" && this.showDropdown === true) {
        if (iamvar_appCookie == "MVB") {
          this.showDropdown = true;
        } else {
          this.showDropdown = false;
        }
      }
    });
    // this.changeContextBiocatch('forgot_password_reset_password_page')
  },
  data() {
    return {
      pd: "",
      confirmPd: "",
      secretQuestion: "",
      validSqa: "",
      questions: ["option 1", "option 2", "option 3"],
      confirmSqa: "",
      showPd: false,
      showConfirmPd: false,
      showSqa: false,
      pdError: "",
      lentghMatch: false,
      checkIcon: checkIcon,
      validPd: false,
      valid_range: "",
      pdNotMatched: "",
      mobileNumber: "",
      showDropdown: false,
      challengeQuestion: "",
      challengeQuestionText: "Select",
      whichRealm: true,
      strength:0,
      passwordBlkListValidation: false,
      checkSequenceValidationError: false,
      url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
      invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\": <>\?]/,
      invalidCharacters: false,
    };
  },
  computed: {
    ...mapGetters("forgotflow", ["pwdReq", "noOfPwdReq", "success"]),

    ...mapGetters("profile", ["getVcgSecretQuestions", "uuID", "submitLoader"]),
    // ...mapGetters('login',['submitLoader']),

    // areFirstTwoFieldvalid(){
    //     return (this.validPwd && !this.pdNotMatched);
    // },

    pwdReqMatch: {
      get: function () {
        return this.$store.getters["forgotflow/pwdReqMatch"];
      },
      set: function (newVal) {
        this.$store.dispatch("forgotflow/updatePwdReqMatch", newVal);
      },
    },
    atleastOneCharacter(pd) {
      return /[A-Za-z]/.test(this.pd);
    },
    atleastOneNumber(pd) {
      return /[0-9]/.test(this.pd);
    },
    // isQuestionSelected() {
    //   return this.secretQuestion !== "";
    // },
    progressBarLevel(){
      return(
        (this.pd && this.pd.length <8) ? 
          'strength_progressive_bar strength_progressive_bar25 strength_progressive_bar_weak':
        ((this.pd && this.pd.length >=8) && (this.pd && this.pd.length <9) ) ?
          'strength_progressive_bar strength_progressive_bar50  strength_progressive_bar_weak':
        (this.pd && this.pd.length >=9)?
          'strength_progressive_bar strength_progressive_bar100 strength_progressive_bar_strong':''
      ) 
    }
  },

  
  methods: {
    ...mapActions("forgotflow", [
      "processInputs",
      "resetUserPassword",
      //added password blocklist functions
      "getPassswordBlackList",
      "containsBlackListPassword",
      "readConfig",
    ]),
    ...mapActions("profile", ["updateVcgSecretQuestions", "updateVcgSecurityProfile"]),
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleShowPd() {
      this.showPd = !this.showPd;
      if (this.showPd) {
        setTimeout(() => {
          this.showPd = false;
        }, 10000);
      }
    },
    toggleshowConfirmPd() {
      this.showConfirmPd = !this.showConfirmPd;
      if (this.showConfirmPd) {
        setTimeout(() => {
          this.showConfirmPd = false;
        }, 10000);
      }
    },
    toggleshowSqa() {
      this.showSqa = !this.showSqa;
      if (this.showSqa) {
        setTimeout(() => {
          this.showSqa = false;
        }, 10000);
      }
    },
    pdInvalidClass(confirm) {
      if (confirm) {
        return this.pdError ? "greenLoader" : "orangeLoader";
      } else if (this.validPd) return "orangeLoader";
      return "greenLoader";
    },
    // calculatedProgressBarWidth(){
    //   let pd = this.pd
    //   const isLengthValid = pd.length >=8 && pd.length <=40;
    //   const hasLetter = /[a-zA-Z]/.test(pd);
    //   const hasNumber = /[0-9]/.test(pd);
    //   const checkBlacklistedPd = this.passwordBlkList;
    //   const checks = [isLengthValid, hasLetter, hasNumber, checkBlacklistedPd]
    //   const trueCount = checks.filter(true).length;
    //         return trueCount*25;
    // },
    passwordBlkListValue: function (params) {
      this.passwordBlkList = params;
    },
    checkPassword: function () {
      this.pdNotMatched =
        this.pd &&
        this.confirmPd &&
        this.pd !== this.confirmPd;
      if (this.pdNotMatched) {
        this.pdError = "Passwords do not matched";
      } else {
        this.pdError = "";
      }
      this.passwordBlkListValidation = false;
      if(this.enhanceFpFlow) {
        for(let i = 0; i<this.pwdReq.length; i++) {
          if(this.pwdReq[i].required) {
            if(this.pwdReq[i].type === 'length') {
              if(this.pd.length >= this.pwdReq[i].range.low && this.pd.length <= this.pwdReq[i].range.high) {
                this.pwdReqMatch[i].matched = 'matched'
              } else {
                this.pwdReqMatch[i].matched = 'unmatched'
              }
            } else if (this.pwdReq[i].type === "lowercase_letter") {
              if (/[a-z]/.test(this.pd)) {
                this.pwdReqMatch[i].matched = "matched";
              }else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if(this.pwdReq[i].type === 'number') {
              if(/\d/.test(this.pd)) {
                this.pwdReqMatch[i].matched = 'matched'
              } else {
                this.pwdReqMatch[i].matched = 'unmatched'
              }
            } else if (this.pwdReq[i].type === "cannot_contain_special_characters") {
              let invalidCharsRegex = new RegExp(/[<>]/);
              if (!invalidCharsRegex.test(this.pd) && this.pd) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "special_characters") {
              let validCharsRegex = new RegExp(/[-\\~`{:;"'?.|,}/[!@#$%^&*()_+=\]]/);
              if (validCharsRegex.test(this.pd) && this.pd) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            }
          }
        }
        this.containsBlackListPassword(this.pd);
        if(this.pd != "" && this.pd.length > 0) {
          if ( !this.success) {
            this.passwordBlkListValidation = false;
          } else {
            this.passwordBlkListValidation = true;
          }
        }
        this.checkSequence()
      } else {
      for (let i = 0; i < this.pwdReq.length; i++) {
        if (this.pwdReq[i].required) {
          if (this.pwdReq[i].type === "length") {
            const valid_range =
              this.pd.length >= this.pwdReq[i].range.low &&
              this.pd.length <= this.pwdReq[i].range.high;
            // console.log("valid range ------", valid_range)

            if (valid_range) {
              this.pwdReqMatch[i].matched = "matched";
            } else {
              this.pwdReqMatch[i].matched = "unmatched";
            }
          } else if (this.pwdReq[i].type === "atleast_one_letter") {
            if (/[a-z]/.test(this.pd)) {
              this.pwdReqMatch[i].matched = "matched";
            } else {
              this.pwdReqMatch[i].matched = "unmatched";
            }
          } else if (this.pwdReq[i].type === "atleast_one_number") {
            if (/[0-9]/.test(this.pd)) {
              this.pwdReqMatch[i].matched = "matched";
            } else {
              this.pwdReqMatch[i].matched = "unmatched";
            }
          } else if (this.pwdReq[i].type === "complexity") {
            this.containsBlackListPassword(this.pd);
            if (
              !this.success &&
              this.pd != "" &&
              this.pd.length > 0
            ) {
              this.passwordBlkList = true;
              this.pwdReqMatch[i].matched = "matched";
            } else {
              this.passwordBlkList = false;
              this.pwdReqMatch[i].matched = "unmatched";
            }
          }
        }
      }
      this.checkSequence()
      // reset invalid characters
      this.invalidCharacters = false 

      // check for invalid characters (<,>,")
      let invalidCharsRegex = new RegExp(/["<>]/);
      if(invalidCharsRegex.test(this.pd) && !this.enhanceFpFlow) {
        this.invalidCharacters = true      }
      }

      const matchArray = this.pwdReqMatch.map((res) => res.matched);
      this.validPd = matchArray.every((res) => res == "matched");
    },
    checkSequence: function() {
      let sequenceMatched = false;
      this.checkSequenceValidationError = false
      let convertToLowerCase = this.pd.toLowerCase()
      let checkString = convertToLowerCase.split('')
      let dupString = this.pd.split('')
      let requestPayload = ''
      let sequenceVal = ''
      for(let i = 3; i < checkString.length; i++) {
        if (!(this.invalidCharRegex.test(checkString[i]))) {
          if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == 1) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == 1) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == 1)) {
            sequenceMatched = true;
            sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i]
            break;
          }
          if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == 0) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == 0) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == 0)) {
            sequenceMatched = true;
            sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i];
            break;
          }
          if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == -1) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == -1) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == -1)) {
            sequenceMatched = true;
            sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i];
            break;
          }
        }
      }
      if(sequenceMatched) {
        this.checkSequenceValidationError = true
        requestPayload = {
          'userID': this.username,
          'sequence': sequenceVal
        }
        this.loggSequences(requestPayload)
      }
    },
    questionSelected(selectedQuestion, selectionType) {
      if (selectionType === "enter") {
        // move focus to button if user
        // click enter after selecting an option from dropdown

        this.challengeQuestion = selectedQuestion.id;
        this.challengeQuestionText = selectedQuestion.question;
        this.$nextTick(() => this.$refs.submitButtonRef.focus());
      } else {
        this.challengeQuestion = selectedQuestion.id;

        this.challengeQuestionText = selectedQuestion.question;

        this.$nextTick(() => this.$refs.submitButtonRef.focus());
        // dont move focus to button if user select from mouse
        this.$refs.dropdownRef.focus();
      }
    },
    selectQuestion(selQuestion) {
      if (selQuestion != null) {
        this.challengeQuestion = selQuestion.id;
        this.challengeQuestionText = selQuestion.question;
      }
    },
    onSubmit() {
      let payload = {
        requestType: "RESET_PROFILE",
        password: this.pd,
        confirmPassword: this.confirmPd,
        sqaID: this.challengeQuestion,
        sqaPwd: this.confirmSqa,
      };
      this.updateVcgSecurityProfile(payload);
    },
    checkPdStrength(){
      const passlength = this.validPd;
      if(valid_range){
        this.strength = 25;
      }else if(/[a-z]/.test(this.pd)){
        this.strength = 50;
      }else if(/[0-9]/.test(this.pd)){
        this.strength = 75;
      }
      else if(this.pwdReq[i].type === "complexity"){
        this.strength = 100;
      }
      else {
        this.strength = 0;
      }
    },

},
  components: {
    Header,
    Footer,
  },
  ForgotFlowFootermixins: [commonMixin],
};
</script>
<style scoped>
body {
  color: #000000 !important;
}

#dropdownMenu1 span {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16.8px;
}

.footer-margin-top {
  margin-top: 64px;
}

.createpwd_layout {
  display: flex;
  justify-content: center;
}

.createpwd_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  width: 50%;
}

.createpwd_container_heading {
  margin: 0;
  font-size: 32px;
  color: #000000;
  line-height: 36px;
  letter-spacing: normal;
  font-family: "VzNHGeDS-bold11";
  margin-bottom: 12px;
}

.input_section {
  margin-bottom: 16px;
  position: relative;
}

.show_hide {
  position: absolute;
  top: 38px;
  right: 12px;
  border-bottom: 1px solid #000000;
}

.show_hide:focus {
  outline: 1px solid #000000;
}

.Createpwd_input_label {
  font-size: 12px;
  color: #000000;
  line-height: 16px;
  font-family: "VzNHGeTX-reg11";
}

.createpwd_input {
  padding: 12px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  width: 100%;
  border: 1px solid #cccccc;
  border-bottom: 1px solid #000;
  color: #000000;
}

.createpwd_input:focus {
  border: 1px solid #000;
}

.btn_container {
  text-align: center;
}

.submit_btn {
  /* background-color: #000000;
  color: #ffffff; */
  font-family: "VzNHGeDS-bold11";
  padding: 12px 24px;
  letter-spacing: 0.5px;
  border-radius: 25px;
  width: 100%;
  margin-top: 4px;
}
.disbaled_btn{
  background-color: #E5E6E6 !important;
  color: #FFFFFF
}
.enabled_btn{
  background-color: #000000;
  color: #ffffff;
}

.submit_btn:focus {
  outline: 1px solid #000000;
}

.strength_bar_container_label {
  width: 30%;
  display: flex;
  justify-content: space-between;
  color: #000000;
}

.strength_bar_container {
  width: 30%;
  display: flex;
  justify-content: space-between;
  color: #000000;
}

.strength_bar_container > span {
  font-size: 16px;
  color: #000000;
}

.strength_progressive_bar {
  border: 1px solid #cccccc;
  height: 20px;
  /* width: 100%; */
  margin-bottom: 16px;
  margin-top: 4px;
}
.strength_progressive_bar25{
  width: 25% !important;
}
.strength_progressive_bar50{
  width: 50% !important;
}
.strength_progressive_bar75{
  width: 75% !important;
}
.strength_progressive_bar100{
  width: 100% !important;
}

.strength_progressive_bar_weak {
  background-color: orange;
}

.strength_progressive_bar_strong {
  background-color: green;
}

.bar_bg {
  background-color: #F5F5F5;
  width: 30%;
}

.validation_para,
.valid_conditions {
  font-size: 16px;
  color: #000000;
  margin-bottom: 16px;
}

.invalid_conditions {
  font-size: 16px;
  margin-bottom: 16px;
  color: #cccccc;
}

.validation_criteria {
  margin-left: 20px;
}

.unmatched-dot {
  height: 6px;
  width: 6px;
  background-color: #000000;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 6px;
  margin-bottom: 10px;
}

.vbg-tick-mark {
  margin-right: 10px;
  width: 6px;
  height: 20px;
}

.vbg-tick-mark img {
  height: 12px;
  width: 12px;
}

.vbg-tick-mark.validationStyle {
  margin-top: 19px;
}

.error_spacing {
  margin-bottom: 8px;
}

.green_input,
.green_input:focus {
  border: 1px solid green;
  border-bottom: 4px solid green;
}

.orange_input,
.orange_input:focus {
  border: 1px solid orange;
  border-bottom: 4px solid orange;
}

.greycontentcolor {
  color: #d8dada !important;
}

@media only screen and (max-width: 1023px) {
  .footer-margin-top {
    margin-top: 48px;
  }

  .createpwd_container {
    width: 100%;
  }

  .createpwd_container_heading {
    font-size: 24px;
    line-height: 28px;
  }

  .strength_bar_container {
    width: 40%;
  }

  .dropdown {
    width: 100% !important;
  }
}

.dropdown {
  /* width: 596px !important; */
  padding: 12px 48px 12px 12px !important;
}

.dropdown-arrow {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAJCAYAAAACTR1pAAAACXBIWXMAAAsSAAALEgHS3X78AAAAZklEQVQokY3R0Q2AMAhAwccGjuQojuAIHaUjOYob1GBKQii1khAa4NIPaK0BHFr/JLD3SgH0VVdQP+i7pzXqCjt0AZsfTHFEby8sGC5faIABK0hRCgNO0RQ6nCJNsZtkISKK7mEGPCr37Vc5T6m3AAAAAElFTkSuQmCC)
    no-repeat;
  background-size: 14px;
  background-position-x: 95%;
  background-position-y: 8px;
  width: 40px;
  height: 22px;
  padding: 0px;
  text-decoration: none;
  border-bottom: none;
  position: absolute;
  right: 18px;
  bottom: 9px;
}

.dropdown-menu {
  box-shadow: none !important;
}

.dropdown-menu li {
  border-bottom: 1px solid #cdcccb;
}

.sqaText {
  white-space: normal;
}

.dropdown-menu li.chosen {
  border-left: 4px solid #d52b1e;
}
.sequence_err_mr{
  margin-bottom: 4px;
}
.sequence_err_msg{
  font-family: "VzNHGeTX-reg11";
  color: #000000 !important;
  font-size: 12px !important;
}
@media only screen and (max-width: 377px) {
  .dropdown {
    height: 58px !important;
  }
}
</style>