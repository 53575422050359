<template>
  <v-app id="app">
    <div app-data="true">
        <div>
            <router-view></router-view>
        </div>
    </div>
  </v-app>
</template>

<script>
// import LoaderComponent from '@/components/LoaderComponent'
// import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import { store } from './store'
export default {
  name: 'app',
  components: {
  },
  data () {
    return {
    }
  },
  mounted() {
    window.onpopstate = event => {
      console.log(event)
      if (window.location.pathname == "/"+iamvar_uiContext+"/business/login/stepup") {
        history.back()
        /* if(store.state.cvsstepup.flowType === 'forgot_password') {
          history.back()
        } else if (store.state.cvsstepup.flowType === 'forgot_username') {
          history.back()
        } else if(store.state.cvsstepup.flowType === 'auth') {
          // var mountUrl = store.state.login.urlOnMount
          // window.location.href = mountUrl
          history.back()
        }*/

        
        // history.back()
        /* var mountUrl = store.state.login.urlOnMount
        if(mountUrl.includes("appview=odi")) // odi
          this.$router.push({ name: 'ODILoginComponent', query: { goto: store.state.login.gotoUrl }})
        else if(mountUrl.includes('mainlogin')) //mainlogin
          window.location.href = mountUrl
        else //simplelogin
          this.$router.push({ name: 'SimpleLoginRoot', query: { goto: store.state.login.gotoUrl }}) */// redirect to home, for example
      }
    };
  },
  methods: {
  },
  computed: {
      ...mapGetters('login', [
      'gotoUrl',
      'urlOnMount'
    ])
  }
}
</script>

<style>

.v-application .error {
  background-color: #ed7000 !important; 
  border-color: #ed7000 !important;
}
.v-application .info {
  background-color: #08c !important; 
  border-color: #08c !important;
}

.v-application .success {
  background-color: #00ac3e !important; 
  border-color: #00ac3e !important;
}
</style>
