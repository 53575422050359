import 'babel-polyfill'
import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login.js'
import general from './modules/general.js'
import cvsstepup from './modules/cvsstepup.js'
import sqareg from './modules/sqareg.js'
import forgotflow from './modules/forgotflow.js'
import authstepup from './modules/authstepup.js'
import profile from './modules/profile.js'
import valogin from './modules/valogin.js'
Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    login,
    cvsstepup,
    sqareg,
    forgotflow,
    authstepup,
    general,
    profile,
    valogin
  }
})
