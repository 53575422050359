<template>
  <form @submit.prevent="onSubmit()" ref="fpForm">
    <div class="vbg-ff-form-container-main">
      <div class="vbg-ff-form-container-main-group1">
        <label for="pwd" class="vbg-ff-input-label">New password</label>
        <div class="vbg-icon-input-group">
          <input
            id="pwd"
            name="pwd"
            v-model="userPwd"
            :type="show1 ? 'text' : 'password'"
            class="vbg-rsp-input-field"
            :class="
              userPwd
                ? passwordInvalidClass() == 'orangeLoader'
                  ? 'vbg-input-warning'
                  : 'vbg-input-sucess'
                : ''
            "
            @input="checkPassword"
            @focus="showSuggestionBox()"
            @blur="hideSuggestionBox()"
            autocomplete="new-password"
          />
          <div
            :class="
              userPwd
                ? passwordInvalidClass() == 'orangeLoader'
                  ? 'vbg-warning-icon'
                  : 'vbg-success-icon'
                : ''
            "
          >
            <img
              :src="
                userPwd
                  ? passwordInvalidClass() == 'orangeLoader'
                    ? warningIcon
                    : checkIcon
                  : ''
              "
            />
          </div>
          <div class="vbg-show-icon">
            <span v-if="!show1 && userPwd" class="vbg-rsp-link" @click="show1 = !show1">Show</span>
            <span v-if="show1 && userPwd" class="vbg-rsp-link" @click="show1 = !show1">Hide</span>
          </div>
          <div class="vbg-ff-input-success">
            <span v-if="passwordInvalidClass() == 'greenLoader'"
              >Password strength : {{ pwdResetStrength }}</span
            >
            <span
              v-else-if="
                passwordInvalidClass() == 'orangeLoader' &&
                userPwd &&
                invalidCharacters &&
                !enhanceFpFlow
              "
              >Please enter a valid password</span
            >
            <span
              v-else-if="
                passwordInvalidClass() == 'orangeLoader' &&
                userPwd &&
                checkSequenceValidationError &&
                enhanceFpFlow
              "
              >Avoid sequences like abcd, 1234, AAAA, 1111.</span
            >
            <span
              v-else-if="
                passwordInvalidClass() == 'orangeLoader' &&
                passwordBlkListValidation &&
                enhanceFpFlow
              "
              >Password is too easy to guess. Try another.</span
            >
            <span 
              v-else-if="
                passwordInvalidClass() == 'orangeLoader' && 
                userPwd && enhanceFpFlow && 
                !this.displaySuggestionBox
                "
                >Password doesn't meet all requirement</span
                >
          </div>
        </div>
      </div>
      <!-- <div v-if="userPwd ? (passwordInvalidClass() == 'orangeLoader' ? true : false) : false" class="password-criteria-container"> -->
      <div v-if="displaySuggestionBox" class="password-criteria-container">
        <div class="password-criteria">
          <div>
            <span class="pas-str"
              >Password strength :
              {{ userPwd.length > 0 ? pwdResetStrength : "" }}</span
            >
            <!-- progress bar -->
            <div class="pwd-progress-bar">
              <div
                class="pwd-progress-bar-black"
                v-bind:style="{ width: computedPwdProgress }"
              ></div>
            </div>
            <div
              v-for="(req, i) in pwdReq"
              :key="i"
              style="display: flex; height: 20px; color: #000;"
            >
              <div
                v-if="req.required"
                :class="[
                  pwdReqMatch[i].matched == 'matched'
                    ? 'vbg-tick-mark'
                    : 'unmatched-dot',
                  req.type == 'cannot_contain_special_characters'
                    ? 'validationStyle'
                    : '',
                ]"
              >
                <img
                  v-if="pwdReqMatch[i].matched == 'matched'"
                  :src="checkIcon"
                />
              </div>
              <div
                v-if="req.required"
                class="pwd-progress-bar-text"
                :class="
                  req.type == 'cannot_contain_special_characters'
                    ? 'martop18'
                    : ''
                "
              >
                {{ req.display_msg }}
              </div>
            </div>
          </div>
        </div>
        <div id="pointer"></div>
      </div>
    </div>
    <div
      class="input-success-mobile"
      v-if="
        passwordInvalidClass() == 'greenLoader' ||
        (passwordInvalidClass() == 'orangeLoader' &&
          userPwd &&
          invalidCharacters &&
          !enhanceFpFlow) ||
        (passwordInvalidClass() == 'orangeLoader' &&
          userPwd &&
          checkSequenceValidationError &&
          enhanceFpFlow) ||
        (passwordInvalidClass() == 'orangeLoader' &&
          passwordBlkListValidation &&
          enhanceFpFlow)
      "
    ></div>
    <div class="vbg-ff-form-container-second-group">
      <label for="pwdConfirm" class="vbg-ff-input-label"
        :class="passwordInvalidClass() == 'orangeLoader'? 'vbg-rsp-disabled-label' : ''"
        >Re-enter password
      </label>
      <div class="vbg-icon-input-group">
        <input
          id="pwdConfirm"
          name="pwdConfirm"
          v-model="userReenteredPwd"
          :type="show2 ? 'text' : 'password'"
          class="vbg-rsp-input-field"
          :class=" 
            passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label' : 
            userReenteredPwd
              ? repasswordValidClass() == 'orangeLoader'
                ? 'vbg-input-warning'
                : 'vbg-input-sucess'
              : ''
          "
          autocomplete="new-password"
          @click.right.prevent
          @copy.prevent
          @paste.prevent
          :disabled="passwordInvalidClass() == 'orangeLoader'? true : false"
        />
        <div
          v-if="passwordInvalidClass() != 'orangeLoader'"
          :class="
            userReenteredPwd
              ? repasswordValidClass() == 'orangeLoader'
                ? 'vbg-warning-icon'
                : 'vbg-success-icon'
              : ''
          "
        >
          <img
            :src="
              userReenteredPwd
                ? repasswordValidClass() == 'orangeLoader'
                  ? warningIcon
                  : checkIcon
                : ''
            "
          />
        </div>
        <div class="vbg-show-icon" :class="passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label': ''">
          <span v-if="!show2 && userReenteredPwd" class="vbg-rsp-link" @click="show2 = !show2"
            >Show</span
          >
          <span v-if="show2 && userReenteredPwd" class="vbg-rsp-link" @click="show2 = !show2"
            >Hide</span
          >
        </div>
        <div
          class="vbg-ff-input-success"
          :class="passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label': ''"
          v-if="userReenteredPwd && repasswordValidClass() == 'greenLoader'"
        >
          <!--<span>Password match: Yes</span>-->
          <span>Your passwords match!</span>
        </div>
        <div
          class="vbg-ff-input-success"
          :class="passwordInvalidClass() == 'orangeLoader' ? 'vbg-rsp-disabled-label': ''"
          v-if="userReenteredPwd && repasswordValidClass() == 'orangeLoader'"
        >
          <span>Passwords do not match.</span>
        </div>
      </div>
    </div>
    <div
      class="input-success-mobile"
      v-if="
        (userReenteredPwd && repasswordValidClass() == 'greenLoader') ||
        (userReenteredPwd && repasswordValidClass() == 'orangeLoader')
      "
    ></div>
    <div class="vbg-ff-form-container-button-group">
      <button
        class="btn vbg-ff-btn-continue vbg-ff-btn-width140"
        :class="isDisabledClass"
        style=""
        type="submit"
        :disabled="isDisabledButton"
        ref="submitButton"
        id="new-pwd-btn"
        name="new-pwd-btn"
      >
        Continue
      </button>
      <!-- <button
        class="btn vbg-ff-btn-cancel vbg-ff-btn-width200"
        @click="cancelPwdRecovery()"
        style=""
        type="button"
      >
        Cancel
      </button> -->
    </div>
    <div style="margin-bottom: 80px"></div>
  </form>
</template>
<script>
import translations from "@/common/assets/json/messages1.json";
import { mapGetters, mapActions } from "vuex";
import warningIcon from "@/common/assets/styles/corp/images/warning.png";
import checkIcon from "@/common/assets/styles/corp/images/checkmark.png";
import commonMixin from "@/common/mixin/commonMixins";
export default {
  name: "ResetSecurityProfileNewPwd",
  components: {},
  mixins: [commonMixin],
  mounted() {
    this.changeContextBiocatch('rsp_new_password_page')
    window.scrollTo(0, 0);
    this.getPassswordBlackList();
    if (window.location.href.includes("login")) {
      this.readConfig("vbg_config");
    } else if (window.location.href.includes("consumer")) {
      this.readConfig("vcg_config");
    }
   // this.readFormInputs("pwd_reset_component");
  },
  data() {
    return {
      uname: "",
      userPwd: "",
      userReenteredPwd: "",
      pwdResetSuggestion: null,
      pwdResetStrength: "", // Strong/Medium/Weak
      pwdValid: false,
      fieldRules: [(v) => !!v || "Please fill out this field"],
      valid: false,
      noOfPwdRequirements: 0,
      pwdProgressWidth: "10%",
      passwordBlkList: false,
      invalidCharacters: false,
      warningIcon: warningIcon,
      checkIcon: checkIcon,
      show1: false,
      show2: false,
      displaySuggestionBox: false,
      passwordBlkListValidation: false,
      checkSequenceValidationError: false,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\": <>\?]/
    };
  },
  methods: {
    ...mapActions("forgotflow", [
      "processInputs",
      "resetUserPassword",
      //added password blocklist functions
      "containsBlackListPassword",
      "getPassswordBlackList",
      "readFormInputs",
      "readConfig",
    ]),
    ...mapActions("cvsstepup", ["updateFpAlerts"]),
    ...mapActions("profile",["updatePassword","loggSequences"]),
    ...mapActions("login", [ "updateUnlockMyAccountAlerts" ]),
    passwordInvalidClass() {
      if (
        !this.pwdValid ||
        this.invalidCharacters ||
        this.passwordBlkListValidation ||
        this.checkSequenceValidationError
      )
        return "orangeLoader";
      return "greenLoader";
    },
    repasswordValidClass() {
      if (this.isRePwdValid()) return "greenLoader";
      return "orangeLoader";
    },
    cancelPwdRecovery() {
      this.$store.dispatch("cvsstepup/resetState");
      this.$store.dispatch("forgotflow/resetState");
      this.updateFpAlerts([]);
      this.$router.push({ name: "AMForgotPwdComponent" }).catch((err) => {
        throw err;
      });
    },
    isRePwdValid() {
      if (this.userPwd === this.userReenteredPwd) {
        return true;
      } else return false;
    },
    passwordBlkListValue: function (params) {
      this.passwordBlkList = params;
    },
    onSubmit: async function () {
      this.$refs.submitButton.blur();
      if (this.isRePwdValid()) {
        let payload = {
          uuID: this.uuID,
          password: this.userPwd,
          confirmPassword: this.userReenteredPwd,
        };
          this.updatePassword(payload);
      }
    },
    checkPassword: function () {
      this.passwordBlkListValidation = false;
      if (this.enhanceFpFlow) {
        for (let i = 0; i < this.pwdReq.length; i++) {
          if (this.pwdReq[i].required) {
            if (this.pwdReq[i].type === "length") {
              if (
                this.userPwd.length >= this.pwdReq[i].range.low &&
                this.userPwd.length <= this.pwdReq[i].range.high
              ) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "lowercase_letter") {
              if (/[a-z]/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "number") {
              if (/\d/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (
              this.pwdReq[i].type === "cannot_contain_special_characters"
            ) {
              let invalidCharsRegex = new RegExp(/[<>]/);
              if (!invalidCharsRegex.test(this.userPwd) && this.userPwd) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "special_characters") {
              let validCharsRegex = new RegExp(
                /[-\\~`{:;"'?.|,}/[!@#$%^&*()_+=\]]/
              );
              if (validCharsRegex.test(this.userPwd) && this.userPwd) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            }
          }
        }
        this.containsBlackListPassword(this.userPwd);
        if (this.userPwd != "" && this.userPwd.length > 0) {
          if (!this.success) {
            this.passwordBlkListValidation = false;
          } else {
            this.passwordBlkListValidation = true;
          }
        }
        this.checkSequence();
      } else {
        for (let i = 0; i < this.pwdReq.length; i++) {
          if (this.pwdReq[i].required) {
            if (this.pwdReq[i].type === "length") {
              if (this.userPwd.length >= this.pwdReq[i].range.low) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "uppercase_letter") {
              if (/[A-Z]/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "lowercase_letter") {
              if (/[a-z]/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            } else if (this.pwdReq[i].type === "number") {
              if (/\d/.test(this.userPwd)) {
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.pwdReqMatch[i].matched = "unmatched";
              }
            }
            // blacklist password
            else if (this.pwdReq[i].type === "complexity") {
              this.containsBlackListPassword(this.userPwd);
              if (
                !this.success &&
                this.userPwd != "" &&
                this.userPwd.length > 0
              ) {
                this.passwordBlkList = true;
                this.pwdReqMatch[i].matched = "matched";
              } else {
                this.passwordBlkList = false;
                this.pwdReqMatch[i].matched = "unmatched";
              }
            }
            ////
          }
        }
        // reset invalid characters
        this.invalidCharacters = false;

        // check for invalid characters (<,>,")
        let invalidCharsRegex = new RegExp(/["<>]/);
        if (invalidCharsRegex.test(this.userPwd) && !this.enhanceFpFlow) {
          this.invalidCharacters = true;
        }
      }
      this.displaySuggestionBox = true;
    },
    changePwdProgressWidth: function (pwdStrength) {
      if (pwdStrength === "Weak") {
        this.pwdProgressWidth = "10%";
      } else if (pwdStrength === "Medium") {
        this.pwdProgressWidth = "70%";
      } else if (pwdStrength === "Strong") {
        this.pwdProgressWidth = "100%";
      }
    },
    hideSuggestionBox: function () {
      this.displaySuggestionBox = false;
    },
    showSuggestionBox: function () {
      this.displaySuggestionBox = true;
      //if(this.userPwd.length > 0) {
      //  this.displaySuggestionBox = true
      //} else {
      //  this.displaySuggestionBox = false
      //}
    },
    checkSequence: function () {
      let sequenceMatched = false;
      this.checkSequenceValidationError = false;
      let convertToLowerCase = this.userPwd.toLowerCase();
      let checkString = convertToLowerCase.split("");
      let dupString = this.userPwd.split('');
      let requestPayload = ''
      let sequenceVal = ''
      for(let i = 3; i < checkString.length; i++) {
        if (!(this.invalidCharRegex.test(checkString[i]))) {
          if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == 1) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == 1) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == 1)) {
            sequenceMatched = true;
            sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i]
            break;
          }
          if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == 0) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == 0) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == 0)) {
            sequenceMatched = true;
            sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i];
            break;
          }
          if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == -1) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == -1) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == -1)) {
            sequenceMatched = true;
            sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i];
            break;
          }
        }
      }
      if (sequenceMatched) {
        this.checkSequenceValidationError = true;
        requestPayload = {
          'userID': this.uuID,
          'sequence': sequenceVal
        }
        this.loggSequences(requestPayload)
      }
    },
  },
  computed: {
    ...mapGetters("forgotflow", [
      "pwdReq",
      "noOfPwdReq",
      //blocklist password
      "passwordBlackListC",
      "passwordBlackListE",
      ///////
      "iamvar_enableCaptcha",
      "iamvar_enableJCaptcha",
      "pwdHistoryReqMet",
      "success",
      "submitLoader",
      "enhanceFpFlow",
    ]),
    ...mapGetters("profile", [
      "uuID"
    ]),
    pwdReqMatch: {
      get: function () {
        return this.$store.getters["forgotflow/pwdReqMatch"];
      },
      set: function (newVal) {
        this.$store.dispatch("forgotflow/updatePwdReqMatch", newVal);
      },
    },
    computedPwdProgress: function () {
      return this.userPwd.length > 0 ? this.pwdProgressWidth : "0%";
    },
    // createPasswordDisabled() {
    //   return !this.userPwd || !this.userReenteredPwd ||this.userPwd != this.userReenteredPwd || this.pwdResetStrength == 'Weak' || this.invalidCharacters
    // },
    isDisabledClass() {
      if (
        !this.userPwd ||
        !this.userReenteredPwd ||
        this.userPwd != this.userReenteredPwd ||
        this.pwdResetStrength == "Weak" ||
        this.invalidCharacters ||
        this.passwordBlkListValidation ||
        this.checkSequenceValidationError
      ) {
        return "disabled";
      } else {
        return "";
      }
    },
    isDisabledButton() {
      if (
        !this.userPwd ||
        !this.userReenteredPwd ||
        this.userPwd != this.userReenteredPwd ||
        this.pwdResetStrength == "Weak" ||
        this.invalidCharacters ||
        this.passwordBlkListValidation ||
        this.checkSequenceValidationError
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    pwdValid: function (newVal) {
      if (newVal) {
        if (this.userPwd.length >= 8 && this.userPwd.length < 10 && !this.passwordBlkListValidation) {
          this.pwdResetStrength = "Medium";
          this.changePwdProgressWidth("Medium");
        } else if (this.userPwd.length >= 10 && !this.passwordBlkListValidation) {
          this.changePwdProgressWidth("Strong");
          this.pwdResetStrength = "Strong";
        }
      }
      // if not valid, default pwd bar to weak
      if (!newVal) {
        this.changePwdProgressWidth("Weak");
      }
    },
    userPwd: function (newVal) {
      //if(newVal.length <= 0) {
      //  this.displaySuggestionBox = false;
      //}
      if (this.pwdValid) {
        if (newVal.length < 8) {
          this.changePwdProgressWidth("Weak");
        } else if (newVal.length >= 8 && newVal.length < 10 && !this.passwordBlkListValidation) {
          this.pwdStrengthClass = "progress-bar-warning";
          this.pwdResetStrength = "Medium";
          this.changePwdProgressWidth("Medium");
        } else if (newVal.length >= 10 && !this.passwordBlkListValidation) {
          this.pwdStrengthClass = "progress-bar-success";
          this.pwdResetStrength = "Strong";
          this.changePwdProgressWidth("Strong");
        }
      }
    },
    pwdHistoryReqMet: function (newVal) {
      if (!newVal) {
        console.log("Pwd history req not met");
        this.pwdResetSuggestion = "Password history requirements are not met.";
      }
    },
    pwdReqMatch: {
      deep: true,
      handler(newVal) {
        var noMatched = 0;
        for (var i = 0; i < newVal.length; i++) {
          if (newVal[i].required) {
            if (newVal[i].matched === "unmatched") {
              this.pwdValid = false;
              this.pwdResetStrength = "Weak";
              break;
            } else {
              noMatched += 1;
              if (noMatched === this.noOfPwdReq) {
                this.pwdValid = true;
              }
            }
          }
        }
      },
    },
    show1: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.show1 = false;
        }, 10000);
      }
    },
    show2: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.show2 = false;
        }, 10000);
      }
    },
  },
};
</script>

<style scoped>
.vbg-input-warning {
  border: 1px solid #ed7000 !important;
  border-bottom: 4px solid #ed7000 !important;
}
.vbg-input-warning:focus {
  border: 1px solid #ed7000 !important;
  border-bottom: 4px solid #ed7000 !important;
}
.vbg-input-sucess {
  border-bottom: 4px solid #008330 !important;
}
.vbg-input-sucess:focus {
  border-bottom: 4px solid #008330 !important;
}
.vbg-ff-input-success {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 8px;
  font-family: 'VzNHGeTX-reg11' !important;
  color: #000 !important;
}
.vbg-success-icon {
  height: 17px;
  width: 17px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 62px;
}
.vbg-success-icon img {
  height: 8px;
  width: 8px;
}
.vbg-show-icon {
  height: 20px;
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 12px;
  /* text-decoration: underline; */
  cursor: pointer;
  letter-spacing: 0.5px;
  font-size: 16px !important;
}
.vbg-warning-icon {
  height: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 62px;
}
.vbg-warning-icon img {
  height: 16.67px;
  width: 16.67px;
}
.password-criteria {
  background: white;
  -moz-border-radius: 10px;
  width: 326px;
  height: 208px;
  border: 1px solid black;
  padding: 16px 16px 16px 20px;
}
.pas-str {
  font-size: 12px;
  margin-bottom: 4px;
  letter-spacing: 0;
  line-height: 14px;
  font-family: NeueHaasGroteskDisplay !important;
  color: #000;
}
.pwd-progress-bar {
  display: flex;
  align-items: center;
  /* background-color: white; */
  background-color: #d8dada;
  /* border: black solid; */
  border-width: 2px;
  /* border-left: 40px solid black; */
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 16px;
}
.pwd-progress-bar-black {
  background-color: black;
  height: 2px;
}
.vbg-tick-mark {
  margin-right: 10px;
  width: 6px;
  height: 20px;
}
.vbg-tick-mark img {
  height: 12px;
  width: 12px;
}
.vbg-tick-mark.validationStyle {
  margin-top: 19px;
}
.unmatched-dot.validationStyle {
  margin-top: 26px;
}
.pwd-progress-bar-text {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  width: 155px;
}
.vbg-ff-btn-width200 {
  width: 200px;
}
.unmatched-dot {
  height: 6px;
  width: 6px;
  background-color: #000000;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: 6px;
  margin-bottom: 10px;
}
#pointer {
  margin: -170px 0 0 -4px;
  border: 1px solid black;
  width: 8px;
  height: 8px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-right: none;
  border-top: none;
  background: white;
}
.disabled {
  background-color: #d8dada !important;
}
.vbg-icon-input-group { position: relative; }
.vbg-icon-input-group input { width: 100%; }
.vbg-ff-btn-continue {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  padding: 12px;
  font-size: 16px;
}
.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
}
.vbg-rsp-disabled-label {
  color: #d8dada !important;
  cursor: not-allowed;
}

/* from mobile to tablet screen */
@media (max-width: 1024px) {
  .vbg-ff-form-container-main {
    position: relative;
    height: 64px;
    margin-top: 64px;
  }
  .password-criteria {
    width: 100%;
  }
  .password-criteria-container {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    padding: 0 !important;
    height: 208px;
    position: absolute;
    bottom: 47px;
    max-width: 328px;
    width: 100%;
  }
  .input-success-mobile {
    height: 24px;
  }
  .vbg-ff-form-container-second-group {
    height: 64px;
    margin-top: 24px;
  }
  .vbg-ff-form-container-button-group {
    margin-top: 48px;
  }
  #pointer {
    margin: -4px 0 0 18px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
@media (max-width: 768px) {
  .vbg-ff-btn-width200 {
    width: 100% !important;
  }
  .vbg-ff-btn-cancel {
    /* margin-bottom: 12px; */
    margin-top: 12px;
  }
  /* .vbg-ff-form-container-button-group {
    display: flex;
    flex-direction: column-reverse;
  } */
}
/* for tablet only */
@media (min-width: 768px) and (max-width: 1024px) {
  .vbg-ff-btn-width200 {
    width: 47% !important;
  }
}
/* only for desktop view */
@media (min-width: 1025px) {
  .vbg-ff-form-container-main {
    max-width: 635px;
    height: 64px;
    margin-top: 64px;
  }
  .vbg-ff-form-container-main-group1 {
    float: left;
  }
  .vbg-icon-input-group {
    width: 278px !important;
  }
  .password-criteria-container {
    float: right;
    width: 344px;
    height: 208px;
  }
  .vbg-ff-form-container-button-group {
    margin-top: 64px;
  }
  .input-success-mobile {
    display: none !important;
  }
  .vbg-ff-form-container-second-group {
    height: 64px;
    margin-top: 48px;
  }
}
</style>