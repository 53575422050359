<template>
    <div>
        <vpsHeader></vpsHeader>
        <div class="vps-container center_margin mainContainer">
            <div class="paddingMobile">
                <div class="myprofileContainer">
                    <form id="vpsSecurityProfileUsernameEdit" class="signin" @submit.prevent="submitForm">
                        <div class="ovrview-msg" v-if="isErrorMessageEnabled">
                            <div class="vbg-ff-messagesInfo" v-bind:class="'error'">
                                <div class="vbg-ff-msg-container">
                                    <div class="messageWrapper">
                                        <div class="messageIcon msg-icon-position">
                                            <div class="messageImage" :class="'newErrorMessageImage'"></div>
                                        </div>
                                        <p class="messageContentUn" v-html="editPwdErrorMessage">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="headUserId">
                            <h2 class="descEdit myprofileHead">Manage Password</h2>
                        </div>
                        <div class="form-group">
                            <span class="labelText">Current password</span>
                            <input id="currentPwdInput" class="input-f input_mr input_border" autofocus type="password"
                                required v-model="currentPwdInput" placeholder="********" maxlength="20" />
                        </div>
                        <div class="form-group">
                            <span class="labelText">New password</span>
                            <input id="newPwdInput" class="input-f input_mr input_border" type="password" required
                                :class="[isPwdInvalid ? 'vps-input-field-warning' : '', checkSequenceValidationError ? 'vps-input-field-warning': '' ]" @keyup="validatePwd"
                                v-model="newPwdInput" placeholder="********" maxlength="20" />
                            <div v-if="isPwdInvalid">
                                <p class="vps-input-errortext-style"
                                    v-html="$t('message.vps_edit_profile_invalid_pwd')">
                                </p>
                            </div>
                            <div v-if="checkSequenceValidationError">
                                <p class="vps-input-errortext-style"
                                    v-html="$t('message.vps_edit_profile_repetitive_error')">
                                </p>
                            </div>
                            <p class="pwdStrength">
                                Password strength : <span class="NeueHaasGroteskDisplay pwdstringvalidate"></span>
                            </p>
                            <p class="rules">Minimum 8 characters, &nbsp;atleast 1 letter and 1 number,&nbsp; cannot
                                match
                                user ID,&nbsp; cannot be an easily guessed password, &nbsp;no spaces,&nbsp; case
                                sensitive.
                            </p>
                        </div>
                        <div class="form-group">
                            <span class="labelText">Confirm new password</span>
                            <input id="verifyPwdInput" class="input-f input_mr input_border"
                                :class="!isPwdMatched ? 'vps-input-field-warning' : ''" type="password" required
                                v-model="verifyPwdInput" placeholder="********" maxlength="20" />
                            <div v-if="!isPwdMatched">
                                <p class="vps-input-errortext-style"
                                    v-html="$t('message.vps_edit_profile_pwd_mismatch')">
                                </p>
                            </div>
                        </div>
                        <div class="buttonset">
                            <button type="submit"
                                class="btn  vbg-ff-btn-continue modal-popup-btn vbg-ff-btn-width140 spacing05">
                                <span>Update</span>
                            </button>
                            <button class="btn vbg-ff-btn-cancel modal-popup-btn vbg-ff-btn-width140 spacing05"
                                @click="onCancel()">
                                <span>Cancel</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <vpsFooter></vpsFooter>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
import translations from '@/common/assets/json/messages1.json'
import {eventTracking} from '../../../../../public/adobeTagging';
export default {
    name: 'VPSSecurityProfilePwdEdit',
    components: {
        vpsHeader,
        vpsFooter
    },
    data() {
        return {
            currentPwdInput: "",
            newPwdInput: "",
            verifyPwdInput: "",
            firstName: "testing",
            lastName: "lastName",
            secProfileEmail: "testMail@verizon.com",
            securityQuestion: "what is your nickname ?",
            isPwdMatched: true,
            isPwdInvalid: false,
            isErrorMessageEnabled: this.showerrorMsg,
            editPwdErrorMessage: translations.en.message['vps_edit_profile_pwd_same_as_previous'],
            checkSequenceValidationError: false,
            invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\": <>\?]/,
        }
    },
    methods: {
         ...mapActions('profile', [
            'modifyPassword'
        ]),
        submitForm() {
        let payload = {
            uuID: this.uuID,
            currentPassword: this.currentPwdInput,
            password: this.newPwdInput,
            confirmPassword: this.verifyPwdInput,
        };
            eventTracking("VPSPassword Reset Initiated", "");
            this.modifyPassword(payload);
        },
        validatePwd() {
            let result = this.checkPwdStrength(this.newPwdInput);
            this.checkSequence(this.newPwdInput);
            if (result.split(' ').length > 2) {
                $('.pwdstringvalidate') && $('.pwdstringvalidate').text(result.split(' ')[0] + " " + result.split(' ')[1]);
                $('.pwdstringvalidate') && $('.pwdstringvalidate').css('color', result.split(' ')[2]);
            } else {
                $('.pwdstringvalidate') && $('.pwdstringvalidate').text(result.split(' ')[0]);
                $('.pwdstringvalidate') && $('.pwdstringvalidate').css('color', result.split(' ')[1]);
            }
            if (this.newPwdInput == "") {
                $('.pwdstringvalidate') && ('.pwdstringvalidate').text(' ');
            }
        },
        checkPwdStrength(str) {
            if (/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+).*$/.test(str) && str.length >= 8) {
                if (/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+).*$/.test(str) && str.length > 10) {
                    this.isPwdInvalid = false;
                    return "Strong #00ac3e";
                }
                this.isPwdInvalid = false;
                return "Medium #ed7000";
            }
            if (str.length < 8) {
                this.isPwdInvalid = true;
                return "Not Valid #ffbc3d";
            }
        },
        onCancel() {
            this.$router.push({ name: 'VPSSecurityProfilePage' })
        },
        checkSequence: function (pwdInputValue) {
            let sequenceMatched = false;
            let convertToLowerCase = pwdInputValue.toLowerCase();
            let checkString = convertToLowerCase.split("");
            let dupString = pwdInputValue.split('');
            let sequenceVal = ''
            for(let i = 3; i < checkString.length; i++) {
                if (!(this.invalidCharRegex.test(checkString[i]))) {
                if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == 1) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == 1) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == 1)) {
                    sequenceMatched = true;
                    sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i]
                    break;
                }
                if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == 0) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == 0) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == 0)) {
                    sequenceMatched = true;
                    sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i];
                    break;
                }
                if((checkString[i].charCodeAt() - checkString[i - 1].charCodeAt() == -1) && (checkString[i - 1].charCodeAt() - checkString[i - 2].charCodeAt() == -1) && (checkString[i - 2].charCodeAt() - checkString[i - 3].charCodeAt() == -1)) {
                    sequenceMatched = true;
                    sequenceVal = dupString[i - 3] + dupString[i - 2] + dupString[i - 1] + dupString[i];
                    break;
                }
                }
            }
            if (sequenceMatched) {
                this.checkSequenceValidationError = true;
            }
        },
    },
    computed: {
    ...mapGetters("profile", [
        'uuID',
        'showerrorMsg'
    ]),
    },
    watch: {
        verifyPwdInput: function (newVal) {
            (newVal === this.newPwdInput) ? this.isPwdMatched = true : this.isPwdMatched = false;
            if(this.isPwdMatched) {
                this.checkSequenceValidationError = false
            }
        },
        newPwdInput: function (newVal) {
            if (this.verifyPwdInput !== "") {
                (newVal === this.verifyPwdInput) ? this.isPwdMatched = true : this.isPwdMatched = false;
            }
            if(this.isPwdMatched) {
                this.checkSequenceValidationError = false
            }
        }
    },
    mounted() {
    }
}
</script>
<style scoped>
.vps-container {
    height: 100%;
    width: auto;
    margin: 40px;
}

.mainContainer {
    margin-top: 100px;
}

.messageImage {
    margin-right: 10px;
}

.pwdStrength {
    font-weight: bold;
}

.paddingMobile {
    padding: 10px 16px;
    overflow-x: auto;
}

.vps-input-field-warning {
    border: 1px solid #ED7000 !important;
    border-bottom: 4px solid #ED7000 !important;
}

.vps-input-errortext-style {
    height: 32px;
    width: 301px;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: bold;
}

.myprofileContainer {
    width: 575px;
    padding-top: 5px;
    border-top: 4px solid #000;
}

@media (max-width: 768px) {
    .myprofileContainer {
        width: 100%;
    }
}

.headUserId {
    float: left;
    width: 100%;
}

.descEdit {
    margin-bottom: 50px;
    font-size: 16px;
    display: inline;
}

.myprofileHead {
    margin-top: 20px;
    float: left;
}

.buttonset {
    margin-top: 50px;
    margin-bottom: 50px;
}

.labelText {
    font-size: 14px;
    color: #747676;
    padding-bottom: 4px;
    display: block;
}

.input-f {
    width: 240px;
    height: 34px;
}
</style>