<template>
    <div>
        <vpsHeader></vpsHeader>
        <div class="vps-container center_margin mainContainer">
            <div class="paddingMobile">
                <div class="myprofileContainer">
                    <form id="vpsSecurityProfileUsernameEdit" class="signin" @submit.prevent="submitForm">
                        <div class="ovrview-msg" v-if="isErrorMessageEnabled">
                            <div class="vbg-ff-messagesInfo" v-bind:class="'error'">
                                <div class="vbg-ff-msg-container">
                                    <div class="messageWrapper">
                                        <div class="messageIcon msg-icon-position">
                                            <div class="messageImage" :class="'newErrorMessageImage'"></div>
                                        </div>
                                        <p class="messageContentUn" v-html="editUsernameErrorMsg">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="headUserId">
                            <h2 class="descEdit myprofileHead">Edit the name</h2>
                        </div>
                        <div class="form-group">
                            <span class="labelText">First name</span>
                            <input id="firstNameInput" class="input-f input_mr input_border"
                                :class="[!isValidFirstName ? 'vps-input-field-warning' : '', isForbiddenFirstName ? 'vps-input-field-warning' : '']" autofocus type="text"
                                v-model="firstName" maxlength="50" />
                            <div v-if="!isValidFirstName">
                                <p class="vps-input-errortext-style" v-html="$t('message.vps_profile_firstname_error')">
                                </p>
                            </div>
                            <div v-if="isForbiddenFirstName">
                                <p class="vps-input-errortext-style" v-html="$t('message.vps_profile_edit_forbiden_charac_error')">
                                </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <span class="labelText">Last name</span>
                            <input id="lastNameInput" class="input-f input_mr input_border"
                                :class="[!isValidLastName ? 'vps-input-field-warning' : '', isForbiddenLastName ? 'vps-input-field-warning' : '']" autofocus type="text"
                                v-model="lastName" maxlength="50" />
                            <div v-if="!isValidLastName">
                                <p class="vps-input-errortext-style" v-html="$t('message.vps_profile_lastname_error')">
                                </p>
                            </div>
                            <div v-if="isForbiddenLastName">
                                <p class="vps-input-errortext-style" v-html="$t('message.vps_profile_edit_forbiden_charac_error')">
                                </p>
                            </div>
                        </div>
                        <div class="buttonset">
                            <button type="submit"
                                class="btn  vbg-ff-btn-continue modal-popup-btn vbg-ff-btn-width140 spacing05">
                                <span>Update</span>
                            </button>
                            <button class="btn vbg-ff-btn-cancel modal-popup-btn vbg-ff-btn-width140 spacing05"
                                @click="onCancel()">
                                <span>Cancel</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <vpsFooter></vpsFooter>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
import translations from '@/common/assets/json/messages1.json'
export default {
    name: 'VPSSecurityProfileUsernameEdit',
    components: {
        vpsHeader,
        vpsFooter
    },
    data() {
        return {
            firstName: "",
            lastName: "",
            isValidFirstName: true,
            isValidLastName: true,
            isErrorMessageEnabled: this.showerrorMsg,
            editUsernameErrorMsg: translations.en.message['vps_profile_username_invalidchar'],
            isForbiddenFirstName : false,
            isForbiddenLastName : false,
            forbiddenCharacters : /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/,
        }
    },
    methods: {
         ...mapActions('profile', [
            'modifyUser'
        ]),
        submitForm() {
            let payload = {
                uuID: this.uuID,
                cn: this.firstName,
                sn: this.lastName,
            };
            this.isForbiddenLastName = false;
            this.isForbiddenFirstName = false;
            if (this.firstName === "") {
                this.isValidFirstName = false;
            }
            if (this.forbiddenCharacters.test(this.firstName)) {
                this.isForbiddenFirstName = true;
            }
            if (this.lastName === "") {
                this.isValidLastName = false;
            }
            if (this.forbiddenCharacters.test(this.lastName)) {
                this.isForbiddenLastName = true;
            }
            if (this.firstName !== "" && this.lastName !== "" && !this.isForbiddenLastName && !this.isForbiddenFirstName) {
                this.modifyUser(payload);
            }
        },
        onCancel() {
            this.$router.push({ name: 'VPSSecurityProfilePage' })
        }
    },
    computed: {
    ...mapGetters("profile", [
        'uuID',
        'showerrorMsg'
    ]),
    },
    watch: {
        firstName: function (newVal) {
            newVal.trim() === "" ? this.isValidFirstName = false : this.isValidFirstName = true;
            if(!this.isValidFirstName) {
                this.isForbiddenFirstName = false
            }
        },
        lastName: function (newVal) {
            newVal.trim() === "" ? this.isValidLastName = false : this.isValidLastName = true;
            if(!this.isValidLastName) {
                this.isForbiddenLastName = false
            }
        }
    },
    mounted() {
    }
}
</script>
<style scoped>
.vps-container {
    height: 100%;
    width: auto;
    margin: 40px;
}

.vps-input-field-warning {
    border: 1px solid #ED7000 !important;
    border-bottom: 4px solid #ED7000 !important;
}

.vps-input-errortext-style {
    height: 32px;
    width: 301px;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: bold;
}

.mainContainer {
    margin-top: 100px;
}

.messageImage {
    margin-bottom: 45px;
    margin-right: 10px;
}

.paddingMobile {
    padding: 10px 16px;
    overflow-x: auto;
}

.myprofileContainer {
    width: 575px;
    padding-top: 5px;
    border-top: 4px solid #000;
}

.buttonset {
    margin-top: 50px;
    margin-bottom: 50px;
}

@media (max-width: 768px) {
    .myprofileContainer {
        width: 100%;
    }
}

.headUserId {
    float: left;
    width: 100%;
}

.descEdit {
    margin-bottom: 50px;
    font-size: 16px;
    display: inline;
}

.myprofileHead {
    margin-top: 20px;
    float: left;
}

.labelText {
    font-size: 14px;
    color: #747676;
    padding-bottom: 4px;
    display: block;
}

.input-f {
    width: 240px;
    height: 34px;
}

@media (max-width: 768px) {
    .input-f {
        width: 100%;
    }
}
</style>