import profilerest from '@/profile/util/profilerest-axios.js'
import { playrest } from '@/vbgauth/util/playrest-axios.js'
import { vbgErrorMessage, iamvar_vbgcCookieName } from '../../../../public/const.js'
import passwordConfig from '@/common/assets/json/password_config.json'
import router from '@/profile/router'
import routerLogin from '@/vbgauth/router'
import translations from '@/common/assets/json/messages1.json'
import messages from '@/common/assets/json/messages1.json'
import mixin from '@/common/mixin/commonMixins.js'

const getUpdatedMessage = (payload) => {
    let str = ''
    if(payload) {
        if(payload.includes('The Verification Code you entered is invalid or expired')) {
            str = messages.en.message["rsp_invalid_passcode"];
        } else {
            const message = payload.split('. ');
            const errTitle = mixin.methods.sanitizeString(message[0]);
            const errDesc = message[1] ? mixin.methods.sanitizeString(message[1]) : '';
            str = `<b class="bold75">${errTitle}</b>`;
            if(errDesc) {
                str += `<br/>${errDesc}`;
            }
        }
        return str;
    }
    return ''
}


// import axios from 'axios'
// var Base64 = require('js-base64').Base64
export default {
    namespaced: true,
    state: {
        options: {
            headers: {
                'Accept-API-Version': 'resource=2.0',
                'protocol': '1.0'
            }
        },
        passwordRulesValid: true,
        message: '',
        emailAddress: '',
        userName: '',
        firstName: '',
        lastName: '',
        uuID: '',
        gotoSQA: false,
        gotoOtp: false,
        enableTC: false,
        userStatus: '',
        loginLastTime: '',
        sqa: '',
        challengeQuestion: '',
        challengeAnswer: '',
        addOtp: true,
        otpDeviceList: {},
        showVerifyOTP: false,
        showVPSPopup: false,
        gotoDevSumry: false,
        status: false,
        deliveryMethodSelected: '',
        emailAddress: '',
        migContent: false,
        errorMsg: null,
        showerrorMsg: false,
        infoMsg: null,
        showinfoMsg: false,
        successMsg: null,
        showsuccessMsg: false,
        warnMsg: null,
        showwarnMsg: false,
        gotoOtpOvw: false,
        gotoPassSet: false,
        redirectUrl: '',
        submitLoader: false,
        secretQuestions: [],
        countryCodes: [],
        gotoProfileSettings: false,
        changeURL: false,
        protected: false,
        passwordBlackListC: '',
        passwordBlackListE: '',
        success: false,
        profileRedirectUrl: '',
        otpAged:'',
        pwdReq: null,
        pwdReqMatch: [],
        noOfPwdReq: 0,
        formInputs: null,
        passwordFlowConfig: null,
        gotoUrl: '',
        maskedValue: '',
        attributedUpdated :'',
        stepupVerified:false,
        logOutGotoUrl:'',
        targetUrl:'',
        showProcessLoader:false,
        userType:'',
        gotoComponent: 'validateUserIdComponent',
        stepsContent: [
            { id: 1, title: "1. Create new password", active: true, disabled: false },
            { id: 2, title: "2. Choose a secret question", active: false, disabled: true },
            { id: 3, title: "3. Review delivery methods", active: false, disabled: true }
        ],
        rspSessionExpired: false,
        vbmSessionExpired:false,
        sdarUserType: '',
        vcgSecretQuestions: '',
        secretAnswer: '',
        vpsProfileAlerts:[]
    },
    mutations: {
        setUserType(state,payload){
            state.userType = payload
        },
        setShowProcessLoader(state,payload){
            state.showProcessLoader = payload
        },
        setTargetUrl(state, payload) {
            state.targetUrl = payload
        },
        setStepupVerified(state, payload) {
            state.stepupVerified = payload
        },
        setLogOutGotoUrl(state, payload) {
            state.logOutGotoUrl = payload
        },
        setProfileRedirectUrl(state, payload) {
            state.profileRedirectUrl = payload
        },
        setOtpAged(state, payload) {
            state.otpAged = payload
        },
        setPasswordBlackListC(state, payload) {
            state.passwordBlackListC = payload
        },
        setPasswordBlackListE(state, payload) {
            state.passwordBlackListE = payload
        },
        setGotoProfileSettings(state, payload) {
            state.gotoProfileSettings = payload
        },
        setChangeURL(state, payload) {
            state.changeURL = payload
        },
        setprotectedFlow(state, payload) {
            state.protected = payload
        },
        setChallengeQuestion(state, payload) {
            state.challengeQuestion = payload
        },
        setMIGContent(state, payload) {
            state.migContent = payload
        },
        setChallengeAnswer(state, payload) {
            state.challengeAnswer = payload
        },
        setLoader(state, payload) {
            state.submitLoader = payload
        },
        setRedirectUrl(state, payload) {
            state.redirectUrl = payload
        },
        setErrorMsg(state, payload) {
            state.errorMsg = payload
        },
        setInfoMsg(state, payload) {
            state.infoMsg = payload
        },
        setSuccessMsg(state, payload) {
            state.successMsg = payload
        },
        setWarnMsg(state, payload) {
            state.warnMsg = payload
        },
        setShowErrorMsg(state, payload) {
            state.showerrorMsg = payload
        },
        setShowInfoMsg(state, payload) {
            state.showinfoMsg = payload
        },
        setShowSuccessMsg(state, payload) {
            state.showsuccessMsg = payload
        },
        setShowWarnMsg(state, payload) {
            state.showwarnMsg = payload
        },
        setDeliveryMethodSelected(state, payload) {
            state.deliveryMethodSelected = payload
        },
        setEmailAddress(state, payload) {
            state.emailAddress = payload
        },
        setOtpDeviceList(state, payload) {
            state.otpDeviceList = payload
        },
        setStatus(state, payload) {
            state.status = payload
        },
        setFirstName(state, payload) {
            state.firstName = payload
        },
        setLastName(state, payload) {
            state.lastName = payload
        },
        setGotoDevSumry(state, payload) {
            state.gotoDevSumry = payload
        },
        setShowVerifyOTP(state, payload) {
            state.showVerifyOTP = payload
        },
        setVPSPopup(state, payload) {
            state.showVPSPopup = payload
        },
        setAddOtp(state, payload) {
            state.addOtp = payload
        },
        setChallengeQuestion(state, payload) {
            state.challengeQuestion = payload
        },
        setCountryCodes(state, payload) {
            state.countryCodes = payload
        },
        setSecretQuestions(state, payload) {
            state.secretQuestions = payload
        },
        setChallengeAnswer(state, payload) {
            state.challengeAnswer = payload
        },
        setGotoSQA(state, payload) {
            state.gotoSQA = payload
        },

        setGotoPassSet(state, payload) {
            state.gotoPassSet = payload
        },
        setGotoOtp(state, payload) {
            state.gotoOtp = payload
        },
        setGotoOtpOvw(state, payload) {
            state.gotoOtpOvw = payload
        },
        setEnableTC(state, payload) {
            state.enableTC = payload
        },
        setUuID(state, payload) {
            state.uuID = payload
        },
        setUserStatus(state, payload) {
            state.userStatus = payload
        },
        setLoginLastTime(state, payload) {
            state.loginLastTime = payload
        },
        setUserName(state, payload) {
            state.userName = payload
        },
        setSqa(state, payload) {
            state.sqa = payload
        },
        setAttributedUpdated(state,payload){
            state.attributedUpdated = payload 
        }, 
        setSuccess(state, payload) {
            state.success = payload
        },
        setPwdRequirement(state, payload) {
            state.pwdReq = payload
            for(var i = 0; i < state.pwdReq.length; i++) {
                if(state.pwdReq[i].required) {
                    state.noOfPwdReq += 1
                }
                state.pwdReqMatch.push({
                    "required": state.pwdReq[i].required,
                    "matched": "unmatched"
                })
            }
        },
        setPwdReqMatch(state, payload) {
            state.pwdReqMatch = payload
        },
        setFormInput(state, payload) {
            state.formInputs = payload
        },
        setPasswordFlowConfig(state, payload) {
            state.passwordFlowConfig = payload
        },
        setGotoUrl(state, payload) {
            state.gotoUrl = payload
        },
        setMaskedValue(state, payload) {
            state.maskedValue = payload
        },
        setGotoComponent(state, payload) {
            state.gotoComponent = payload
        },
        setStepsContent(state, payload) {
            state.stepsContent = payload
        },
        setRspSessionExpired(state, payload) {
            state.rspSessionExpired = payload
        },
        setVbmSessionExpired(state, payload) {
            state.vbmSessionExpired = payload
        },
        setSdarUserType(state, payload) {
            state.sdarUserType = payload
        },
        setVcgSecretQuestions(state, payload) {
            state.vcgSecretQuestions = payload
        },
        setSecretAnswer(state, payload) {
            state.secretAnswer = payload
        },
        setVpsProfileAlerts(state, payload) {
            state.vpsProfileAlerts = payload
        },
    },
    actions: {
      
        OttSetUP({state}, payload){
            if (iamvar_oneSessionPerLoginEnable !== true) {
                if(state.attributedUpdated ===''){
                    document.cookie = 'iamvar_profileFlow = true;path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure+';comment=setting in ui';
                    window.location.href = iamvar_serverUrl+'account/business/login/verify/otp/?continueURL='+iamvar_serverUrl+'account/business/profile/postauth/'+payload.attrUpdated+'?target='+state.profileRedirectUrl
                }else{
                    if (payload.attrUpdated) {
                        router.push({path: payload.attrUpdated, query: { target: state.profileRedirectUrl }})
                    }
                } 
            } else if (iamvar_oneSessionPerLoginEnable === true) {
                if (payload.attrUpdated) {
                    router.push({path: payload.attrUpdated, query: { target: state.profileRedirectUrl }})
                }
            }      
        },

        readFormInputs ({commit, state}, payload) {
            var formInputs = state.passwordFlowConfig[payload].form.inputs
            commit('setFormInput', formInputs)
            if(payload === 'pwd_reset_component') {
                commit('setPwdRequirement', state.passwordFlowConfig.pwd_reset_component.pwd_requirement)
            }
        },
        readConfig ({commit}, payload) {
            commit('setPasswordFlowConfig', passwordConfig[payload])
        },

        updatePwdReqMatch({commit}, payload) {
            commit('setPwdReqMatch', payload)
        },
        displayLoginIntercept({ commit, state, dispatch }) {
            dispatch('resetMessages')
            commit('setEnableTC', false)
            
            return profilerest.get('pre-auth/intercept-service/v1/start', {
            }).then(function (response) {
                var profile = response.data
                commit('setUuID', profile.uuID)
                commit('setOtpAged',true)
                // eslint-disable-next-line no-extra-boolean-cast
                if (!!profile.flow) {
                    commit('setprotectedFlow', true)

                }
                if (profile.flow.includes('RESET_PW')) {
                    commit('setGotoPassSet', true)
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'success', msg:translations.en.message["rsp_account_verified"]}], { root: true })
                }
                else if (profile.flow.includes('SQA_REG')) {
                    commit('setGotoSQA', true)
                    commit('setOtpAged',true)
                    if (profile.flow.includes('TC')) {

                        commit('setEnableTC', true)
                    }
                }

                else if (profile.flow.includes('SQA_MIG')) {
                    commit('setGotoSQA', true)
                    commit('setMIGContent', true)
                }

                else if (profile.flow.includes('OTP_OVW')) {
                    dispatch('getOtpDeviceList', profile.uuID)
                    commit('setGotoOtpOvw', true)
                }

                else if (profile.flow.includes('OTP') && !profile.flow.includes('SQA_REG')
                    && !profile.flow.includes('SQA_MIG')) {

                    commit('setGotoOtp', true)
                } else if(profile.flow.includes('VCG_CQA_REG') || profile.flow.includes('VCG_CQA_MTN_REG')) {
                    dispatch('login/updateVcgProfileView', 'resetCsqa', { root: true })
                    if (state.gotoUrl) {
                        routerLogin.push({ name: 'VcgProfileUpdateRootComponent', query: { goto: state.gotoUrl } });
                    } else {
                        routerLogin.push({ name: 'VcgProfileUpdateRootComponent' });
                    }
                } else if(profile.flow.includes('VCG_RSP')) {
                    dispatch('login/updateVcgProfileView', 'resetSecurityProfile', { root: true })
                    if (state.gotoUrl) {
                        routerLogin.push({ name: 'VcgProfileUpdateRootComponent', query: { goto: state.gotoUrl } });
                    } else {
                        routerLogin.push({ name: 'VcgProfileUpdateRootComponent' });
                    }
                }

            }).catch((error) => {
              //  commit('setGotoPassSet', true)
            }).finally(() => {
               
            })

        },
        getOtpDeviceList({ commit, state }, payload) {

            var URL = ""
          
            if (!state.protected) {
                URL = "post-auth/profile-service/v1/get-otp-devices/"
                commit('setChangeURL', false)

            } else {
                URL = "pre-auth/intercept-service/v1/get-otp-devices/"
                commit('setChangeURL', true)
            }
            let updatedPayload = {
                uuID: payload,
            }
            if (!state.protected) {
            return profilerest.post(URL, updatedPayload).then(function (response) {

                var otpdeviceList = response.data
                commit('setOtpDeviceList', otpdeviceList)
                if (typeof otpdeviceList.profileURL == 'undefined' || otpdeviceList.profileURL == null || otpdeviceList.profileURL == '') {
                    commit('setChangeURL', false)
                } else {
                    commit('setChangeURL', true)
                }


            }).catch((error) => {

            })
         } else {
            return profilerest.get(URL + payload).then(function (response) {

                var otpdeviceList = response.data
                commit('setOtpDeviceList', otpdeviceList)
                if (typeof otpdeviceList.profileURL == 'undefined' || otpdeviceList.profileURL == null || otpdeviceList.profileURL == '') {
                    commit('setChangeURL', false)
                } else {
                    commit('setChangeURL', true)
                }


            }).catch((error) => {
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg'] ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            })
         }

        },
        sendotpToDevice({ commit, state, dispatch }, payload) {
            dispatch('resetMessages')
            var URL = ""
            let option =state.options
            if (!state.protected) {

                let iamvar_transactionIdCookie = null;
                let iamvar_selectedOtpDeviceId = null;
                if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
                iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
                }
                if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
                iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
                }

                if(iamvar_transactionIdCookie) {
                    option.headers['X-VZ-TXN-ID'] = iamvar_transactionIdCookie
                } 
                if(iamvar_selectedOtpDeviceId) {
                    option.headers['X-VZ-DEVICE-ID'] = iamvar_selectedOtpDeviceId
                }

                URL = "post-auth/profile-service/v1/send-otp"
            } else {
                URL = "pre-auth/intercept-service/v1/send-otp"
            }
            
            return profilerest.post(URL, payload, option).then(function (response) {
                if (response.status == 200) {
                  
                    commit('setShowVerifyOTP', true)
                    commit('setUuID', response.data.uuID)
                    commit('setMaskedValue', response.data.maskedDeviceValue)
                }
                if(response.headers['x-vz-success-msg']) {
                    var errorMsg = getUpdatedMessage(response.headers['x-vz-success-msg'])
                    if( (response.status === 200 || response.status === 204) && response.headers['x-vz-success-msg'] ) {
                        dispatch('login/updateUnlockMyAccountAlerts', [{type: 'success', msg: errorMsg}], { root: true })
                    }
                } else if (response.headers['x-vz-info-msg']) {
                    var errorMsg = getUpdatedMessage(response.headers['x-vz-info-msg'])
                    if( (response.status === 200 || response.status === 204) && response.headers['x-vz-info-msg'] ) {
                        dispatch('login/updateUnlockMyAccountAlerts', [{type: 'information', msg: errorMsg}], { root: true })
                    }
                } else {
                    var errorMsg = getUpdatedMessage(response.headers['x-vz-warn-msg'])
                    if( (response.status === 200 || response.status === 204) && response.headers['x-vz-warn-msg'] ) {
                        dispatch('login/updateUnlockMyAccountAlerts', [{type: 'warning', msg: errorMsg}], { root: true })
                    }
                }

            }).catch((error) => {
                if (error.response.status == 401 && !(getCookie('vbgc'))) {
                    commit('setRspSessionExpired', true)
                }
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg'] ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            }).finally(() => {
                
            })

        },
        sendVPSOtpToDevice({ commit, state, dispatch }, payload) {
            dispatch('resetMessages')
            commit('setVPSPopup', false)
            let option =state.options
            let iamvar_transactionIdCookie = null;
            let iamvar_selectedOtpDeviceId = null;
            if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
            iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
            }
            if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
            iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
            }

            if(iamvar_transactionIdCookie) {
                option.headers['X-VZ-TXN-ID'] = iamvar_transactionIdCookie
            } 
            if(iamvar_selectedOtpDeviceId) {
                option.headers['X-VZ-DEVICE-ID'] = iamvar_selectedOtpDeviceId
            }     
            return profilerest.post('post-auth/profile-service/v1/send-otp', payload, option).then(function (response) {
                if (response.status == 200) {
                    commit('setVPSPopup', true)
                }
            }).catch((error) => {
                commit('setVpsProfileAlerts', [ { msg: translations.en.message["bckend_unknown_err"], type: 'error' } ])
            })
        },
        verifyVPSOtpCode({ commit, dispatch, state }, payload) {
            dispatch('resetMessages')
            let option =state.options;
            let iamvar_transactionIdCookie = null;
                let iamvar_selectedOtpDeviceId = null;
                if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
                iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
                }
                if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
                iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
                }

                if(iamvar_transactionIdCookie) {
                    option.headers['X-VZ-TXN-ID'] = iamvar_transactionIdCookie
                } 
                if(iamvar_selectedOtpDeviceId) {
                    option.headers['X-VZ-DEVICE-ID'] = iamvar_selectedOtpDeviceId
                } 
                commit('setOtpAged',true)
            return profilerest.post('post-auth/profile-service/v1/authenticate-otp', payload, option).then(function (response) {
                if (response.status == 200) {
                    var data = response.data
                    commit('setUuID', data.uuID)
                    var otpValue = ''
                   if (payload.deliveryMethodSelected == 'email') {
                        otpValue = payload.emailAddress
                       } else if (payload.deliveryMethodSelected == 'sms' || payload.deliveryMethodSelected == 'ivr') {
                        otpValue = payload.mtn

                    }
                    payload.deliveryMethodSelected

                    let addOTPPayload = {
                        "otpDeviceList": [
                            {
                                "otp_type": payload.deliveryMethodSelected,
                                "otp_value": otpValue,
                                "country_code": payload.countryCode,
                                "otp_aged": state.otpAged,
                                "last_invalid_attempt_date": null,
                                "invalid_attempts": null,
                                "create_timestamp": null,
                                "user_guid": data.uuID,
                            }
                        ]

                    }
                    dispatch('addVPSOTPDevice', addOTPPayload)
                }
            }).catch((error) => {
                commit('setVpsProfileAlerts', [ { msg: translations.en.message["bckend_unknown_err"], type: 'error' } ])
            })
        },
        verifyOtpCode({ commit, dispatch, state }, payload) {
            dispatch('resetMessages')
         
            var URL = ""
            let option =state.options
            if (!state.protected) {

                let iamvar_transactionIdCookie = null;
                let iamvar_selectedOtpDeviceId = null;
                if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
                iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
                }
                if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
                iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
                }

                if(iamvar_transactionIdCookie) {
                    option.headers['X-VZ-TXN-ID'] = iamvar_transactionIdCookie
                } 
                if(iamvar_selectedOtpDeviceId) {
                    option.headers['X-VZ-DEVICE-ID'] = iamvar_selectedOtpDeviceId
                } 

                commit('setOtpAged',true)
                URL = "post-auth/profile-service/v1/authenticate-otp"
            } else {
               commit('setOtpAged',true)
               URL = "pre-auth/intercept-service/v1/authenticate-otp"
            }
            return profilerest.post(URL, payload, option).then(function (response) {
                if (response.status == 200) {
                  
                    var data = response.data
                    commit('setUuID', data.uuID)
                    var otpValue = ''
                   if (payload.deliveryMethodSelected == 'email') {
                        otpValue = payload.emailAddress
                       } else if (payload.deliveryMethodSelected == 'sms' || payload.deliveryMethodSelected == 'ivr') {
                        otpValue = payload.mtn

                    }
                    payload.deliveryMethodSelected

                    let addOTPPayload = {
                        "otpDeviceList": [
                            {
                                "otp_type": payload.deliveryMethodSelected,
                                "otp_value": otpValue,
                                "country_code": payload.countryCode,
                                "otp_aged": state.otpAged,
                                "last_invalid_attempt_date": null,
                                "invalid_attempts": null,
                                "create_timestamp": null,
                                "user_guid": data.uuID,
                            }
                        ]

                    }
                    dispatch('addOTPDevice', addOTPPayload)



                }


            }).catch((error) => {
                if (error.response.status == 401 && !(getCookie('vbgc'))) {
                    commit('setRspSessionExpired', true)
                }
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg']  ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            }).finally(() => {
            
            })

        },
        getSQAList({ commit }, payload) {
            let updatedPayload = {
                uuID: payload,
            }
            return profilerest.post('post-auth/profile-service/v1/get-user-sqa/', updatedPayload, { headers: { 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE', crossdomain: true, 'Allow-Control-Allow-Origin': '*' } }).then(function (response) {
                var sqa = response.data.sqaTO[0].message

                commit('setSqa', sqa)

            }).catch((error) => {

            })

        },
        addOTPDevice({ commit, dispatch, state }, payload) {
            dispatch('resetMessages')
           
          
            var URL = ""
            let option =state.options
            if (!state.protected) {

                let iamvar_transactionIdCookie = null;
                let iamvar_selectedOtpDeviceId = null;
                if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
                iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
                }
                if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
                iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
                }

                if(iamvar_transactionIdCookie) {
                    option.headers['X-VZ-TXN-ID'] = iamvar_transactionIdCookie
                } 
                if(iamvar_selectedOtpDeviceId) {
                    option.headers['X-VZ-DEVICE-ID'] = iamvar_selectedOtpDeviceId
                } 

                URL = "post-auth/profile-service/v1/add-otp-device"
            } else {

                URL = "pre-auth/intercept-service/v1/add-otp-device"
            }
            
            return profilerest.post(URL, payload, option).then(function (response) {
                if (response.status == 200) {
                    commit('setShowVerifyOTP', false)
                    if (!state.protected) {
                        let msg = '{"actionType": "metricsUpdate", "txnName": "changeOTP", "status": "SUCCESS", "desc": ""}';
                        if (window.webkit != undefined) { // iOS
                            if (window.webkit.messageHandlers.mfAppInterface != undefined) {
                            window.webkit.messageHandlers.mfAppInterface.postMessage(msg);
                            }
                        }
                        if (window.mfAppInterface != undefined) { // Android
                            window.mfAppInterface.postMessage(msg);
                        }
                        router.push(  { path: 'overview', query : { target:state.logOutGotoUrl}});
                        
                    } else {
                        if(enhanchedResetSecurityProfile){
                            dispatch('updateGotoOtp', false);
                            dispatch('updateGotoOtpOvw', true);
                            //dispatch('updateGotoComponent', 'resetSecurityProfileOTPSummary')
                        }else {
                            router.push({path: 'otpsummary'})
                        }

                    }
                    
                    
                    dispatch('getOtpDeviceList', state.uuID)

                }
                if(response.headers['x-vz-success-msg']) {
                    var errorMsg = getUpdatedMessage(response.headers['x-vz-success-msg'])
                    if( (response.status === 200 || response.status === 204) && response.headers['x-vz-success-msg'] ) {
                        dispatch('login/updateUnlockMyAccountAlerts', [{type: 'success', msg: errorMsg}], { root: true })
                    }
                } else if (response.headers['x-vz-info-msg']) {
                    var errorMsg = getUpdatedMessage(response.headers['x-vz-info-msg'])
                    if( (response.status === 200 || response.status === 204) && response.headers['x-vz-info-msg'] ) {
                        dispatch('login/updateUnlockMyAccountAlerts', [{type: 'information', msg: errorMsg}], { root: true })
                    }
                } else {
                    var errorMsg = getUpdatedMessage(response.headers['x-vz-warn-msg'])
                    if( (response.status === 200 || response.status === 204) && response.headers['x-vz-warn-msg'] ) {
                        dispatch('login/updateUnlockMyAccountAlerts', [{type: 'warning', msg: errorMsg}], { root: true })
                    }
                }

            }).catch((error) => {
                if (error.response.status == 401 && !(getCookie('vbgc'))) {
                    commit('setRspSessionExpired', true)
                }
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg'] ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            }).finally(() => {
               
            })

        },
        addVPSOTPDevice({ commit, dispatch, state }, payload) {
            dispatch('resetMessages')
            let option =state.options;
            let iamvar_transactionIdCookie = null;
                let iamvar_selectedOtpDeviceId = null;
                if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
                iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
                }
                if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
                iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
                }

                if(iamvar_transactionIdCookie) {
                    option.headers['X-VZ-TXN-ID'] = iamvar_transactionIdCookie
                } 
                if(iamvar_selectedOtpDeviceId) {
                    option.headers['X-VZ-DEVICE-ID'] = iamvar_selectedOtpDeviceId
                } 
            return profilerest.post('post-auth/profile-service/v1/add-otp-device', payload, option).then(function (response) {
                if (response.status == 200) {
                    routerLogin.push({name: 'VPSSecurityProfilePage'})
                }
            }).catch((error) => {
            })
        },

        deleteOTPDevice({ commit, dispatch, state }, payload) {
            dispatch('resetMessages')
           
            var URL = ""
            let option =state.options
            if (!state.protected) {

                let iamvar_transactionIdCookie = null;
                let iamvar_selectedOtpDeviceId = null;
                if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
                iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
                }
                if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
                iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
                }

                if(iamvar_transactionIdCookie) {
                    option.headers['X-VZ-TXN-ID'] = iamvar_transactionIdCookie
                } 
                if(iamvar_selectedOtpDeviceId) {
                    option.headers['X-VZ-DEVICE-ID'] = iamvar_selectedOtpDeviceId
                } 

                URL = "post-auth/profile-service/v1/delete-otp-device"

            } else {
                URL = "pre-auth/intercept-service/v1/delete-otp-device"
            }
            
            return profilerest.post(URL, payload, option).then(function (response) {
                if (response.status == 200) {
               
                    var otpdeviceList = response.data
                    commit('setOtpDeviceList', otpdeviceList)
                    commit('setGotoDevSumry', true)

                }


            }).catch((error) => {
                if (error.response.status == 401 && !(getCookie('vbgc'))) {
                    commit('setRspSessionExpired', true)
                }
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg']  ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            }).finally(() => {
              
            })

        },
        modifySQA({ commit, state, dispatch }, payload) {
            commit('setGotoProfileSettings', false)
            dispatch('resetMessages')
         
            var URL = ""
            let option =state.options
            if (!state.protected) {

                let iamvar_transactionIdCookie = null;
                let iamvar_selectedOtpDeviceId = null;
                if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
                    iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
                }
                if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
                    iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
                }

                if(iamvar_transactionIdCookie) {
                    option.headers['X-VZ-TXN-ID'] = iamvar_transactionIdCookie
                } 
                if(iamvar_selectedOtpDeviceId) {
                    option.headers['X-VZ-DEVICE-ID'] = iamvar_selectedOtpDeviceId
                } 

                URL = "post-auth/profile-service/v1/modify-sqa"

            } else {
                URL = "pre-auth/intercept-service/v1/set-sqa"
            }
            
            return profilerest.post(URL, payload, option).then(function (response) {
                if (response.status == 200) {
                    if (!state.protected) {    
                        let msg = '{"actionType": "metricsUpdate", "txnName": "changeSQA", "status": "SUCCESS", "desc": ""}';
                        if (window.webkit != undefined) { // iOS
                            if (window.webkit.messageHandlers.mfAppInterface != undefined) {
                            window.webkit.messageHandlers.mfAppInterface.postMessage(msg);
                            }
                        }
                        if (window.mfAppInterface != undefined) { // Android
                            window.mfAppInterface.postMessage(msg);
                        }
                    }
                }        
                commit('setGotoProfileSettings', true)

            }).catch((error) => {
                if (error.response.status == 401 && !(getCookie('vbgc'))) {
                    commit('setRspSessionExpired', true)
                }
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg']  ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            }).finally(() => {
                
            })

        },

        modifyPassword({ commit, dispatch, state }, payload) {
            commit('setGotoProfileSettings', false)
            dispatch('resetMessages')

            let option =state.options

            let iamvar_transactionIdCookie = null;
            let iamvar_selectedOtpDeviceId = null;
            if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
            iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
            }
            if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
            iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
            }

            if(iamvar_transactionIdCookie) {
                option.headers['X-VZ-TXN-ID'] = iamvar_transactionIdCookie
            } 
            if(iamvar_selectedOtpDeviceId) {
                option.headers['X-VZ-DEVICE-ID'] = iamvar_selectedOtpDeviceId
            } 
          
            return profilerest.post('post-auth/profile-service/v1/modify-password/', payload, { headers: { 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE', crossdomain: true, 'Allow-Control-Allow-Origin': '*', 'X-VZ-TXN-ID': iamvar_transactionIdCookie, 'X-VZ-DEVICE-ID': iamvar_selectedOtpDeviceId} }).then(function (response) {
            if (!payload.currentPassword) {     
                if (response.status == 200) {
                    if (!state.protected) {
                        let msg = '{"actionType": "metricsUpdate", "txnName": "changePassword", "status": "SUCCESS", "desc": ""}';
                        if (window.webkit != undefined) { // iOS
                            if (window.webkit.messageHandlers.mfAppInterface != undefined) {
                            window.webkit.messageHandlers.mfAppInterface.postMessage(msg);
                            }
                        }
                        if (window.mfAppInterface != undefined) { // Android
                            window.mfAppInterface.postMessage(msg);
                        }
                    }
                }
                commit('setGotoProfileSettings', true)
            } else {
                routerLogin.push({name: 'VPSSecurityProfilePage'})
            }
            }).catch((error) => {
                commit('setShowErrorMsg', true)
            }).finally(() => {
              
            })

        },
        modifyUser({ commit, dispatch, state }, payload) {
            return profilerest.post('post-auth/profile-service/v1/modify-user', payload).then(function (response) {
                routerLogin.push({name: 'VPSSecurityProfilePage'})
            }).catch((error) => {
                commit('setShowErrorMsg', true)
            }) 
        },
        updatePassword({ commit, state, dispatch }, payload) {
            dispatch('resetMessages')
           
            
            return profilerest.post('pre-auth/intercept-service/v1/reset-password', payload).then(function (response) {
                if (response.status == 200) {
                
                    var profile = response.data
                    commit('setUuID', profile.uuID)

                    if (profile.flow.includes('SQA_REG')) {
                        commit('setGotoSQA', true)
                        if (profile.flow.includes('TC')) {

                            commit('setEnableTC', true)
                        }
                    }

                    else if (profile.flow.includes('SQA_MIG')) {
                        commit('setGotoSQA', true)
                        commit('setMIGContent', true)
                    }
                    else if (profile.flow.includes('OTP_OVW')) {
                        dispatch('getOtpDeviceList', profile.uuID)
                        commit('setGotoOtpOvw', true)

                    }
                    else if (profile.flow.includes('OTP') && !profile.flow.includes('SQA_REG')
                        && !profile.flow.includes('SQA_MIG')) {

                        commit('setGotoOtp', true)

                    } 
                    else if (profile.flow === 'RESET_PW') {
                        let payload = {
                            "uuID": state.uuID
                        }
                        dispatch(callRedirectURL, payload)
                    }
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'success', msg: translations.en.message["rsp_pwd_success"]}], { root: true })
                }


            }).catch((error) => {
                if (error.response.status == 401 && !(getCookie('vbgc'))) {
                    commit('setRspSessionExpired', true)
                }
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( error.response.status == 400 &&  errorMsg.includes("The password you've selected has been recently used.")) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error',  msg:translations.en.message["rsp_pd_reset_error"]}], { root: true })
                } else if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg'] ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            }).finally(() => {
               
            })

        },
        saveSQA({ commit, state, dispatch }, payload) {
            dispatch('resetMessages')
            var URL = ""
            
            if (!state.protected) {
                URL = "post-auth/profile-service/v1/modify-sqa"
                commit('setChangeURL', false)
            } else {
                URL = "pre-auth/intercept-service/v1/set-sqa"
                commit('setChangeURL', true)
            }
           
            
            return profilerest.post(URL, payload).then(function (response) {
                var profile = response.data
                if (profile.flow.includes('OTP_OVW')) {
                    dispatch('getOtpDeviceList', payload.uuID)
                    commit('setGotoOtpOvw', true)

                } else if (profile.flow.includes('OTP')) {
                    commit('setGotoOtp', true)

                } else {
                    let payload = {
                        "uuID": state.uuID
                    }
                    dispatch(callRedirectURL, payload)

                }
                dispatch('login/updateUnlockMyAccountAlerts', [{type: 'success', msg: translations.en.message["rsp_sqa_success"]}], { root: true })
            }).catch((error) => {
                if (error.response.status == 401 && !(getCookie('vbgc'))) {
                    commit('setRspSessionExpired', true)
                }
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg']  ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            }).finally(() => {
               
            })

        },
        CallcreateNewSession({commit, state}){
            if(iamvar_polarisFlowToggle && state.sdarUserType) {
                //sending usertype back to AM when creating the session
                createNewSession({type: 'flowtype', value: state.sdarUserType});
            } else {
                createNewSession()
            }

        },
        updateTargetUrl({commit,state},payload){
            if(typeof payload !== 'undefined' && payload !="null" && !! payload &&  payload!="" && payload.length!=0){
                if (payload.includes("enterpriseportal")) {
                 if(!payload.includes("#/account/myProfile")) {
                    commit('setLogOutGotoUrl',payload+"#/account/myProfile")
                 } else {
                    commit('setLogOutGotoUrl',payload)
                 } 
                } else {
                    //if(!payload.includes("#/ebilling/my-profile")) {
                    //    commit('setLogOutGotoUrl',payload+"#/ebilling/my-profile")
                    // } else {
                        commit('setLogOutGotoUrl',payload)
                    //}   
                } 
              }else{
                if(state.userType.includes('vzw')){
                    commit('setLogOutGotoUrl',iamvar_myBizDefaultGotoUrl)

                  } else{
                    commit('setLogOutGotoUrl',iamvar_vecDefaultGotoUrl)
                  }
                if(state.logOutGotoUrl){
                    commit('setLogOutGotoUrl', state.logOutGotoUrl)
                }
            
            }
            commit('setProfileRedirectUrl',state.logOutGotoUrl)
        },

        callRedirectURL({ commit, dispatch }, payload) {
            dispatch('resetMessages')
            
            
            return profilerest.post('pre-auth/intercept-service/v1/complete-secure-registration', payload).then(function (response) {
                if (response.status == 200) {
                    var ott= response.headers[iamvar_vbgcCookieName]
                    setOTTInClientLibForCreatingSession(ott)
                    commit('setShowProcessLoader',true) 
                }
            }).catch((error) => {
                if (error.response.status == 401 && !(getCookie('vbgc'))) {
                    commit('setRspSessionExpired', true)
                }
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg'] ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            }).finally(() => {
               
            })

        },
        getSecretQuestions({ commit, state }) {
            var URL ="pre-auth/util-service/v1/get-secret-questions"
            return profilerest.get(URL).then(function (response) {
                commit('setSecretQuestions', response.data.secretQuestions)
            }).catch(function (error) {

                // throw error
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg'] ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            })
        },
        getCountryCodes({ commit, state }) {
          
            var URL = "pre-auth/util-service/v1/get-country-codes"
            return profilerest.get(URL).then(function (response) {
               
                commit('setCountryCodes', response.data.countryCodes)
            }).catch(function (error) {

                // throw error
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg'] ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            })
        },
        getUserProfile({ commit, dispatch, state },payload) {
            if(payload) {
                commit('setProfileRedirectUrl', payload)
            } 
            return profilerest.get('post-auth/profile-service/v1/get-user').then(function (response) {
                var profile = response.data
                console.log("profile", profile)
                commit('setUserName', profile.userName)
                commit('setEmailAddress', profile.emailAddress)
                commit('setFirstName', profile.firstName)
                commit('setLastName', profile.lastName)
                commit('setLoginLastTime', profile.lastLoginDt)
                commit('setUuID', profile.uuID)
                commit('setUserType', profile.userType)
                if(!payload.isVPSFlow){
                  dispatch('getOtpDeviceList', profile.uuID)
                }
                dispatch('getSQAList', profile.uuID)
            }).catch((error) => {
                commit('setVpsProfileAlerts', [ { msg: translations.en.message["bckend_unknown_err"], type: 'error' } ])
            }).finally(() => {
                
            })

        },
        resetMessages({ commit }) {
            commit('setErrorMsg', '')
            commit('setShowErrorMsg', false)
            commit('setInfoMsg', '')
            commit('setShowInfoMsg', false)
            commit('setSuccessMsg', '')
            commit('setShowSuccessMsg', false)
            commit('setWarnMsg', '')
            commit('setShowWarnMsg', false)
         
        },
        updateGotoUrl ({commit, dispatch}, payload) {
            commit('setGotoUrl', payload)
        },
        getPassswordBlackList({ commit, state }) {
            var URL = "pre-auth/util-service/v1/get-password-block-list"
            return profilerest.get(URL).then(function (response) {
              
                commit('setPasswordBlackListC', response.data.passwordBlockList.passwordBlackListC)
                commit('setPasswordBlackListE', response.data.passwordBlockList.passwordBlackListE)
            }).catch(function (error) {

                // throw error
                var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg'] ) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                }
            })

        },

        isTxnValid({ commit}, payload) {
            commit('setGotoProfileSettings', false)
            if(payload) {
                commit('setProfileRedirectUrl', payload)
            } 

            let iamvar_transactionIdCookie = null;
            let iamvar_selectedOtpDeviceId = null;
            if(document.cookie.indexOf('iamvar_transactionID') !== -1) {
                iamvar_transactionIdCookie = getCookie('iamvar_transactionID')
            }
            if(document.cookie.indexOf('iamvar_selectedOtpDeviceID') !== -1) {
                iamvar_selectedOtpDeviceId = getCookie('iamvar_selectedOtpDeviceID')
            }

            if(iamvar_transactionIdCookie || iamvar_selectedOtpDeviceId) {
                return profilerest.get('post-auth/profile-service/v1/is-txn-valid', { headers: { 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE', crossdomain: true, 'Allow-Control-Allow-Origin': '*', 'X-VZ-TXN-ID': iamvar_transactionIdCookie, 'X-VZ-DEVICE-ID': iamvar_selectedOtpDeviceId} }).then(function (response) {
                    
                }).catch((error) => {
                    if (error.status == 401)
                    {
                        commit('setGotoProfileSettings', true)
                        commit('setLoader', false)
                        if (error.response.headers[vbgErrorMessage]) {
                            commit('setErrorMsg', error.response.headers[vbgErrorMessage])
                            commit('setShowErrorMsg', true)
                        }
                    }
                }).finally(() => {
                    
                })
            }
            else {
                return profilerest.get('post-auth/profile-service/v1/is-txn-valid').then(function (response) {
                    
                }).catch((error) => {
                    if (error.status == 401)
                    {
                        commit('setGotoProfileSettings', true)
                        commit('setLoader', false)
                        if (error.response.headers[vbgErrorMessage]) {
                            commit('setErrorMsg', error.response.headers[vbgErrorMessage])
                            commit('setShowErrorMsg', true)
                        }
                    }
                }).finally(() => {
                    
                })
            }
        },

        containsBlackListPassword: function ({ state, commit }, payload) {

            var blackListArrayE = '';
            var blackListArrayC = '';
            if (typeof state.passwordBlackListE != 'undefined' && state.passwordBlackListE != null
                && state.passwordBlackListE != "") {
                var str = state.passwordBlackListE
                if (!str) {
                    return;
                }
                if (typeof atob != 'undefined') {
                    blackListArrayE = window.atob(str).split('|');
                } else { // IE8, IE9
                    var decodedBytes = dojox.base64.decode(str);
                    blackListArrayE = String.fromCharCode.apply(String, decodedBytes).split('|')
                }



            }
            if (typeof state.passwordBlackListC != 'undefined' && state.passwordBlackListC != null
                && state.passwordBlackListC != "") {

                var str1 = state.passwordBlackListC
                if (!str1) {
                    return;
                }
                if (typeof atob != 'undefined') {
                    blackListArrayC = window.atob(str1).split('|');
                } else { // IE8, IE9
                    var decodedBytes = dojox.base64.decode(str1);
                    blackListArrayC = String.fromCharCode.apply(String, decodedBytes).split('|')
                }

            }
            var index = -1;
            var success1 = false;

            if (blackListArrayE != null && blackListArrayE != "") {
                index = $.inArray(payload.toLowerCase(), blackListArrayE);
                success1 = index != -1


            }
            if (blackListArrayC != null && blackListArrayC != "") {
                $.each(blackListArrayC, function (index1, value1) {
                    if (!success1) {
                        success1 = payload.toLowerCase().indexOf(value1.toLowerCase()) != -1;
                    } else {

                        success1 = true;
                    }

                });

            }
            commit('setSuccess', success1)

        },
        resetShowVerifyOtp({ commit },payload){
            commit("setShowVerifyOTP", payload);
        },
        updateGotoComponent({ commit }, payload) {
            commit("setGotoComponent", payload);
        },
        updatedStepsContent({ state, commit }, payload) {
            const updatedState = state.stepsContent.map(step => {
                return { 
                    ...step, 
                    active: step.id === payload? true: false, 
                    disabled: step.id <= payload? false: true
                };
            })
            
            commit('setStepsContent', updatedState);
        },
        updateGotoOtpOvw({ commit }, payload) {
            commit('setGotoOtpOvw', payload);
        },
        updateGotoOtp({ commit }, payload) {
            commit('setGotoOtp', payload);
        },
        loggSequences({ state }, newPayload) {
            let option = state.options
            return playrest.post(iamvar_recoveryServ+'/logSequenceError', newPayload, option).then(function (response) {

            }).catch(()=>{

            })
        },
        updateRspSessionExpired({ commit }, payload) {
            commit('setRspSessionExpired', payload)
        },
        updateVcgSecretQuestions({ commit}) {
            var URL ="pre-auth/util-service/v1/get-secret-questions/vcg-guest-user"
            return profilerest.get(URL).then(function (response) {
                commit('setVcgSecretQuestions', response.data.questions)
            }).catch(function () {
                // throw error
            })
        },
        updateVcgSecurityProfile({ dispatch, commit }, payload) {
            commit('setLoader', true)
            var URL ="pre-auth/intercept-service/guest/v1/updateProfile"
            return profilerest.post(URL,payload).then(function (response) {
                dispatch('CallcreateNewSession')
                // commit('setLoader', false)
            }).catch(function () {
                commit('setLoader', false)
                // throw error
            })
        },
        requestProfileSecurityPin({ dispatch, commit }, payload) {
            commit('setLoader', true)
            var URL ="pre-auth/intercept-service/v1/send-otp"
            return profilerest.post(URL,payload).then(function (response) {
                // dispatch('CallcreateNewSession')
                dispatch('login/updateVcgProfileView',"resetCsqaVerifyPin", { root: true })
                router.push({ name: "VcgProfileUpdateRootComponent" });
                commit('setLoader', false)
            }).catch((error) => {
                commit('setLoader', false)
                if (error.response.status == 401 && !(getCookie('vbgc'))) {
                    // router.push({ path: "/login/sessionexpiredpage" })
                    commit('setVbmSessionExpired', true)
                }
            }).finally(() => {
            })
        },
        verifyProfileSecurityPin({ dispatch, state, commit }, payload) {
            commit('setLoader', true)
            var URL ="pre-auth/intercept-service/v1/authenticate-otp"
            return profilerest.post(URL,payload).then(function (response) {
                let requestPayload = {
                    requestType: "INCOMPLETE_PROFILE",
                    sqaID: state.challengeQuestion,
                    sqaPwd: state.secretAnswer,
                    secProfileMTN: payload.mtn
                }
                 dispatch('updateVcgSecurityProfile',requestPayload)
                commit('setLoader', false)
            }).catch(function (error) {
                // throw error
                if(error.response.status == 401 || error.response.status == 400) {
                    dispatch('login/updateUnlockMyAccountAlerts', [{ type: 'error', msg: translations.en.message['vcg_vbg_incomplete_profile_invalid_pin'] }], { root: true })
                }
                commit('setLoader', false)
            })
        },
        updateChallengeQuestion({commit}, payload) {
            commit("setChallengeQuestion", payload)
        },
        updateSecretAnswer({commit}, payload) {
            commit("setSecretAnswer", payload)
        },
        updateSdarUserType({ commit }, payload) {
            commit('setSdarUserType', payload)
        },
    },
    getters: {
        gotoSQA: state => state.gotoSQA,
        gotoOtp: state => state.gotoOtp,
        enableTC: state => state.enableTC,
        uuID: state => state.uuID,
        emailAddress: state => state.emailAddress,
        firstName: state => state.firstName,
        lastName: state => state.lastName,
        userStatus: state => state.userStatus,
        loginLastTime: state => state.loginLastTime,
        userName: state => state.userName,
        challengeQuestion: state => state.challengeQuestion,
        challengeAnswer: state => state.challengeAnswer,
        addOtp: state => state.addOtp,
        showVerifyOTP: state => state.showVerifyOTP,
        showVPSPopup: state => state.showVPSPopup,
        otpDeviceList: state => state.otpDeviceList,
        gotoDevSumry: state => state.gotoDevSumry,
        status: state => state.status,
        deliveryMethodSelected: state => state.deliveryMethodSelected,
        emailAddress: state => state.emailAddress,
        errorMsg: state => state.errorMsg,
        migContent: state => state.migContent,
        showerrorMsg: state => state.showerrorMsg,
        showsuccessMsg: state => state.showsuccessMsg,
        successMsg: state => state.successMsg,
        gotoOtpOvw: state => state.gotoOtpOvw,
        gotoPassSet: state => state.gotoPassSet,
        infoMsg: state => state.infoMsg,
        showinfoMsg: state => state.showinfoMsg,
        redirectUrl: state => state.redirectUrl,
        submitLoader: state => state.submitLoader,
        secretQuestions: state => state.secretQuestions,
        countryCodes: state => state.countryCodes,
        sqa: state => state.sqa,
        gotoProfileSettings: state => state.gotoProfileSettings,
        changeURL: state => state.changeURL,
        protected: state => state.protected,
        passwordBlackListC: state => state.passwordBlackListC,
        passwordBlackListE: state => state.passwordBlackListE,
        success: state => state.success,
        profileRedirectUrl: state => state.profileRedirectUrl,
        otpAged:state=>state.otpAged,
        pwdReq: state => state.pwdReq,
        pwdReqMatch: state => state.pwdReqMatch,
        noOfPwdReq: state => state.noOfPwdReq,
        formInputs: state => state.formInputs,
        passwordFlowConfig: state => state.passwordFlowConfig,
        gotoUrl: state => state.gotoUrl,
        maskedValue: state => state.maskedValue,
        attributedUpdated:state=>state.attributedUpdated,
        stepupVerified:state=>state.stepupVerified,
        logOutGotoUrl:state=>state.logOutGotoUrl,
        targetUrl:state=>state.targetUrl,
        showProcessLoader:state=>state.showProcessLoader,
        userType:state=>state.userType,
        gotoComponent: state=> state.gotoComponent,
        stepsContent: state => state.stepsContent,
        rspSessionExpired: state => state.rspSessionExpired,
        vbmSessionExpired: state =>state.vbmSessionExpired,
        sdarUserType: state => state.sdarUserType,    
        getVcgSecretQuestions: state => state.vcgSecretQuestions,
        getSecretAnswer: state => state.secretAnswer,
        vpsProfileAlerts: (state) => state.vpsProfileAlerts,
    }
}
