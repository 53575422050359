<template>
  <div class="vbg-ff-root-container">
    <Header v-if="this.whichRealm"></Header>
    <div class="vbg-ff-content-container">
    <div v-if="getUnlockMyAccountAlerts.length > 0 && !tokenExpired && !rspSessionExpired">
      <div class="row" v-for="j in getUnlockMyAccountAlerts" :key="j">
        <div class="col-sm-12">
          <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
            <div class="vbg-ff-msg-container">
              <div class="messageWrapper" style="display: flex">
                <div
                  class="messageIcon"
                  style="padding: 0px; margin-right: 12px"
                >
                  <div class="messageImage" :class="messageImage(j)"></div>
                </div>
                <p
                  class="messageContentUn col-xs-11"
                  v-if="j.type == 'list'"
                  v-html="j.msg"
                  style="padding: 0px; margin: 0px"
                >
                  {{ j.msg }}
                </p>
                <p
                  class="messageContentUn col-xs-11"
                  v-else-if="j.type == 'error' || j.type == 'warning'"
                  style="padding: 0px; margin: 0px"
                  v-html="j.msg"
                >
                  {{ j.msg }}
                </p>
                <p
                  class="messageContentUn col-xs-11"
                  v-else
                  style="padding: 0px; margin: 0px; color: #ffffff"
                  v-html="j.msg"
                >
                  {{ j.msg }}
                </p>
                <div
                  class="closeMessage col-xs-1"
                  style="padding: 0px; margin-left: 16px; display: grid;"
                >
                  <img
                    class="closeMessageImg close-icon-img"
                    v-if="
                      j.type == 'list' ||
                      j.type == 'error' ||
                      j.type == 'warning'
                    "
                    src="@/common/assets/styles/corp/images/close_black2.png"
                    @click="deleteAlert(j)"
                  />
                  <img
                    class="closeMessageImg close-icon-img"
                    v-else
                    src="@/common/assets/styles/corp/images/close.png"
                    @click="deleteAlert(j)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="vbg-rsp-root-container" v-if="!tokenExpired && !rspSessionExpired">
        <ResetSecurityProfileSteps></ResetSecurityProfileSteps>
        <ValidateUserIdComponent
          v-if="(gotoComponent === 'validateUserIdComponent' || gotoComponent === 'resetSecurityProfileNewPwd')"
          :tokenVal="tokenValue"
        ></ValidateUserIdComponent>
        <ResetSecurityProfileSqaComponent v-else-if="gotoComponent === 'resetSecurityProfileSqaComponent'"></ResetSecurityProfileSqaComponent>
        <ResetSecurityProfileOTPSummary v-else-if="gotoComponent === 'resetSecurityProfileOTPSummary'"></ResetSecurityProfileOTPSummary>
        <ResetSecurityProfileAddOtpComponent v-else-if="gotoComponent === 'resetSecurityProfileAddOtpComponent'"></ResetSecurityProfileAddOtpComponent>
      </div>
      <ErrorMessage
        :title="title"
        :description="des"
        v-if="tokenExpired || rspSessionExpired"
      ></ErrorMessage>
    </div>
    <Footer v-if="this.whichRealm"></Footer>
  </div>
</template>
<script>
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import Header from "@/vbgauth/components/LegalHeader";
import { mapGetters, mapActions } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import ErrorMessage from "@/common/components/ErrorMessage";
import ValidateUserIdComponent from "@/vbgauth/components/unlockMyAccount/ValidateUserIdComponent";
import ResetSecurityProfileSteps from "@/vbgauth/components/unlockMyAccount/ResetSecurityProfileSteps";
import ResetSecurityProfileSqaComponent from "@/vbgauth/components/unlockMyAccount/ResetSecurityProfileSqaComponent"
import translations from "@/common/assets/json/messages1.json";
import ResetSecurityProfileOTPSummary from "@/vbgauth/components/unlockMyAccount/ResetSecurityProfileOTPSummary";
import ResetSecurityProfileAddOtpComponent from "@/vbgauth/components/unlockMyAccount/ResetSecurityProfileAddOtpComponent"
import { setPageDetails, errorSet } from "../../../../public/adobeTagging";

export default {
  name: "ResetSecurityProfileRootComponent",
  components: {
    Header,
    Footer,
    ResetSecurityProfileSteps,
    ValidateUserIdComponent,
    ErrorMessage,
    ResetSecurityProfileSqaComponent,
    ResetSecurityProfileOTPSummary,
    ResetSecurityProfileAddOtpComponent
  },
  computed: {
    ...mapGetters("login", ["tokenExpired", "getUnlockMyAccountAlerts", "pwdUsername"]),
    ...mapGetters("profile", [
      "gotoSQA",
      "gotoOtp",
      "gotoOtpOvw",
      "gotoPassSet",
      "submitLoader",
      "gotoComponent",
      "rspSessionExpired",
      "sdarUserType",
    ]),
    title() {
      if (this.tokenExpired || this.rspSessionExpired) {
        gnavdl = {
          bu: "smb",
          appid: "unified",
        };
        return this.tokenExpired ? translations.en.message["enhance_security_link_expired_title"] : translations.en.message['enhance_security_session_expired_title'];
      } else {
        gnavdl = {
          bu: "smb",
          appid: "mybusinesslimited",
        };
      }
      return "";
    },
    des() {
      if (this.tokenExpired || this.rspSessionExpired) {
        return this.tokenExpired ? translations.en.message[
          "enhance_security_link_expired_description"
        ] : translations.en.message['enhance_security_session_expired_description'];
      }
      return "";
    },
  },
  data() {
    return {
      whichRealm: true,
      tokenValue: null,
      sdarStatus:null
    };
  },
  mixins: [commonMixin],
  mounted() {
    this.updateUrlOnMount(window.location.href);
    if (document.cookie.indexOf("iamvar_realm") !== -1) {
      let iamvar_realmCookie = getCookie("iamvar_realm");
      if (iamvar_realmCookie == "MOBILE") {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }
    const userToken = this.getParameterByName("token")
    if (userToken) {
      let queryParamToken = atob(
        decodeURIComponent(userToken)
      );

      const params = queryParamToken.split('.');

      this.tokenValue = params[0];
      let epochTime = params[1];
      // checking if user is coming from SMS
      if(params[2] && params[2] === 'PEND_SDAR') {
        this.updateSdarUserType(params[2])
      }
      let currentEpochTime = Math.round(new Date().getTime() / 1000);
      this.tokenValue ? this.updateResetSecurityProfile(true) : this.updateResetSecurityProfile(false)

      if (currentEpochTime >= epochTime) {
        //check for sms flow
        if(iamvar_polarisFlowToggle && this.sdarUserType === 'PEND_SDAR') {
          // redirect SMS user to unified login flow
          this.$router.push({ path: "/login/unifiedlogin" })
        } else {
          this.updateSetTokenExpired(true);
        }
      } else {
        this.updateSetTokenExpired(false);
      }
    } else {
      if (iamvar_polarisFlowToggle && 
        (this.sdarUserType === 'PEND_SDAR_1' || this.sdarUserType === 'PEND_SDAR_2')
      ){
        this.displayLoginIntercept();
        this.updatedStepsContent(1)
        this.updateGotoComponent('resetSecurityProfileNewPwd')
      }
    }
    if (this.getParameterByName("goto")) {
      this.$store.dispatch(
        "profile/updateGotoUrl",
        this.getParameterByName("goto")
      );
      setGotoUrlInClientLib(this.getParameterByName("goto"));
    }
  },
  methods: {
    ...mapActions("login", ["updateSetTokenExpired", "updateUrlOnMount", "updateResetSecurityProfile"]),
    ...mapActions("profile", [
      "updateGotoComponent", 
      "updatedStepsContent", 
      "updateSdarUserType",
      "displayLoginIntercept"
    ]),
    messageImage(index) {
      if (
        index.type == "error" ||
        index.type == "warning" ||
        index.type == "list"
      ) {
        return "newErrorMessageImage";
      } else {
        return index.type + "MessageImage";
      }
    },
    getAlertType(index) {
      errorSet(index.msg);
      window.scrollTo(0, 0);
      return index.type;
    },
    deleteAlert(alert) {
      this.getUnlockMyAccountAlerts.splice(
        this.getUnlockMyAccountAlerts.indexOf(alert),
        1
      );
    },
    getTypeOfAlert (index) {
          return index.type
    },
  },
  watch: {
    gotoPassSet: function (newVal) {
      if (newVal) {
      //  this.$router.push({ path: "resetpassword" });
      this.updatedStepsContent(1)
      this.updateGotoComponent('resetSecurityProfileNewPwd')

      }
    },
    gotoSQA: function (newVal) {
      if (newVal) {
       // this.$router.push({ path: "setsqa" });
       this.updatedStepsContent(2)
       this.updateGotoComponent('resetSecurityProfileSqaComponent')
      }
    },
    gotoOtpOvw: function (newVal) {
      if (newVal) {
        this.updatedStepsContent(3)
        this.updateGotoComponent('resetSecurityProfileOTPSummary');
      }
    },
    gotoOtp: function (newVal) {
      if (newVal) {
        this.updatedStepsContent(3)
        this.updateGotoComponent('resetSecurityProfileAddOtpComponent')
      }
    },
  },
};
</script>
<style scoped>
.close-icon-img {
  width: 13px;
  height: auto;
  position: relative;
  z-index: 9999;
  cursor: pointer !important;
}
.messageImage {
  width: 18px !important;
  height: 18px !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.vbg-ff-msg-container {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
</style>