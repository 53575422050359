<template>
    <div>
        <vpsHeader></vpsHeader>
        <div v-if="vpsProfileAlerts.length > 0">
            <div class="row" v-for="j in vpsProfileAlerts" :key="j">
                <div class="col-sm-12">
                    <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
                        <div class="vbg-ff-msg-container">
                            <div class="messageWrapper" style="display: flex;">
                                <div class="messageIcon" style="padding:0px;margin-right: 12px;">
                                    <div class="messageImage" :class="messageImage(j)"></div>
                                </div>
                                <p class="messageContentUn col-xs-11" v-if="j.type == 'list'" v-html="j.msg"
                                    style="padding:0px;margin:0px">{{j.msg}}</p>
                                <p class="messageContentUn col-xs-11"
                                    v-else-if="j.type == 'error' || j.type == 'warning'" style="padding:0px;margin:0px"
                                    v-html="j.msg">{{j.msg}}</p>
                                <p class="messageContentUn col-xs-11" v-else
                                    style="padding:0px;margin:0px;color:#ffffff" v-html="j.msg">{{j.msg}}</p>
                                <div class="closeMessage col-xs-1" style="padding:0px;margin-left:auto">
                                    <img class="closeMessageImg"
                                        v-if="j.type == 'list' || j.type == 'error' || j.type == 'warning'"
                                        src="@/common/assets/styles/corp/images/close_black.png"
                                        @click="deleteAlert(j)" />
                                    <img class="closeMessageImg" v-else
                                        src="@/common/assets/styles/corp/images/clear_white.png"
                                        @click="deleteAlert(j)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vps-container center_margin mainContainer">
            <div class="paddingMobile">
                <div class="myprofileContainer">
                    <form id="vpsSecurityProfileUsernameEdit" class="signin" @submit.prevent="submitForm">
                        <div class="headUserId">
                            <h2 class="descEdit myprofileHead">Edit email address</h2>
                        </div>
                        <div class="form-group">
                            <span class="labelText">Email address</span>
                            <input id="emailIdInput" class="input-f input_mr input_border"
                                :class="isEmailValid ? 'vps-input-field-warning' : ''" autofocus type="text" required
                                v-model="secProfileEmail" maxlength="60" />
                            <div v-if="isEmailValid">
                                <p class="vps-input-errortext-style"
                                    v-html="$t('message.vps_edit_profile_invalid_email')">
                                </p>
                            </div>
                            <p class="rules">We'll send you a security code to verify this email address.</p>
                        </div>
                        <div class="buttonset">
                            <button type="submit"
                                class="btn  vbg-ff-btn-continue modal-popup-btn vbg-ff-btn-width140 spacing05">
                                <span>Update</span>
                            </button>
                            <button class="btn vbg-ff-btn-cancel modal-popup-btn vbg-ff-btn-width140 spacing05"
                                @click="onCancel()">
                                <span>Cancel</span>
                            </button>
                        </div>
                    </form>
                    <div id="vpsEmailModal" class="modal fade in">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <h3 class="vps-email-verify-header">Verify your email address</h3>

                                <a data-dismiss="modal" class="pull-right close-icon-popup vbg-vps-close-icon-popup"
                                    @click="closeModal()"></a>
                                <p class="spacing05 vps-email-verify-description">Enter the security code we just sent
                                    to your email address. If you
                                    didn't recieve an email with in a few minutes, please check your span or junk
                                    folders.</p>
                                <p>Security Code</p>
                                <input id="otpIdInput" class="input-f input_mr input_border"
                                    :class="isEmailValid ? 'vps-input-field-warning' : ''" autofocus type="text"
                                    required v-model="validateOTPPin" maxlength="60" />
                                <p class="vps-resend-code-btn"><u>Re-send security code</u></p>
                                <div class="modal-popup-btn-box">
                                    <button type="submit" data-dismiss="modal"
                                        class="btn vbg-ff-btn-cancel modal-popup-btn vbg-ff-btn-width140 spacing05"
                                        @click="submitOTP()">
                                        <span>Verify</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vpsFooter></vpsFooter>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
export default {
    name: 'VPSSecurityProfileEmailEdit',
    components: {
        vpsHeader,
        vpsFooter
    },
    data() {
        return {
            secProfileEmail: "",
            isEmailValid: false,
            emailFormatRegex: /^[a-zA-Z0-9](\.?\_?\-?[a-zA-Z0-9]){0,}@[a-zA-Z0-9-_]+\.([a-zA-Z0-9-_]{1,}\.){0,}[a-zA-Z]{2,}$/,
            validateOTPPin: "",
        }
    },
    methods: {
        ...mapActions('profile', [
            'sendVPSOtpToDevice',
            'verifyVPSOtpCode'
        ]),
        submitForm() {
            let payload = {
                uuID: this.uuID,
                emailAddress: this.secProfileEmail,
                otpType: "email",
            };
            if (!this.isEmailValid) {
                this.sendVPSOtpToDevice(payload)
            }
        },
        closeModal() {
            $("#vpsEmailModal").modal('hide')
        },
        getMsgIcon(index) {
            return "@/common/assets/styles/corp/images/" + index.type + ".png";
        },
        messageImage(index) {
            if (index.type == 'error' || index.type == 'warning' || index.type == 'list') {
                return "newErrorMessageImage"
            } else {
                return index.type + "MessageImage";
            }
        },
        getCloseIcon() {
            return iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/close_white.png';
        },
        deleteAlert(alert) {
            this.vpsProfileAlerts.splice(this.vpsProfileAlerts.indexOf(alert), 1)
        },
        getAlertType(index) {
            return index.type
        },
        submitOTP() {
            let payload = {
                uuID: this.uuID,
                otpToken: this.validateOTPPin,
                deliveryMethodSelected: "email",
                emailAddress: this.secProfileEmail,
                mtn: "",
                countryCode: "",
            };
            this.verifyVPSOtpCode(payload)
        },
        onCancel() {
            this.$router.push({ name: 'VPSSecurityProfilePage' })
        }
    },
    computed: {
        ...mapGetters("profile", [
            'uuID',
            'showVPSPopup',
            'vpsProfileAlerts'
        ]),
        vpsProfileAlerts: {
            get: function () {
                return this.$store.getters['profile/vpsProfileAlerts']
            },
            set: function (newVal) {
                this.$store.commit('profile/setVpsProfileAlerts', newVal)
            }
        }
    },
    watch: {
        secProfileEmail(newVal) {
            if (newVal) {
                if (!this.emailFormatRegex.test(newVal)) {
                    this.isEmailValid = true
                } else {
                    this.isEmailValid = false;
                }
            }
        },
        showVPSPopup: function (newVal) {
            if(newVal == true)
            $("#vpsEmailModal").modal({
                backdrop: "static"
            });
        },
        vpsProfileAlerts: function (newVal) {
            if (newVal) {
                window.scrollTo(0, 0);
            }
        },
    },
    mounted() {
        this.vpsProfileAlerts.splice(0, 1);
    }
}
</script>
<style scoped>
.vps-container {
    height: 100%;
    width: auto;
    margin: 40px;
}

.vps-input-field-warning {
    border: 1px solid #ED7000 !important;
    border-bottom: 4px solid #ED7000 !important;
}

.mainContainer {
    margin-top: 100px;
}

.paddingMobile {
    padding: 10px 16px;
    overflow-x: auto;
}

.vps-input-errortext-style {
    height: 32px;
    width: 301px;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: bold;
}

.myprofileContainer {
    width: 575px;
    padding-top: 5px;
    border-top: 4px solid #000;
}

@media (max-width: 768px) {
    .myprofileContainer {
        width: 100%;
    }
}

.headUserId {
    float: left;
    width: 100%;
}

.descEdit {
    margin-bottom: 50px;
    font-size: 16px;
    display: inline;
}

.myprofileHead {
    margin-top: 20px;
    float: left;
}

.labelText {
    font-size: 14px;
    color: #747676;
    padding-bottom: 4px;
    display: block;
}

.input-f {
    width: 240px;
    height: 34px;
    margin-bottom: 0px;
}

@media (max-width: 768px) {
    .input-f {
        width: 100%;
    }
}

.rules {
    margin-top: 10px;
    font-size: 12px;
}

.buttonset {
    margin-bottom: 50px;
    margin-top: 50px;
}

#modal-popup {
    width: 100% !important;
    position: absolute !important;
}

.vps-resend-code-btn {
    cursor: pointer;
    margin-bottom: 45px;
    margin-top: 10px;
}

.modal-dialog {
    width: 590px;
    height: 380px;
    margin: 0 auto !important;
    position: relative;
    top: 25%;
}

.modal-content {
    width: 590px;
    height: 380px;
    padding: 48px !important;
}

#modal-popup h3 {
    margin-bottom: 24px;
    font-family: "VzNHGeDS-bold11", Arial, Helvetica, sans-serif !important;
}

#modal-popup p {
    margin-bottom: 72px;
}

.vps-email-verify-header {
    margin-bottom: 5px;
}

.vps-email-verify-description {
    margin-bottom: 20px;
    margin-top: 10px;
}

.close-icon-popup {
    top: -78px !important;
}

.textDesc,
.edit-link {
    display: inline-block;
    padding-left: 0px;
}

td,
th {
    border: 0px;
    text-align: left;
    padding: 8px;
}

.tb-profile {
    width: 100%;
    margin-bottom: 50px
}

.tb-profile td {
    margin-right: 50px;
}

.view-otp-table {
    width: 100%;
    margin-bottom: 100px;
}

.vbg-ff-btn-width140 {
    height: 44px !important;
    line-height: 18px;
    font-size: 16px;
    padding: 12px;
    text-align: center;
}

.btn:active,
.btn:hover,
.btn:focus {
    line-height: 18px;
    font-size: 16px;
    padding: 12px;
}

@media (min-width: 350px) and (max-width: 550px) {
    .setup-secure-profile {
        width: 95% !important;
    }
}

@media (max-width: 442px) {
    .vbg-vps-close-icon-popup {
        margin-top: 48px !important;
    }
}

@media (max-width: 768px) {
    .modal-content {
        padding: 48px 16px !important;
    }

    .vbg-vps-close-icon-popup {
        left: 0px !important;
        margin-top: 62px !important;
        margin-right: 0px !important;
    }

    .modal-popup-btn {
        height: 44px !important;
        line-height: 18px;
        font-size: 16px;
        padding: 12px;
        text-align: center;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .setup-secure-profile {
        width: 88% !important;
    }
}

@media (min-width: 280px) and (max-width: 768px) {
    .modal-dialog {
        width: 100%;
        height: 100%;
    }

    .modal-content {
        width: 100%;
        height: 100%;
    }

    .modal-dialog {
        top: 0;
    }
}
</style>